import React from "react";
import Calendar from "../../calendar/calendar";
import WelcomeDisplay from "../widgets/welcome_display";
import { useAppSelector } from "../../../hooks/redux-hooks";
import moment from "moment";
import { TInvestment } from "../../../redux/types/investment.type";
import { useGetInvestmentListQuery } from "../../../redux/slices/investment_slice";
import LoadingSpinner from "../../../components/widgets/spinner";
import { ErrorWidget } from "../../utils/error_widget";
import Pagination from "../../pagination/pagination_items";
import { formatPrice } from "../../utils/format_price";

const InvestmentDashboardHomePage: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: pendingProjects,
    isLoading,
    error,
  }: TInvestment | any = useGetInvestmentListQuery({
    user: user?.id,
    status: "PENDING",
    page: currentPage, searchQuery: query
  });
  const { data: activeProjects, isLoading: fetchingActive }: TInvestment | any =
    useGetInvestmentListQuery({ user: user?.id, status: "ACTIVE",page: currentPage, searchQuery: query });

  const { data: cancelled, isLoading: fetchingCancelled }: TInvestment | any =
    useGetInvestmentListQuery({ user: user?.id, status: "CANCELLED",page: currentPage, searchQuery: query });

  if (isLoading || fetchingActive || fetchingCancelled) {
    return (
      <div className="text-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorWidget error={error}/>
    );
  }

  let ticketAmount = pendingProjects.results.map(
    (item: TInvestment) => item.project_amount
  );

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  let confirmedAmount = activeProjects.results.map(
    (item: TInvestment) => item.project_amount
  );

  const totalConirmedAmount = confirmedAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);

  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };

  return (
    <div className="content">
      <center>
        <h2
          style={{ fontFamily: "monospace", fontWeight: "bolder" }}
          className="text-capitalize"
        >
          Welcome: <b>{user?.company_name}</b>
        </h2>
      </center>
      
      <WelcomeDisplay
        totalAmount={total_amount}
        activeProjects={activeProjects.results}
        totalConfirmed={totalConirmedAmount}
        cancelled={cancelled.results}
        pendingProjects={pendingProjects.results}
      />
      <div className="col-md-12">
        <div className="card mb-4 mb-md-0">
          <h4 className="ml-3">
            <b>Active Projects</b>
          </h4>
          {activeProjects.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Paid</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {activeProjects.results.map((item: TInvestment, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalized">
                          {item.project_title.substring(0, 20)}...
                        </td>
                        <td className="text-uppercase">
                          <i className="fa fa-bookmark"></i>{" "}
                          {item.project_category}
                        </td>
                        <td>
                          {formatPrice({currency: item.user.company_currency.currency.currency_code, price: item.project_amount})}
                        </td>
                        <td>
                          {formatPrice({currency: item.user.company_currency.currency.currency_code, price: item.total_donated_avg})}
                        </td>
                        <td>
                          {moment().format("YYYY-MM-D") === item.project_days ||
                          moment().format("YYYY-MM-D") > item.project_days ? (
                            <h6 className="text-warning">
                              <i className="fa fa-heart"></i> Closed
                            </h6>
                          ) : (
                            <small className="text-capitalize text-dark">
                              {findDayDifference(
                                new Date(moment().format(item.project_days)),
                                new Date(moment().format("YYYY-MM-D"))
                              )}{" "}
                              day(s) remaining
                            </small>
                          )}{" "}
                        </td>
                        <td>
                          {moment(item.created_at).format("Do MMMM, YYYY")}
                        </td>
                        <td>
                          <a
                            className="me-3"
                            href={`/zm/investment/project_details/?project_id=${item.id}`}
                          >
                            <i className="fa fa-arrow-right"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                lastPage={activeProjects.total_pages}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
      <Calendar />
    </div>
  );
};

export default InvestmentDashboardHomePage;
