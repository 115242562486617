import React, { useState } from "react";
import { PropertyModel } from "../../../../models/product_model";
import ProductDataService from "../../../services/http.product.services";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface PaymentProps {
  property: PropertyModel | any;
}

function DeletePayment({ property }: PaymentProps) {
  const [deleting, setDeleting] = useState<boolean>(false);


  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setDeleting(true);
    ProductDataService.deletePropery(property?.id)
      .then((response) => {
        toast.success("Property Deleted Successfully");
        setDeleting(false);
        reset();
        window.location.reload();
      })
      .catch((error) => {
        setDeleting(false);
        toast.error(error);
      });
     
    
  };
  return (
    <>
      {/* <!-- Bid Offer Modal --> */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered offer-pop-form"
          role="document"
        >
          <div className="modal-content" id="deleteModal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <i className="fas fa-close"></i>
            </span>
            <div className="modal-body">
              <div className="text-center">
              <center>
            <i className="fa fa-home fa-5x text-danger"></i>
          </center>
              </div>
              <div className="offer-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="text-center mt-3 mb-3">Are you sure you want to delete <br />
                <b className="text-capitalize">{property?.propertyName}</b></h3>
                 <p className="text-danger text-center text-justify">
                    This action can't be undone and all the information and
                    products associated with this property will be delete and can't be retrieved
                  </p>
                  <div className="form-group">
                    <button
                      disabled={deleting}
                      type="submit"
                      className="btn full-width btn-primary"
                    >
                      {deleting && (
                        <span>
                          <i className="fa fa-refresh fa-spin"></i> Please Wait
                          ...
                        </span>
                      )}
                      {!deleting && <span> <i className="fa fa-trash"></i> Delete</span>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Modal --> */}
    </>
  );
}

export default DeletePayment;
