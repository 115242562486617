import React from 'react'
import RecentProducts from '../dashboard/recent_products'
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../widgets/bread_crumbs';

const CarList: React.FC = () => {
    const navigate = useNavigate();
  return (
    <div className='content'>
      <BreadCrumbs
        title={"Car Rental List"}
        subTitle={"View/Manage Car Rental List"}
      />
        <RecentProducts />
    </div>
  )
}

export default CarList