import { apiSlice } from "../services/apiSlice";
import { ICarBooking } from "../types/car_booking.type";
import { ICarImages, ICarRental } from "../types/car_rental.type";

export const adminAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCarRental: builder.mutation<ICarRental,Partial<ICarRental>>({
      query(data) {
        return {
          url: `/cars/v1/everyday/car_rental/tech/`,
          method: "POST",
          body: data,
        };
      },
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response: { data: ICarRental }, meta, arg) => response.data,
      invalidatesTags: ["ICarRental"],
      // onQueryStarted is useful for optimistic updates
      // The 2nd parameter is the destructured `MutationLifecycleApi`
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {},
      // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
      async onCacheEntryAdded(arg, {
          dispatch,
          getState,
          extra,
          requestId,
          cacheEntryRemoved,
          cacheDataLoaded,
          getCacheEntry,
        }
      ) {},
    }),
    adminUpdateCar: builder.mutation<ICarRental, Partial<ICarRental> & Pick<ICarRental, "id">>({
      query: ({ id, ...patch }) => ({
        url: `/cars/v1/everyday/admin_car_rental/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["ICarRental"],
      transformResponse: (response: { car: ICarRental }) => response.car,
    }),
    getAdminCar: builder.query<ICarRental, any>({
      query: (carid) => ({
        url: `/cars/v1/everyday/admin_car_rental/tech/${carid}/`,
      }),
      providesTags: ["ICarRental"],
    }),
    getAdminCars: builder.query<ICarRental[], any>({
      query: ({ company, searchQuery, status, page, label  }) =>
        `/cars/v1/everyday/company_cars/tech/?user__id=${company}&page=${page}&search=${searchQuery}&is_active=${status}&label=${label}`,
      // transformResponse: (res: ICarRental[]) => res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarRental"],
      // keepUnusedDataFor: 5,
    }),
    getAdminCustomerBookings: builder.query<ICarRental[], any>({
      query: ({ searchQuery, status, page, company, rentalType }) =>
        `/cars/v1/everyday/car_booking/tech/?status=${status}&user__id=${company}&page=${page}&search=${searchQuery}&rental_type=${rentalType}`,
      // transformResponse: (res: ICarRental[]) => res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarRental"],
      // keepUnusedDataFor: 5,
    }),
    deleteAdminBooking: builder.mutation<ICarRental, number>({
      query: (id) => ({
        url: `/cars/v1/everyday/car_booking/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ICarRental"],
    }),
    deleteCarImage: builder.mutation<ICarImages, number>({
      query: (id) => ({
        url: `/cars/v1/everyday/car_image_gallery/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ICarImages"],
    }),
    // GET CANCELLED, CONFIRMED, PENDING BOOKINGS
    getAdminPendingBookings: builder.query<ICarBooking[], void>({
      query: () =>
        `/cars/v1/everyday/pending_bookings/tech`,
      transformResponse: (res: ICarBooking[]) => res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
    getAdminConfirmedBookings: builder.query<ICarBooking[], void>({
      query: () =>
        `/cars/v1/everyday/confirmed_bookings/tech`,
      transformResponse: (res: ICarBooking[]) => res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
    getAdminCancelledBookings: builder.query<ICarBooking[], void>({
      query: () =>
        `/cars/v1/everyday/cancelled_bookings/tech`,
      transformResponse: (res: ICarBooking[]) => res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
    getAdminPendingCars: builder.query<ICarRental[], any>({
      query: ({searchQuery, status, page, label}) =>
        `/cars/v1/everyday/admin_car_rental/tech/?page=${page}&search=${searchQuery}&is_active=${status}&label=${label}`,
      // transformResponse: (res: ICarRental[]) => res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarRental"],
      // keepUnusedDataFor: 5,
    }),
  }),
});
export const {
  useCreateCarRentalMutation,
  useDeleteAdminBookingMutation,
  useGetAdminCarsQuery,
  useGetAdminCustomerBookingsQuery,
  useAdminUpdateCarMutation,
  useGetAdminCarQuery,
  useDeleteCarImageMutation,
  useGetAdminCancelledBookingsQuery,
  useGetAdminConfirmedBookingsQuery,
  useGetAdminPendingBookingsQuery,
  useGetAdminPendingCarsQuery,
} = adminAPISlice;
