import { RefObject } from "react";

export const handlePrint = (divRef: RefObject<HTMLDivElement>) => {
    const printContents = divRef.current?.innerHTML;
    const printWindow = window.open('', '_blank');
    if (printWindow && printContents) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              @media print {
                body { margin: 0; padding: 0; }
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #000; padding: 8px; }
              }
            </style>
          </head>
          <body>${printContents}</body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };