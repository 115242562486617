import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import plusIcon from "../../../../assets/img/icons/plus.svg";
import imageUpload from "../../../../assets/img/icons/upload.svg";



interface FormValues {
  visible: boolean;
  onClose: () => void;
  handleShow: () => void;
  onSubmit: (data: any) => void;
  initialValues?: any;
  isLoading: boolean;
}

const AddBrand: React.FC<FormValues> = ({
  visible,
  onClose,
  handleShow,
  onSubmit,
  initialValues,
  isLoading,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues || {
      brand_name: "",
      image: null,
    },
  });

  // Reset the form when initialValues change
  React.useEffect(() => {
    reset(initialValues || { brand_name: "", image: null });
  }, [initialValues, reset]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Example: Validate file type
      if (!selectedFile.type.startsWith('image/')) {
        toast.error("Please upload an image file.");
        return;
      }

      // Example: Validate file size (e.g., 2MB limit)
      if (selectedFile.size > 2 * 1024 * 1024) {
        toast.error("File size must be less than 5MB.");
        return;
      }

      setFile(selectedFile);
    }
  };

  const onSubmitForm = (data: any) => {
    console.log("data: ", data);
    onSubmit({
      brand_name: data.brand_name,
      image: file,
    });
  };

  return (
    <>
      <div className="page-btn">
        <button onClick={handleShow} className="btn btn-added">
          <img src={plusIcon} className="me-1" alt="img" />
          Add Brand
        </button>
      </div>

      <Modal
        show={visible}
        onHide={onClose}
        backdrop="static"
        centered={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{initialValues !== null ? "Update Car Brand" : "Add Car Brand"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitForm)} encType="multipart/form-data">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Brand Name</label>
                      <input
                        type="text"
                        disabled={isLoading}
                        {...register("brand_name", { required: true })}
                        placeholder="Brand Name"
                        className={`form-control ${
                          errors.brand_name ? "border-danger" : ""
                        }`}
                      />
                      {errors.brand_name && (
                        <p className="text-danger">Brand Name is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Brand Image</label>
                      <div
                        className={`form-control image-upload ${
                          errors.brand_name ? "border-danger" : ""
                        }`}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          disabled={isLoading}
                          {...register("image", {
                            required: initialValues !== null ? false : true,
                            onChange: handleFileChange,
                          })}
                          name="image"
                        />
                        <div className="image-uploads">
                          <img
                            style={{ width: "80px", height: "80px" }}
                            src={
                              file !== null
                                ? URL.createObjectURL(file)
                                : imageUpload
                            }
                            alt="img"
                          />
                          <h4 className="mt-4">Drag and drop a file to upload</h4>
                        </div>
                        {errors.image && (
                          <p className="text-danger">Image is required.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={onClose}
              >
                Close
              </Button>
              <Button disabled={isLoading} type="submit" variant="primary">
                {isLoading && (
                  <i className="fa fa-spinner fa-spin text-danger"></i>
                )}{" "}
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBrand;