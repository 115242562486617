export const SYSTEMS_TO_USE_CHOICES = [
  "CARRENTAL",
  "COMMODITY",
  "REALESTATE",
  "DEALSECURE",
  "INVESTMENTS",
  "CONSTRUCTION-MINING",
  "ORGANIZATION",
];

export const CREATE_USER_SYSTEMS_TO_USE_CHOICES = [
    { value: 'ORGANIZATION', label: 'Organization' },
    { value: 'COMMODITY', label: 'Commodity' },
    { value: 'CARRENTAL', label: 'Carrental' },
    { value: 'REALESTATE', label: 'RealEstate' },
    { value: 'DEALSECURE', label: 'DealSecure' },
    { value: 'INVESTMENTS', label: 'Investments' },
    { value: 'CONSTRUCTION-MINING', label: 'Construction-Mining' },
    
  ];