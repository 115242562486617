import moment from 'moment';
import React from 'react';
import { IMessage } from '../../../../redux/types/user_message.interface';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useGetMineralMessageConversationQuery, useSendMessageMutation } from '../../../../redux/slices/mineral_slice';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { ErrorWidget } from '../../../utils/error_widget';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';

interface MessageBoxProps {
  selectedUser: IMessage;
}

interface Message {
  user: number | undefined;
  sender: number|undefined;
  receiver: number;
  text: string;
  product: number;
  image?: File | null;
  timestamp?: number; // Add a unique identifier for optimistic updates
}

const MessageBox: React.FC<MessageBoxProps> = ({ selectedUser }) => {
  const [file, setFile] = React.useState<File | any>();
  const [localMessages, setLocalMessages] = React.useState<Message[]>([]);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [sendMessage, { isLoading }] = useSendMessageMutation();

// Ref for the message container
const messagesEndRef = React.useRef<HTMLDivElement | null>(null);

// Function to scroll to the bottom
const scrollToBottom = () => {
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }
};

const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    setFile(file); // Store the file in state

    // Generate a preview URL for the image
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target?.result as string); // Store the preview URL
    };
    reader.readAsDataURL(file);
  }
};



  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    data: messageList,
    isLoading: isFetching,
    error,
    refetch,
  }: IMessage | any = useGetMineralMessageConversationQuery({
    sender: selectedUser.sender.id,
    receiver: selectedUser.receiver.id,
    product: selectedUser.product.id,
  });

  React.useEffect(() => {
    if (messageList) {
      setLocalMessages([]); // Clear local messages after refetch
    }
  }, [messageList]);

  const allMessages = React.useMemo(() => {
    return [...(messageList || []), ...localMessages];
  }, [messageList, localMessages]);

  // Scroll to the bottom when allMessages changes
React.useEffect(() => {
  scrollToBottom();
}, [allMessages]);

  const onSubmit = handleSubmit(async (data) => {
    const newMessage = {
      user: user?.id,
      sender: user?.id,
      receiver: selectedUser.sender.id,
      text: data.message,
      product: selectedUser.product.id,
      // image: file,
      timestamp: Date.now(), // Add a unique identifier
    };

    // Optimistically update the local state
    setLocalMessages((prev) => [...prev, newMessage]);

    try {
      // Send the message to the server
      await sendMessage(newMessage).unwrap();

      // Reset the form after sending the message
      reset();
      setImagePreview(null); // Clear the image preview
      setFile(null);
      // Refetch the message list to ensure it's up-to-date
      refetch();
    } catch (err) {
      // Revert the optimistic update if the request fails
      setLocalMessages((prev) =>
        prev.filter((msg) => msg.timestamp !== newMessage.timestamp)
      );
      console.error("Failed to send message:", err);
    }
  });

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  return (
    <div className="col-lg-7 col-xl-8 chat-cont-right">
      <div className="card mb-0">
        <div className="card-header msg_head">
          <div className="d-flex bd-highlight">
            <a
              id="back_user_list"
              href="javascript:void(0)"
              className="back-user-list"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <div className="img_cont">
              <img
                className="rounded-circle user_img"
                src={selectedUser.user.user_profile?.profile_picture}
                alt={selectedUser.user.first_name}
              />
            </div>
            <div className="user_info">
              <span>
                <strong id="receiver_name" className='text-capitalize'>{selectedUser.user.first_name} {selectedUser.user.last_name}</strong>
              </span>
              <p className="mb-0 text-capitalize"><b>{selectedUser.product.productName}</b></p>
            </div>
          </div>
        </div>
        <div className="card-body msg_card_body chat-scroll">
          <ul className="list-unstyled">
            {allMessages.map((message: IMessage, index: number) => (
              <li
                key={message.id || index}
                className={`media ${message.sender.id === user?.id ? 'sent' : 'received'} d-flex`}
              >
                <div className="avatar flex-shrink-0">
                  <img
                    src={
                      message.sender.id === user?.id
                        ? require('../../../../assets/img/customer/profile2.jpg') // Current user's avatar
                        : selectedUser.sender.user_profile?.profile_picture // Selected user's avatar
                    }
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="media-body flex-grow-1">
                  <div className="msg-box">
                    <div>
                      <p>{message.text}</p>
                      {message.image && (
                        <div className="chat-msg-attachments">
                          <div className="chat-attachment">
                            <img
                              src={message.image}
                              alt="Attachment"
                              className="img-fluid"
                            />
                            <a href="" className="chat-attach-download">
                              <i className="fas fa-download"></i>
                            </a>
                          </div>
                        </div>
                      )}
                      <ul className="chat-msg-info">
                        <li>
                          <div className="chat-time">
                            <span>{moment(message.created_at).format('h:mm A')}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {imagePreview && (
          <div className="image-preview">
            <img
              src={imagePreview}
              alt="Selected Image"
              style={{ maxWidth: '100px', maxHeight: '100px', marginBottom: '10px' }}
              className='rounded'
            />
            <button
              type="button"
              onClick={() => {
                setImagePreview(null); // Clear the preview
                setFile(null); // Clear the file
              }}
              className="btn btn-danger btn-sm"
            >
              <i className='fa fa-trash'></i>
            </button>
          </div>
        )}
        <div className="card-footer">
          <form onSubmit={onSubmit}>
            <div className="input-group">
              <input
                className={`form-control type_msg mh-auto empty_check ${
                  errors.propertyLabel ? "border-danger" : ""
                }`}
                placeholder="Type your message..."
                {...register("message", {
                  required: "Message is required!",
                })}
                name="message"
                disabled={isLoading}
              />
              <input
                type="file"
                id="image-upload"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageSelection}
              />
             {file !== null || file !== undefined ? null : <button
                type="button"
                onClick={() => document.getElementById('image-upload')?.click()}
                className="btn btn-primary btn_send"
              >
                <i className="fa fa-image" aria-hidden="true"></i>
              </button>
              }
              <button disabled={isLoading} type="submit" className="btn btn-primary btn_send">
               {isLoading ? <Spinner /> : <i className="fa fa-paper-plane" aria-hidden="true"></i>}
              </button>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;