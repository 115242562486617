import { apiSlice } from "../services/apiSlice";
import { TAgroOrder } from "../types/agro_order.type";

export const agroOrderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAgroOrder: builder.mutation<TAgroOrder, Partial<TAgroOrder>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_agriculture_orders/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TAgroOrder"],
      transformResponse: (result: { agri: TAgroOrder }) =>
        result.agri,
    }),
    updateAgroOrder: builder.mutation<TAgroOrder, Partial<TAgroOrder>& Pick<TAgroOrder, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_agriculture_orders/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TAgroOrder"],
      transformResponse: (response: { agri: TAgroOrder }) =>
        response.agri,
    }),
    getAgroOrder: builder.query<TAgroOrder, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_agriculture_orders/tech/${id}/`,
      }),
      providesTags: ["TAgroOrder"],
    }),
    getAgroOrdersList: builder.query<TAgroOrder[], any>({
      query: ({status, user, fromDate, toDate}) => `/administrator/v1/everyday/admin_agriculture_orders/tech/?status=${status}&supplier__id=${user}&from_date=${fromDate}&to_date=${toDate}`,
      transformResponse: (res: TAgroOrder[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'TAgroOrder', id })) : ['TAgroOrder'],
      // keepUnusedDataFor: 5,
    }),
    getAgroCustomer: builder.query<TAgroOrder, any>({
      query: ({customer}) => ({
        url: `/administrator/v1/everyday/agro_customer/${customer}/tech`,
      }),
      providesTags: ["TAgroOrder"],
    }),
    getAgroCustomers: builder.query<TAgroOrder[], any>({
      query: ({supplier, page, searchQuery}) => `/administrator/v1/everyday/admin_agriculture_customers/tech/?supplier__id=${supplier}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TAgroOrder[]) =>
      //   res.sort((a, b) => b.id - a.id),
      providesTags: ["TAgroOrder"],
      // keepUnusedDataFor: 5,
    }),
    deleteAgroOrder: builder.mutation<TAgroOrder, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_agriculture_orders/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TAgroOrder"],
    }),
  }),
});
export const {
  useCreateAgroOrderMutation,
  useDeleteAgroOrderMutation,
  useGetAgroCustomerQuery,
  useGetAgroCustomersQuery,
  useGetAgroOrderQuery,
  useGetAgroOrdersListQuery,
  useUpdateAgroOrderMutation,
} = agroOrderSlice;
