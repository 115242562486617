import React from "react";
import Calendar from "../../calendar/calendar";
import WelcomeDisplay from "../widgets/welcome_display";
import { useAppSelector } from "../../../hooks/redux-hooks";
import moment from "moment";
import LoadingSpinner from "../../../components/widgets/spinner";
import { useGetConstructionCustomersListQuery, useGetConstructionOrdersListQuery } from "../../../redux/slices/construction_orders_slice";
import { TConstruction, TConstructionMiningOrders } from "../../../redux/types/construction.type";
import { useGetConstructionListQuery } from "../../../redux/slices/construction_slice";
import { formatPrice } from "../../utils/format_price";
import MiningConstructionOrderChart from "../../admin/pages/construction/stats";

const AgroDashboardHomePage: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: reservations,
    isLoading,
    error,
  }: TConstructionMiningOrders | any = useGetConstructionOrdersListQuery({
    supplier: user?.id, 
    status: "PENDING", 
    searchQuery: query,
    page: currentPage,
    fromDate: '',
    toDate:   '',
  });
  const {
    data: confirmedReservations,
    isLoading: fetchingConfirmed,
  }: TConstructionMiningOrders | any = useGetConstructionOrdersListQuery({
    supplier: user?.id, 
    status: "CONFIRMED", 
    searchQuery: query,
    page: currentPage,
    fromDate: '',
    toDate:   '',
  });
  const {
    data: cancelled,
    isLoading: fetchingCancelled,
  }: TConstructionMiningOrders | any = useGetConstructionOrdersListQuery({
    supplier: user?.id, 
    status: "CANCELLED", 
    searchQuery: query,
    page: currentPage,
    fromDate: '',
    toDate:   '',
  });
  
  const { data: customers, isLoading: fetchingCustomers }: TConstructionMiningOrders | any =
  useGetConstructionCustomersListQuery({
      supplier: user?.id,
      searchQuery: query,
      page: currentPage,
    });

  const { data: itemList, isLoading: fetchingMinerals }: TConstruction | any =
    useGetConstructionListQuery({supplier: user?.id, 
      status: "ACTIVE", 
      product_type: user?.user_type, 
      searchQuery: query, 
      page: currentPage,
      fromDate: '',
    toDate:   '',
    });

  if (isLoading || fetchingCustomers || fetchingConfirmed || fetchingMinerals || fetchingCancelled) {
    return (
      <div className="text-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5" role="alert">
        An error occurred while loading the application
      </div>
    );
  }

  let ticketAmount = reservations.results.map(
    (item: TConstructionMiningOrders) => item.productPrice
  );

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  let confirmedAmount = confirmedReservations.results.map(
    (item: TConstructionMiningOrders) => item.productPrice
  );

  const totalConirmedAmount = confirmedAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);

  return (
    <div className="content">
      <center>
        <h2
          style={{ fontFamily: "monospace", fontWeight: "bolder" }}
          className="text-capitalize"
        >
          Welcome: {user?.company_name}
        </h2>
      </center>
      <WelcomeDisplay
        totalAmount={total_amount}
        reservations={reservations.results}
        customers={[]}
        constructionList={itemList.results}
        confirmedReservations={confirmedReservations.results}
        totalConfirmed={totalConirmedAmount}
        cancelled={cancelled.results}
      />
      <div className="col-md-12">
      <h3>Sales</h3>
      <MiningConstructionOrderChart company_id={user?.id} />
        <div className="card mb-4 mb-md-0">
          {reservations.results.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info mt-3"
                role="alert"
                style={{ width: "fit-content" }}
              >
                <h1 className="mt-3">NO PENDING SALES FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Paid</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {reservations.results.map((item: TConstructionMiningOrders, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">{item.product.productName.substring(0, 12)}</td>
                        <td className="text-capitalize">
                          {item.customer.first_name} {item.customer.last_name}
                        </td>
                        <td>{item.customer.email}</td>
                        <td>{item.customer.phoneNumber}</td>
                        <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.productPrice})}</td>
                        <td>{moment(item.created_at).format("MMM do YYYY")}</td>
                        <td>
                          <div className="badges bg-lightred text-white text-capitalize">
                            <b>{item.status}</b>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Calendar />
    </div>
  );
};

export default AgroDashboardHomePage;
