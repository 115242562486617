import React from "react";
import bell from "../../../../assets/img/icons/notification-bing.svg";
import moment from "moment";
import { TAgriculture } from "../../../../redux/types/agriculture.type";
import { useGetAgricultureListQuery } from "../../../../redux/slices/agriculture_slice";

const AgricNotificationWidget: React.FC = () => {
  const {
    data: agricultureData,
    isLoading,
    error,
  }: TAgriculture | any = useGetAgricultureListQuery({
    supplier: "", 
    status: "PENDING", 
    searchQuery: "",
    page: 1,
    fromDate: "",
    toDate: "",
  });
  return (
    <li className="nav-item dropdown">
      <a
        href="javascript:void(0);"
        className="dropdown-toggle nav-link"
        data-bs-toggle="dropdown"
      >
        <img src={bell} alt="img" />
        <span className="badge rounded-pill bg-success">{isLoading ? "0" : error ? "0":  agricultureData.results.length}</span>
      </a>
      <div className="dropdown-menu notifications">
        <div className="topnav-dropdown-header">
          <span className="notification-title">Agriculture Notifications</span>
          <a href="javascript:void(0)" className="clear-noti">
            {" "}
            Clear All{" "}
          </a>
        </div>
        <div className="noti-content">
          <ul className="notification-list">
            
            {isLoading ? <center>
              <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
            </center>: error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : agricultureData.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h5 className="text-center">No Notifications</h5>
              </div>
            ) : agricultureData.results.map((item: TAgriculture, index: number)=>{
              return <li key={index} className="notification-message">
              <a href={`/zm/dashboard/agro_transactions/?agro_id=${item.id}`}>
                <div className="media d-flex">
                  <span className="avatar flex-shrink-0">
                    <img
                      src={`${item.thumbnail}`}
                      alt={item.productName}
                    />
                  </span>
                  <div className="media-body flex-grow-1">
                    <p className="noti-details">
                      New Agro Pending Listing request From: <span className="noti-title text-capitalize">{item.supplier.company_name}</span>
                    </p>
                    <p className="noti-time">
                      <span className="notification-time">
                        {moment(item.created_at).fromNow()}
                      </span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            })}
            
          </ul>
        </div>
        <div className="topnav-dropdown-footer">
          <a href="#">View all Notifications</a>
        </div>
      </div>
    </li>
  );
};


export default AgricNotificationWidget;
