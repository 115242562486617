import { IMessage, IUser, IUserMessages } from "./user_message.interface";

const mockUsers: IUser[] = [
  {
    id: '1',
    name: 'Jeffrey Akridge',
    image: require('../../../../../assets/img/customer/customer1.jpg'),
  },
  {
    id: '2',
    name: 'Nancy Olson',
    image: require('../../../../../assets/img/customer/customer2.jpg'),
  },
  {
    id: '3',
    name: 'Ramona Kingsley',
    image: require('../../../../../assets/img/customer/customer3.jpg'),
  },
];

const mockMessages: IMessage[] = [
  {
    id: '1',
    text: 'Hello! How are you?',
    user: mockUsers[0], // Jeffrey Akridge
    timestamp: '2023-10-01T08:30:00',
    senderId: '1', // Sent by Jeffrey
    created_at: '2023-10-01T08:30:00',
  },
  {
    id: '2',
    text: "I'm good, thanks! How about you?",
    user: mockUsers[1], // Nancy Olson
    timestamp: '2023-10-01T08:35:00',
    senderId: 'currentUser', // Sent by the current user
    created_at: '2023-10-01T08:35:00',
  },
  {
    id: '3',
    text: 'Check out this image!',
    user: mockUsers[0], // Jeffrey Akridge
    timestamp: '2023-10-01T08:40:00',
    senderId: '1', // Sent by Jeffrey
    created_at: '2023-10-01T08:40:00',
    image: require('../../../../../assets/img/product/product12.jpg'), // Image attachment
  },
  {
    id: '4',
    text: 'That looks amazing!',
    user: mockUsers[1], // Nancy Olson
    timestamp: '2023-10-01T08:45:00',
    senderId: 'currentUser', // Sent by the current user
    created_at: '2023-10-01T08:45:00',
  },
];

export const mockUserMessages: IUserMessages[] = [
  {
    userId: '1',
    user: mockUsers[0], // Jeffrey Akridge
    messages: mockMessages.filter(
      (msg) => msg.senderId === '1' || msg.senderId === 'currentUser'
    ),
    lastMessage: mockMessages
      .filter((msg) => msg.senderId === '1' || msg.senderId === 'currentUser')
      .slice(-1)[0], // Get the last message in the conversation
  },
  {
    userId: '2',
    user: mockUsers[1], // Nancy Olson
    messages: mockMessages.filter(
      (msg) => msg.senderId === '2' || msg.senderId === 'currentUser'
    ),
    lastMessage: mockMessages
      .filter((msg) => msg.senderId === '2' || msg.senderId === 'currentUser')
      .slice(-1)[0], // Get the last message in the conversation
  },
  {
    userId: '3',
    user: mockUsers[2], // Ramona Kingsley
    messages: mockMessages.filter(
      (msg) => msg.senderId === '3' || msg.senderId === 'currentUser'
    ),
    lastMessage: mockMessages
      .filter((msg) => msg.senderId === '3' || msg.senderId === 'currentUser')
      .slice(-1)[0], // Get the last message in the conversation
  },
];
