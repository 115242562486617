import React from 'react';
import { IAgroReview, TAgriculture } from '../../../../redux/types/agriculture.type';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { useGetAgricultureQuery } from '../../../../redux/slices/agriculture_slice';
import { TAgroOrder } from '../../../../redux/types/agro_order.type';
import editIcon from "../../../../assets/img/icons/edit-5.svg";
import EditAgro from './edit_agro';
import moment from 'moment';
import BreadCrumbs from '../../../widgets/bread_crumbs';
import { ErrorWidget } from '../../../utils/error_widget';
import AgroOrdersChart from '../../../admin/pages/agriculture/stats';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { formatPrice } from '../../../utils/format_price';
import { renderStars } from '../../../utils/render_star';


const AgroProductDetails: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
    const queryParams = new URLSearchParams(window.location.search);
    const product_id = queryParams.get("product_id");
    const [show, setShow] = React.useState(false);
     const [agro, setAgro] = React.useState<TAgriculture | any>();
       const handleClose = () => {
         setShow(false);
       };
     
       const handleShow = (agro: TAgriculture) => {
         setAgro(agro);
         setShow(true);
       };
     

    const {
      data: product,
      isLoading: isFetching,
      error,
    }: TAgriculture | any = useGetAgricultureQuery(Number(product_id));
  
    if (isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
    if (error) {
      return (
        <ErrorWidget error={error}/>
      );
    }
  return (
    <>
    <EditAgro
        agro={product}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        />
         
        <div className="content">
        <BreadCrumbs
        title={"Agro Product Details"}
        subTitle={"View/Manage Agro Product Details"}
      />
      <AgroOrdersChart transaction_id={product_id} company_id={user?.id}/>
      {/* Display Average Rating */}
        <div style={{ marginBottom: '20px' }}>
          <h3>Average Rating: {product.rating_avg}
          </h3>
          <div>
            {renderStars(product.rating_avg, 40)}
          </div>
        </div>
        <div className="row g-0">
          {/* Product Image */}
          <div className="col-md-4">
            <img
              src={`${process.env.REACT_APP_API_URL}${product.thumbnail}`}
              alt={product.productName}
              className="img-fluid rounded"
              style={{ height: '100%', objectFit: 'cover' }}
            />
          </div>

          {/* Product Details */}
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title display-10 text-capitalize"><b>{product.productName}</b></h5>
              <p className="card-text text-muted">{product.description}</p>

              <div className="row mt-4">
                <div className="col-md-6">
                  <p className="mb-2">
                    <strong>Price:</strong> {formatPrice({currency: user?.company_currency.currency.currency_code, price: product.productPrice})}
                  </p>
                  <p className="mb-2">
                    <strong>Unit:</strong> {product.unit}
                  </p>
                  <p className="mb-2">
                    <strong>Category:</strong> {product.category}
                  </p>
                  <p className="mb-2">
                    <strong>Quantity:</strong> {product.quantity}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                    <p className="mb-2">
                    <strong>Status:</strong>{" "}
                    <span className={`badge ${product.quantity > 1 ? 'bg-success' : 'bg-warning'}`}>
                      {product.status}
                    </span>
                    
                  </p>
                    </div>
                    <div className="col-md-6">
                    <a
                        className="mt-5 ml-4 text-warning p-2 rounded"
                        onClick={() => handleShow(product)}
                    >
                        <img width={30} height={30} src={editIcon} alt="img" />
                    </a>
                    </div>
                  </div>
                 
                  <p className="mb-2">
                    <strong>Rating:</strong>{" "}
                    <span className="text-warning">
                      {product.rating_avg} <i className='fa fa-star'></i>
                    </span>
                  </p>
                  <p className="mb-2">
                    <strong>Created At:</strong> {moment(product.created_at).format("YYYY-MM-DD HH:mm a")}
                  </p>
                  <p className="mb-2">
                    <strong>Product ID:</strong> {product.product_unique_id}
                  </p>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        {/* Order Products Section */}
        <div className="mt-4">
        <h3 className="h5"><b>Order Products</b></h3>
        {product.agri_order_product.length === 0 ? <div className="text-center">
            <h4 className="text-muted">No placed orders yet.</h4>
          </div>:
        <ul className="list-group">
            {product.agri_order_product.map((order: TAgroOrder, index: number) => (
            <li key={index} className="list-group-item">
                <div className="d-flex justify-content-between">
                <div>
                    <strong>#:</strong> {index + 1}
                </div>
                <div className='text-capitalize'>
                    <strong>Customer:</strong> {order.customer.first_name} {order.customer.last_name}
                </div>
                <div>
                    <strong>Order ID:</strong> {order.agro_order_id}
                </div>
                <div>
                    <strong>Quantity:</strong> {order.quantity}
                </div>
                <div>
                    <strong>Status:</strong>{" "}
                    <span className={`badge ${order.status === 'CONFIRMED' ? 'bg-success' : 'bg-warning'}`}>
                    {order.status}
                    </span>
                </div>
                <div>
                    <strong>Date Ordered:</strong>{" "}
                    <span>
                    {moment(order.created_at).format("YYYY MMM Do HH:mm a")}
                    </span>
                </div>
                <div>
                  <strong>Action: </strong>
                  <span>
                  <a className="me-3" href={`/zm/agriculture/order_details/?order_id=${product.id}`}>
                      <i className='fa fa-arrow-right'></i>
                    </a>
                  </span>
                </div>
                </div>
            </li>
            ))}
        </ul>
        }
        </div>

        {/* Product Reviews Section */}
        <div className="mt-4">
        <h3 className="h5"><b>Product Reviews</b></h3>
        {product.agri_product_review.length > 0 ? (
            <div className="list-group">
            {product.agri_product_review.map((review: IAgroReview, index: number) => (
                <div key={review.id} className="list-group-item">
                <div className="d-flex justify-content-between align-items-center">
                    <div className='text-capitalize'>
                    <strong>{review.user.first_name} {review.user.last_name}</strong>
                    <span className="text-warning ms-2">
                        {renderStars(review.rating, 20)} {review.rating}
                    </span>
                    </div>
                    <small className="text-muted">
                    {moment(review.created_at).format('YYYY MMM Do HH:mm a')}
                    </small>
                </div>
                <p className="mt-2 mb-0">{review.review}</p>
                </div>
            ))}
            </div>
        ) : (
            <div className="text-center">
            <h4 className="text-muted">No reviews yet.</h4>
            </div>
        )}
        </div>
    </div>
    </>
    
  );
};

export default AgroProductDetails;