import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ResetPasswordConfirm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const { new_password, re_new_password } = formData;
  const { uid, token } = useParams();

  const onChange = (e: any) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
          body,
          config
        )
        .then((res: any) => {
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          toast.success(
            "Your Password has been Reset Successfully, You can now login with Your new password"
          );
        })
        .catch((e: any) => {
          toast.error(`${e.message}`);
        });
    } catch (err) {}
    setLoading(false);
  };


  return (
    <>
    <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="login-wrapper">
              <div className="login-content">
                <div className="login-userset">
                  <center>
                    <div className="login-logo">
                      <img style={{borderRadius: '50%'}} src={require("../assets/img/logo.jpg")} alt="img" />
                    </div>
                  </center>
                  <div className="login-userheading">
                    <h3>Reset Password</h3>
                    <h4>Enter your new password</h4>
                  </div>

                  <form onSubmit={onSubmit}>
                    <div className="form-login">
                      <label>Password</label>
                      <div className="form-addons">
                        <input
                         type="password"
                         placeholder="New Password"
                         name="new_password"
                         value={new_password}
                         disabled={loading}
                         onChange={(e) => onChange(e)}
                         minLength={6}
                         required
                        />
                        
                      </div>
                    </div>
                    <div className="form-login">
                    <label>Retype Password</label>
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      name="re_new_password"
                      disabled={loading}
                      value={re_new_password}
                      onChange={(e) => onChange(e)}
                      minLength={6}
                      required
                    />
                    </div>
                    
                    <div className="form-login">
                      <button
                        type="submit"
                        className="btn btn-login"
                        disabled={loading}>
                        {loading && (
                          <div className="spinner-border spinner-border-sm text-danger"></div>
                        )}{" "}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="login-img shadow">
                <img src={require("../assets/img/login.jpg")} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ResetPasswordConfirm;
