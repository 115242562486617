export const fuelTypes = [
    'Petrol',
    'Diesel',
    'Kerosene',
    'Electric', 
    'Hybrid', 
    'Gasoline', 
    'Flexible',
    'Natural Gas',
    'Biofuels',
    'Compressed Natural Gas',
    'Propane',
    'Ethanol',
    'Liquefied Petroleum Gas',
    'Advanced Fuel Oil',
    'Ultra Low Sulfur Diesel',
    'Hydrogen',
    'Biodiesel',
    'Advanced Gasoline',
    'Bifuel',
]