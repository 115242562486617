import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import plusIcon from "../../../../../assets/img/icons/plus.svg";
import imageUpload from "../../../../../assets/img/icons/upload.svg";
import httpDashboardServices from "../../../../services/http.category.services";
import { useCreateMineralCategoryMutation } from "../../../../../redux/slices/mineral_category_slice";


interface IProps {
  fetchData: any;
}

const AddMainCategory: React.FC<IProps> =({fetchData})=> {
  const [file, setFile] = useState<File | null>(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [createMineralCategory, { isLoading }] = useCreateMineralCategoryMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
  
      // Example: Validate file type
      if (!selectedFile.type.startsWith('image/')) {
        toast.error("Please upload an image file.");
        return;
      }
  
      // Example: Validate file size (e.g., 2MB limit)
      if (selectedFile.size > 2 * 1024 * 1024) {
        toast.error("File size must be less than 5MB.");
        return;
      }
  
      setFile(selectedFile);
    }
  };

  const onSubmit = async (data: any) => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return; // Exit early if no file is selected
    }
    console.log(data);
    const formData = new FormData();
    formData.append('categoryName', data.categoryName);
    formData.append('image', file);
    
    setIsLoading(true);
    await httpDashboardServices.createMineralCategory(formData)
      .then((res: any) => {
        setIsLoading(false);
        reset();
        fetchData();
        setShow(false);
        setFile(null);
        toast.success("Added Successfully");
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.error(err.message);
        toast.error("Failed to add: " + err.error);
      });
  };

  return (
    <>
      <div className="page-btn">
        <button onClick={handleShow} className="btn btn-added">
          <img src={plusIcon} className="me-1" alt="img" />
          Add Category
        </button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Mineral Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Category Name</label>
                      <input
                        type="text"
                        disabled={isLoading}
                        {...register("categoryName", { required: true })}
                        name="categoryName"
                        placeholder="Category Name"
                        className={`form-control ${
                          errors.categoryName ? "border-danger" : ""
                        }`}
                      />
                      {errors.categoryName && (
                        <p className="text-danger">
                          Category Name is required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label> Product Image</label>
                      <div
                        className={`form-control image-upload ${
                          errors.categoryName ? "border-danger" : ""
                        }`}>
                        <input
                          type="file"
                          accept="image/*"
                          disabled={isLoading}
                          {...register("image", {
                            required: true,
                            onChange: handleFileChange,
                          })}
                          name="image"
                        />
                        <div className="image-uploads">
                          <img
                            style={{ width: "80px", height: "80px" }}
                            src={
                              file !== null
                                ? URL.createObjectURL(file)
                                : imageUpload
                            }
                            alt="img"
                          />
                          <h4 className="mt-4">Drag and drop a file to upload</h4>
                        </div>
                        {errors.image && (
                          <p className="text-danger">
                            Image is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={handleClose}>
                Close
              </Button>
              <Button disabled={isLoading} type="submit" variant="primary">
                {isLoading && (
                  <i className="fa fa-spinner fa-spin text-danger"></i>
                )}{" "}
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddMainCategory;
