import React from "react";
import { useNavigate } from "react-router-dom";

type TUser={
  user_type?: any;
}
const DashboardFooter: React.FC<TUser> = ({user_type}) => {
  const navigate = useNavigate();
  const onLockScreen = () => {
    navigate("/zm/lock-screen", { replace: true });
  };
  return (
    <div className="dash_user_footer">
      <ul>
        <li className="text-white cursor-pointer">
          <a onClick={onLockScreen}>
            <i className="fa fa-power-off"></i>
          </a>
        </li>
        <li>
          <a href={`${user_type === "MINERAL" ? "/zm/mineral/chat": ""}`}>
            <i className="fa fa-comment"></i>
          </a>
        </li>
        <li>
          <a href="JavaScript:Void(0);">
            <i className="fa fa-cog"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DashboardFooter;
