import React from "react";

interface IFilter {
  value: string;
  filter: (value: string) => void;
}

const FilterProperties: React.FC<IFilter> = ({ value, filter }) => {
  return (
    <>
      <div className="_prt_filt_dash_flex">
        <div
          className="btn-group"
          role="group"
          aria-label="Basic radio toggle button group">
          <input
            type="radio"
            className="btn-check"
            name="chooselists"
            id="alllist"
            onClick={() => filter("")}
            checked={value === ""}
          />
          <label
            className="btn btn-md btn-outline-primary font--medium"
            htmlFor="alllist">
            All
          </label>

          <input
            type="radio"
            className="btn-check"
            name="chooselists"
            id="activelist"
            onClick={() => filter("CONFIRMED")}
            checked={value === "active"}
          />
          <label
            className="btn btn-md btn-outline-primary font--medium"
            htmlFor="activelist">
            Active
          </label>

          <input
            type="radio"
            className="btn-check"
            name="chooselists"
            id="expiredlist"
            onClick={() => filter("PENDING")}
            checked={value === "sold"}
          />
          <label
            className="btn btn-md btn-outline-primary font--medium"
            htmlFor="expiredlist">
            Sold
          </label>

          <input
            type="radio"
            className="btn-check"
            name="chooselists"
            id="draftlist"
            onClick={() => filter("CANCELLED")}
            checked={value === "rented"}
          />
          <label
            className="btn btn-md btn-outline-primary font--medium"
            htmlFor="draftlist">
            Rented
          </label>
        </div>
      </div>
    </>
  );
};

export default FilterProperties;
