// SearchableDropdown.tsx
import React from "react";
import Select, { SingleValue } from "react-select";

type Option = {
  value: number;
  label: string;
};

type SearchableDropdownProps = {
  options: Option[];
  onChange: (selectedOption: SingleValue<Option>) => void;
  placeholder?: string;
  defaultValue?: any;
};

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  onChange,
  defaultValue,
  placeholder = "Select an option...",
}) => {
  return (
    <Select
    defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      required
      isSearchable
    />
  );
};

export default SearchableDropdown;
