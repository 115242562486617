import React, { useRef } from "react";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import moment from "moment";
import Filters from "../../../admin/widgets/filters";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import Pagination from "../../../pagination/pagination_items";
import { useGetConstructionListQuery } from "../../../../redux/slices/construction_slice";
import { TConstruction } from "../../../../redux/types/construction.type";
import EditConstruction from "./edit_construction";
import DeleteConstructionModal from "./delete_construction_modal";
import AddConstruction from "./add_construction";
import { ErrorWidget } from "../../../utils/error_widget";
import LoadingSpinner from "../../../../components/widgets/spinner";

const ConstructionPage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [showDelete, setShowDelete] = React.useState(false);
  const [deleteProduct, setDeleteProduct] = React.useState<TConstruction | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    
    // Handler for date filter change
    const onDateFilter = (fromDate: string, toDate: string) => {
      setFromDate(fromDate);
      setToDate(toDate);
      // Call your API or perform filtering here
      console.log("Filtering from:", fromDate, "to:", toDate);
    };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  

  const handleShowDelete = (product: TConstruction) => {
    setDeleteProduct(product);
    setShowDelete(true);
  };

  const {
    data: itemList,
    isLoading,
    refetch,
    error,
  }: TConstruction | any = useGetConstructionListQuery({
    supplier: user?.id, 
    product_type: user?.user_type, 
    searchQuery: query, 
    page: currentPage,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>{user?.user_type} List</h4>
            <h6>Manage your {user?.user_type}s</h6>
          </div>
          <div className="page-btn">
            <AddConstruction fetchData={refetch}/>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter}/>

            {error ? (
             <ErrorWidget error={error}/>
            ) : itemList.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Sales</th>
                      <th>Ratings</th>
                      <th>Reference</th>
                      <th>Unit</th>
                      <th>Product Price</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.results.map((item: TConstruction, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            <div className="row">
                              <div className="col-md-4 mr-0 pr-0">
                                  <img
                                    className="product-imgs rounded"
                                    src={`${item.thumbnail}`}
                                    alt={item.productName}
                                  />
                              </div>
                              <div className="col-md-8 ml-0 pl-0">
                                <b>{item.productName.substring(0, 10)}</b>
                              </div>
                            </div>
                          </td>
                          <td>{item.quantity}</td>
                          <td>{item.construction_product_order.length}</td>
                          <td><i className="fa fa-star text-warning"></i> {item.rating_avg}</td>
                          <td>{item.product_unique_id}</td>
                          <td className="text-capitalize">{item.product_type}</td>
                          <td>{user?.company_currency.currency.currency_code} {Number(item.productPrice).toLocaleString()}</td>
                          <td className="text-capitalize">{item.status}</td>
                          <td>
                            {moment(item.created_at).format("Do MMMM, YYYY")}
                          </td>
                          <td>
                            
                            <a
                              className="me-3 confirm-text"
                              onClick={() => handleShowDelete(item)}
                            >
                              <img src={deleteIcon} alt="img" />
                            </a>
                            <a
                              className="me-3"
                              href={`/zm/dashboard/${user?.user_type}/product_details/?product_id=${item.id}`}
                            >
                              <i className="fa fa-arrow-right"></i>
                            </a>
                          </td>
                          
                          <DeleteConstructionModal
                            construction={deleteProduct === null ? item : deleteProduct}
                            show={showDelete}
                            handleClose={handleCloseDelete}
                            setShow={setShowDelete}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={itemList.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConstructionPage;
