import http from "./http.files";

class DashbordServices {
  // CREATE BRAND
  async createBrand(data: any) {
    return await http.post(
      `/cars/v1/everyday/car_brands/tech/`,
      data
    );
  }

  async updateBrand(data: any) {
    return await http.patch(
      `/cars/v1/everyday/car_brands/tech/${data.id}/`,
      data
    );
  }
  // CREATE MINERAL CATEGORY
  async createMineralCategory(data: any) {
    let form_data = new FormData();
    form_data.append("categoryName", data.categoryName);
    form_data.append("image", data.image);
    return await http.post(
      `/administrator/v1/everyday/admin_mineral_category/tech/`,
      data
    );
  }
// CREATE MINERAL
  async createMineral(data: any) {
    return await http.post(
      `/administrator/v1/everyday/admin_minerals/tech/`,
      data
    );
  }

  async createFuel(data: any) {
    return await http.post(
      `/administrator/v1/everyday/admin_fuels/tech/`,
      data
    );
  }
  

// CREATE AGRICULTURE
async createAgriculture(data: any) {
  return await http.post(
    `/administrator/v1/everyday/admin_agriculture/tech/`,
    data
  );
}
// CREATE INVESTMENT PROJECT
async createInvestmentProject(data: any) {
  return await http.post(
    `/investments/v1/everyday/admin_investments/tech/`,
    data
  );
}

// CREATE CONSTRUCTION
async createConstruction(data: any) {
  return await http.post(
    `/administrator/v1/everyday/admin_construction/tech/`,
    data
  );
}

// CREATE PRODUCT
async createProduct(data: any){
  return await http.post(
    `/administrator/v1/everyday/admin_administrator/tech/`,
    data
  );
}


// UPLOAD PRODUCT IMAGES
async uploadProductImages(data: any){
  return await http.post(
    `/administrator/v1/everyday/upload_product_images/tech`,
    data
  );
}
}

export default new DashbordServices();
