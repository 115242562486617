import React, { useEffect, useState } from "react";
import moment from "moment";
import banner from "../../assets/img/page-title.png";
import { PropertyModel } from "../../models/product_model";
import DataServices from "../services/http.product.services";
import { RequestsModel } from "../../models/requests_model";
import RentedOrSoldProperty from "./properties/rentedOrSold";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useGetRequestsQuery } from "../../redux/slices/property/property_requests_slice";
import RequestCard from "./requests/widgets/request_card";
import IPropertyPayments from "../../models/payments_model";
import { useGetPaymentsQuery } from "../../redux/slices/property/property_payments_slice";
import { formatPrice } from "../utils/format_price";

const Dashboard: React.FC =()=> {
  const user = useAppSelector((state) => state.auth.user);
  const [properties, setProperties] = useState<PropertyModel[]>([]);
  const [rentProperties, setRentProperties] = useState<PropertyModel[]>([]);
  const [saleProperties, setSaleProperties] = useState<PropertyModel[]>([]);
  const { data: requests, isLoading }: RequestsModel | any = useGetRequestsQuery();

  const {
    data: payments,
    isLoading: fetchingPayments,
  }: IPropertyPayments | any = useGetPaymentsQuery();

  const fetchProperties = () => {
    DataServices.getProperties(user?.id)
      .then((res) => {
        setProperties(res.data);
      })
      .catch((err) => {});
    // get property rent
    DataServices.getPropertyByLabel("rent", user?.id).then((res) => {
      setRentProperties(res.data);
    });
    // get property sale
    DataServices.getPropertyByLabel("sale", user?.id).then((res) => {
      setSaleProperties(res.data);
    });
  };

  useEffect(() => {
    fetchProperties();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="content">
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray-simple pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            {/* <!-- ============================ Page Title Start================================== --> */}
            <div
              className="vpage-title"
              style={{
                background: `#017efa url(${banner}) no-repeat`,
              }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h2 className="ipt-title text-capitalize">
                      Hi, {`${user?.first_name} ${user?.last_name}`}{" "}
                    </h2>
                    <span className="ipn-subtitle">
                      Manage your profile and view properties
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ============================ Page Title End ================================== --> */}
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="veshm-dash-wrapper widget-1">
                  <div className="veshm-dash-icon">
                    <div className="vsm-icon-lio bg-light-success text-success">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                  </div>
                  <div className="veshm-dash-block">
                    <div className="veshm-dash-block_content">
                      <h4>{formatPrice({currency: user?.company_currency.currency.currency_code, price: 0})}</h4>
                      <h6>Total Income</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="veshm-dash-wrapper widget-2">
                  <div className="veshm-dash-icon">
                    <div className="vsm-icon-lio bg-light-danger text-danger">
                      <i className="fab fa-gg-circle"></i>
                    </div>
                  </div>
                  <div className="veshm-dash-block">
                    <div className="veshm-dash-block_content">
                      <h4>{Number(properties.length).toLocaleString()}</h4>
                      <h6>Total Properties</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="veshm-dash-wrapper widget-3">
                  <div className="veshm-dash-icon">
                    <div className="vsm-icon-lio bg-light-warning text-warning">
                      <i className="fa fa-home"></i>
                    </div>
                  </div>
                  <div className="veshm-dash-block">
                    <div className="veshm-dash-block_content">
                      <h4>{rentProperties.length}</h4>
                      <h6>Properties for Rent</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="veshm-dash-wrapper widget-3">
                  <div className="veshm-dash-icon">
                    <div className="vsm-icon-lio bg-light-warning text-warning">
                      <i className="fa fa-home"></i>
                    </div>
                  </div>
                  <div className="veshm-dash-block">
                    <div className="veshm-dash-block_content">
                      <h4>{saleProperties.length}</h4>
                      <h6>Properties for Sale</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--  row --> */}

            <div className="row">
              <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h6>Property Requests</h6>
                  </div>
                  <div className="ground-client-list">
                    {isLoading ? (
                      <center>
                        <i className="fa fa-spin fa-fast"></i>
                      </center>
                    ) : requests.length === 0 ? (
                      <div
                        className="alert alert-warning text-center"
                        role="alert">
                        <i className="fa fa-bell"></i> No Requests Found
                      </div>
                    ) : (
                      requests
                        .slice(0, 4)
                        .map((item: RequestsModel, idx: number) => {
                          return <RequestCard key={idx} request={item} />;
                        })
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h6>Notifications</h6>
                  </div>
                  <div className="ground-list ground-list-hove">
                    {fetchingPayments ? (
                      <center>
                        <i className="fa fa-spin fa-fast"></i>
                      </center>
                    ) : payments.length === 0 ? (
                      <div
                        className="alert alert-info text-center"
                        role="alert">
                        <i className="fa fa-bell"></i> No Notifications Found
                      </div>
                    ) : (
                      payments.slice(0, 4).map((item: IPropertyPayments, index: number) => {
                          return (
                            <div
                              key={index}
                              className="ground ground-single-list">
                              <a href="JavaScript:Void(0);">
                                <div className="btn-circle-40 text-warning bg-light-warning">
                                  <i className="fa fa-bell"></i>
                                </div>
                              </a>

                              <div className="ground-content">
                                <h6>
                                  <a className="capitalize" href="#">
                                    <strong>
                                      {item.property.property.propertyName}
                                    </strong>
                                  </a>
                                  <p className="capitalize">
                                    {item.client.first_name}{" "}
                                    {item.client.last_name}
                                  </p>
                                </h6>
                                <span className="small">
                                  {moment(item.created_at).fromNow()}
                                </span>
                              </div>
                            </div>
                          );
                        })
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}

            <RentedOrSoldProperty />
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
}

export default Dashboard;
