import React from "react";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetRealEstateQuery } from "../../../../redux/slices/property/propertySlice";
import { IPropertyImages, PropertyModel } from "../../../../models/product_model";
import Amenties from "./amenities";
import Features from "./features";
import FloorPlans from "./floor_plans";
import { ErrorWidget } from "../../../utils/error_widget";
import { formatPrice } from "../../../utils/format_price";
import EditPropertyStatus from "./edit_property_status";

const RealDeals: React.FC = () => {
  const [show, setShow] = React.useState(false);
    const [propertyItem, setPropertyItem] = React.useState<PropertyModel | any>();
  const queryParams = new URLSearchParams(window.location.search);
  const property_id = queryParams.get("property");
  const handleClose = () => {
      setShow(false);
    };
  
    const handleShow = (property: PropertyModel) => {
      setPropertyItem(property);
      setShow(true);
    };
  const {
      data: property,
      isLoading: isFetching,
      error,
    }: PropertyModel | any = useGetRealEstateQuery(Number(property_id));
  
    if (isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }

  return (
    <div className="content">
      <BreadCrumbs
        title={"Real Estate"}
        subTitle={`View Real Estate Properties`}
      />
      <EditPropertyStatus
        property={propertyItem}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
          />
      <div className="card">
        <div className="card-body">
          {error ? <ErrorWidget error={error}/>: <>
          <section className="gray-simple">
            <div className="container">
            <div className="row">
        {property.property_image.map((file: IPropertyImages, index: number) => {
          return (
            <div key={index} className="col-md-2 col-lg-3 shadow-xl image-full">
              <img
                src={`${process.env.REACT_APP_API_URL}${file.image}`}
                style={{
                  width: "100%",
                  height: "140px",
                }}
                className="img-responsive rounded"
                alt="room"
              />
            </div>
          );
        })}
      </div>
              <div className="row">
                {/* <!-- property main detail --> */}
                <div className="col-lg-8 col-md-12 col-sm-12">
                  {/* <!-- Main Info Detail --> */}
                  <div className="vesh-detail-bloc">
                    <div className="vesh-detail-headup">
                      <div className="vesh-detail-headup-first">
                        <div className="prt-detail-title-desc">
                          <span className="label label-success mb-5 capitalize">
                            {`for ${property?.propertyLabel}`}
                          </span>
                          <h2 className="mt-3 mb-3 capitalize">
                            {property?.propertyName}
                          </h2>
                          <span className="text-mid capitalize">
                            <i className="fa-solid fa-location-dot me-2"></i>
                            {property?.propertyCountry},{" "}
                            {property?.propertyAddress}
                          </span>
                          <div className="list-fx-features mt-2">
                            <div className="list-fx-fisrt">
                              <span className="label font--medium label-light-success me-2">
                                {property?.bedrooms} Bedrooms
                              </span>
                              <span className="label font--medium label-light-info me-2">
                                {property?.bathrooms} Bathrooms
                              </span>
                              <span className="label font--medium label-light-warning me-2">
                                {property?.garages} Garages
                              </span>
                              <span className=" label font--medium label-light-danger">
                                {Number(property?.squareFeet).toLocaleString()}{" "}
                                Sqft
                              </span>
                            </div>
                            <div className="list-fx-last"></div>
                          </div>
                        </div>
                      </div>
                      <div className="vesh-detail-headup-last">
                        <h3 className="prt-price-fix theme-cl">
                          {formatPrice({currency: property?.user.company_currency.currency.currency_code, price: property?.propertyPrice})}
                          <span>
                            {property?.propertyLabel === "rent"
                              ? "Per Month"
                              : "One Time"}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>

                  {/* <!-- About Property Detail --> */}
                  <div className="vesh-detail-bloc">
                    <div className="vesh-detail-bloc_header">
                      <h4 className="property_block_title no-arrow">
                        About Property
                      </h4>
                    </div>
                    <div className="vesh-detail-bloc-body">
                      <div className="row g-3">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <blockquote>
                            <p>{property?.propertyDescription}</p>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Basic Detail --> */}
                  <div className="vesh-detail-bloc">
                    <div className="vesh-detail-bloc_header">
                      <a href="javascript:void(0);" aria-expanded="false">
                        <h4 className="property_block_title">Basic Detail</h4>
                      </a>
                    </div>
                    <div
                      id="basicinfo"
                      className="panel-collapse"
                      aria-labelledby="basicinfo">
                      <div className="vesh-detail-bloc-body">
                        <div className="row g-3">
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-kitchen-set"></i>
                              </div>
                              <div className="ilio-text">1 Kitchen</div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-bed"></i>
                              </div>
                              <div className="ilio-text">
                                {property?.bedrooms} Bedrooms
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-bath"></i>
                              </div>
                              <div className="ilio-text">
                                {property?.bathrooms} Bathrooms
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-layer-group"></i>
                              </div>
                              <div className="ilio-text">
                                {Number(property?.squareFeet).toLocaleString()}{" "}
                                sq ft
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-warehouse"></i>
                              </div>
                              <div className="ilio-text">
                                {property?.garages} Garage
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa-regular fa-building"></i>
                              </div>
                              <div className="ilio-text capitalize">
                                {property?.propertyType}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-building-wheat"></i>
                              </div>
                              <div className="ilio-text">
                                Built in {property?.yearBuilt}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="ilio-icon-wrap">
                              <div className="ilio-icon">
                                <i className="fa fa-building-circle-check"></i>
                              </div>
                              <div className="ilio-text">Active</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {property?.propertyType === "farms" || property?.propertyType === "lands" ? null : <div>
                    {/* <!-- Amenties Detail --> */}
                  {<Amenties property={property} />}

                  {/* <!-- All features Detail --> */}
                  <Features property={property} />

                  {/* <!-- Floor Plan --> */}
                  {<FloorPlans property={property} />}

                  {/* <!-- All over Review DOWN --> */}
                  </div>}
                  
                </div>
                {/* <!-- property main detail --> */}

                {/* <!-- Property Sidebar --> */}

                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="pg-side-groups">
                      <div className="pg-side-block">
                        <div className="pg-side-block-head">
                          <div className="pg-side-left">
                            <div className="pg-side-thumb">
                              <img
                                src="https://oktelugu.com/wp-content/uploads/2022/06/rent.jpg"
                                className="img-fluid circle rounded"
                                style={{ width: "80px", height: "70px" }}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="pg-side-right">
                            <div className="pg-side-right-caption">
                              <h4>{property?.user.company_name}</h4>
                              
                            </div>
                          </div>
                        </div>
                        <div className="pg-side-block-body">
                          <div className="pg-side-block-info">
                            <h5 className="text-center mb-3">
                              Contact Details
                            </h5>
                            <div className="vl-elfo-group">
                              <div className="vl-elfo-icon">
                                <i className="fa fa-user"></i>
                              </div>
                              <div className="vl-elfo-caption">
                                <h6>Agent Name</h6>
                                <a
                                  href={`/agent-properties/?property=${property?.slug}&property_id=${property?.id}`}>
                                  <p className="capitalize">
                                    {property?.contactName}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="vl-elfo-group">
                              <div className="vl-elfo-icon">
                                <i className="fa fa-phone-volume"></i>
                              </div>
                              <div className="vl-elfo-caption">
                                <h6>Phone Number</h6>
                                <a href={`tel:${property?.contactPhone}`}>
                                  <p>{property?.contactPhone}</p>
                                </a>
                              </div>
                            </div>
                            <div className="vl-elfo-group">
                              <div className="vl-elfo-icon">
                                <i className="fa fa-envelope"></i>
                              </div>
                              <div className="vl-elfo-caption">
                                <h6>E-mail</h6>
                                <a href={`mailto:${property?.contactEmail}`}>
                                  <p>{property?.contactEmail}</p>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="pg-side-block-buttons">
                            
                              <button onClick={()=> handleShow(property)} className="btn btn-sm btn-info text-white">
                              {property?.status} <i className="fa fa-edit"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          </>}
          
        </div>
      </div>
    </div>
  );
};

export default RealDeals;
