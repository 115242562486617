import React from "react";
import dashboard from "../../../assets/img/logo.jpg";
import product from "../../../assets/img/icons/product.svg";
import transfer1 from "../../../assets/img/icons/transfer1.svg";
import {  useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";
import DashboardAvatar from "../../widgets/dashboard_avatar";
import DashboardFooter from "../../widgets/dsahboard_footer";

const SideBar: React.FC = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  function randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var token = randomString(100, `${localStorage.getItem("access")}`);
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
          <DashboardAvatar />
            <ul>
              <li>
                <a className={`${location.pathname === "/zm/mineral/" ? "active" : ""}`} href={`/zm/mineral/?uid=${user?.id}`}>
                  <img style={{borderRadius: '50%'}} src={dashboard} alt="img" />
                  <span>Dashboard</span>
                </a>
              </li>
              <li
                className={`${
                  location.pathname === "/zm/mineral/profile/"
                    ? "active"
                    : ""
                }`}
              >
                <a href="/zm/mineral/profile/">
                  <i className="fa fa-user-tie"></i>
                  <span>My Profile</span>
                  <span className="menu-arrow"></span>
                </a>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={product} alt="img" />
                  <span>Minerals</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <a className={`${location.pathname === "/zm/mineral/mineral_categories" ? "active" : ""}`} href="/zm/mineral/mineral_categories">Mineral Categories</a>
                  </li>
                  <li>
                    <a className={`${location.pathname === "/zm/mineral/minerals_list" ? "active" : ""}`} href="/zm/mineral/minerals_list">Mineral List</a>
                  </li>
                </ul>
              </li>   
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={transfer1} alt="img" />
                  <span>Customer Orders</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <a className={`${location.pathname === "/zm/mineral/pending_orders_list" ? "active" : ""}`} href="/zm/mineral/pending_orders_list">Pending Orders List</a>
                  </li>
                  <li>
                    <a className={`${location.pathname === "/zm/mineral/confirmed_orders_list" ? "active" : ""}`} href="/zm/mineral/confirmed_orders_list">Confirmed Orders List</a>
                  </li>
                  <li>
                    <a className={`${location.pathname === "/zm/mineral/cancelled_orders_list" ? "active" : ""}`} href="/zm/mineral/cancelled_orders_list">Cancelled Orders List</a>
                  </li>
                </ul>
              </li>     
              <li>
                <a  className={`${location.pathname === "/zm/mineral/customers" ? "active" : ""}`}href="/zm/mineral/customers">
                  <i className="fa fa-users"></i>
                  <span>Customers</span>
                  <span className="menu-arrow"></span>
                </a>
              </li>
              <li>
                <a className={`${location.pathname === "/zm/mineral/terms_of_service" ? "active" : ""}`} href={`/zm/mineral/terms_of_service/${token}/${user?.id}`}>
                  <i className="fa fa-book"></i>
                  <span>Terms & Conditions</span>
                  <span className="menu-arrow"></span>
                </a>
              </li>
              <li>
                <a className={`${location.pathname === "/zm/mineral/privacy_policy" ? "active" : ""}`} href={`/zm/mineral/privacy_policy/${token}/${user?.id}`}>
                  <i className="fa fa-book"></i>
                  <span>View Privacy Policy</span>
                  <span className="menu-arrow"></span>
                </a>
              </li>
              <li
                className={`${
                  location.pathname ===
                  "/zm/mineral/change-password"
                    ? "active"
                    : ""
                }`}
              >
                <a href="/zm/mineral/change-password">
                  <i className="fa fa-unlock-alt"></i>
                  <span>Change Password</span>
                  <span className="menu-arrow"></span>
                </a>
              </li>
            </ul>
            <DashboardFooter user_type={user?.user_type} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
