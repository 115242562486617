import { apiSlice } from "../services/apiSlice";
import { TCountyCurrency } from "../types/country_currency.type";

export const countryCurrencySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCurrency: builder.mutation<TCountyCurrency, Partial<TCountyCurrency>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_country_currency/tech/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TCountyCurrency"],
      transformResponse: (result: { item: TCountyCurrency }) =>
        result.item,
    }),
    updateCurrency: builder.mutation<TCountyCurrency, Partial<TCountyCurrency>& Pick<TCountyCurrency, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_country_currency/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TCountyCurrency"],
      transformResponse: (response: { item: TCountyCurrency }) =>
        response.item,
    }),
    getCurrency: builder.query<TCountyCurrency, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_country_currency/tech/${id}/`,
      }),
      providesTags: ["TCountyCurrency"],
    }),
    getCurrencyList: builder.query<TCountyCurrency[], any>({
      query: ({status, page, searchQuery}) => `/administrator/v1/everyday/admin_country_currency/tech/?status=${status}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TCountyCurrency[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TCountyCurrency', id })) : ['TCountyCurrency'],
      // keepUnusedDataFor: 5,
    }),
    deleteCurrency: builder.mutation<TCountyCurrency, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_country_currency/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TCountyCurrency"],
    }),
  }),
});
export const {
  useCreateCurrencyMutation,
  useDeleteCurrencyMutation,
  useGetCurrencyListQuery,
  useGetCurrencyQuery,
  useUpdateCurrencyMutation
} = countryCurrencySlice;
