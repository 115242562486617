import React from 'react';

const CustomerPage: React.FC = () => {
    return (
        <div style={{
            fontFamily: 'Arial, sans-serif',
            textAlign: 'center',
            backgroundColor: '#f0f8ff',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className="login-logo">
                  <img style={{borderRadius: '50%', width: '100px', height: '100px'}} src={require("../../assets/img/logo.jpg")} alt="img" />
                </div>
            <h1 style={{ fontSize: '2.5rem', color: '#333' }}>Hi There!</h1>
            <p style={{ fontSize: '1.25rem', color: '#555', marginTop: '1rem' }}>
                Please proceed to the app to continue exploring our features.
            </p>
            <p style={{ fontSize: '1rem', color: '#777', marginTop: '1rem' }}>
                If you haven't downloaded the app yet, use the links below to get started.
            </p>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '1rem',
                marginTop: '2rem',
            }}>
                <a
                    href="https://play.google.com/store"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: 'none',
                        padding: '0.75rem 1.5rem',
                        backgroundColor: '#34a853',
                        color: 'white',
                        borderRadius: '8px',
                        fontSize: '2rem',
                        fontWeight: 'bold',
                    }}
                >
                  <img src={require("../../assets/img/icons/google.png")} alt="" />  Download for Android
                </a>
                <a
                    href="https://www.apple.com/app-store/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: 'none',
                        padding: '0.75rem 1.5rem',
                        backgroundColor: '#007aff',
                        color: 'white',
                        borderRadius: '8px',
                        fontSize: '2rem',
                        fontWeight: 'bold',
                    }}
                >
                   <img src={require("../../assets/img/icons/facebook.png")} alt="" /> Download for iOS
                </a>
            </div>
            <footer style={{
                marginTop: '3rem',
                fontSize: '0.9rem',
                color: '#777',
            }}>
                Need help? <a href="/support" style={{ color: '#007aff', textDecoration: 'none' }}>Contact Support</a>
            </footer>
        </div>
    );
};

export default CustomerPage;
