import React from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import { useGetAdminCustomerBookingsQuery } from "../../../../redux/slices/admin_hire_slice";
import Pagination from "../../../pagination/pagination_items";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";
import ReservationCard from "../transactions/reservation_card";
import OfflineRentalsForm from "./create_offline_record";
import { useHireCarMutation, useUpdateBookingMutation } from "../../../../redux/slices/car_booking_slice";
import { toast } from "react-toastify";

const CarOfflineRentals: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [status, setStatus] = React.useState("")
  const [query, setQuery] = React.useState("")
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const [offlineRental, { isLoading: postingData, error: errorPosting, isSuccess: successPosting }] = useHireCarMutation();
  const [updateOfflineRental, { isLoading: updatingData, error: errorUpdating, isSuccess: successUpdating }] = useUpdateBookingMutation();

  const {
    data: bookings,
    isLoading,
    error,
  }: ICarBooking | any = useGetAdminCustomerBookingsQuery({
    company: user?.id,
    status: status,
    searchQuery: query, 
    page: currentPage,
    rentalType: 'OFFLINE',
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "10%" }} className="text-center">
      <LoadingSpinner />
    </div>
    );
  }

  

  const handleAdd = () => {
    setInitialValues(null); // Clear values for a new rental
    setModalVisible(true);
  };

  const handleEdit = (rental: any) => {
    setInitialValues(rental); // Pass the rental data for editing
    setModalVisible(true);
  };

  const handleClose = () => setModalVisible(false);

  const handleSubmit = (data: any) => {
    if (initialValues) {
      console.log("Editing rental:", data);
      // Call API to update rental
      updateOfflineRental({id: data.id, ...data})
    } else {
      console.log("Adding new rental:", data);
      // Call API to create rental
      offlineRental(data)
        .unwrap()
        .then((res) => {
          toast.success("Data submitted Successfully");
        })
        .catch((err) => {
          toast.error("Error: " + err.data["detail"]);
        });
    }
    setModalVisible(false);
  };


  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Car Rentals Offline Records List</h4>
            <h6>Manage your Car Rentals Offline Records</h6>
          </div>
          <button onClick={handleAdd} className="btn btn-sm btn-primary">
            <i className="fa fa-plus"></i>
              Record Rental
            </button>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="form-group">
              <label htmlFor="search">Search</label>
              <input type="search" name="search" className="form-control" placeholder="search records..." onChange={onChange} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select onChange={(e) => setStatus(e.target.value)} name="status" id="status" className="form-control">
                <option value="">All</option>
                <option value="PENDING">Pending</option>
                <option value="CONFIRMED">Confirmed</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
            </div>
          </div>
        </div>
      {error ?  <ErrorWidget error={error} />: bookings.results.length === 0 ? (
        <center>
          <div
            className="text-center alert alert-info custom-length"
            role="alert"
          >
            <i className="fa fa-car fa-5x"></i>
            <h1>NO RENTALS FOUND</h1>
          </div>
        </center>
      ) : (
        <>
        
        <div className="table-responsive mb-4">
          <table className="table datanew">
            <thead>
              <tr>
                <th>#</th>
                <th>Car</th>
                <th>Pick Up Date</th>
                <th>Customer Name</th>
                <th>Reference</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Total</th>
                <th>Paid</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {bookings.results.map((item: ICarBooking, index: number) => {
                return (
                  <ReservationCard item={item} index={index} key={index} />
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
              currentPage={currentPage}
              lastPage={bookings.total_pages}
              maxLength={7}
              setCurrentPage={setCurrentPage}
            />
        </>
        
      )}
      </div>
      
      {/* <button onClick={() => handleEdit({  })}>Edit Rental</button> */}
      
      <OfflineRentalsForm
        visible={isModalVisible}
        onClose={handleClose}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        company_id={user?.id}
        isLoading={postingData}
      />
    </>
  );
};

export default CarOfflineRentals;
