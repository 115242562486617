import React from "react";
import moment from "moment";
import { PropertyModel } from "../../../models/product_model";
import DataService from "../../services/http.product.services";
import PaymentModal from "./widgets/paymentModal";
import DeletePayment from "./widgets/deleteModal";
import FilterProperties from "./filter";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useGetRealEstateListQuery } from "../../../redux/slices/property/propertySlice";
import LoadingSpinner from "../../../components/widgets/spinner";
import Pagination from "../../pagination/pagination_items";
import { formatPrice } from "../../utils/format_price";

function Properties() {
  const user = useAppSelector((state) => state.auth.user);
  const [property, setProperty] = React.useState<PropertyModel>();
  const [selected, setSelected] = React.useState<string>("")
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }


  const details = (property: PropertyModel) => {
    setProperty(property);
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { data: properties, isLoading, error }: PropertyModel | any =
  useGetRealEstateListQuery({
    supplier: user?.id, status: selected, searchQuery: query, page: currentPage});

if (isLoading) {
  return (
    <div className="text-center mt-5">
     <LoadingSpinner />
    </div>
  );
}



  const filter= (value: string) => {
      setSelected(value);
    
  }

  return (
    <div className="content">
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="_prt_filt_dash">
                  {/* filter here */}
                <div className="row">
                  <div className="col-md-9">
                  <FilterProperties value={selected} filter={filter} />
                  </div>
                  <div className="col-md-3">
                  <div className="_prt_filt_dash_last m2_hide">
                    <div className="_prt_filt_radius"></div>
                    <div className="_prt_filt_add_new">
                      <a
                        href="/zm/accounts/realestate/add-property"
                        className="prt_submit_link">
                        <i className="fas fa-plus-circle"></i>
                        <span className="d-none d-lg-block d-md-block">
                          List New Property
                        </span>
                      </a>
                    </div>
                  </div>
                  </div>
                  </div>  
                </div>
              </div>
            </div>
            <div className="alert alert-info text-center" role="alert">
              <h4>
                Properties with{" "}
                <span className="text-warning">Pending Status</span> are not
                Listed until payments are made for them
              </h4>
            </div>
            <div className="form-group col-md-3 col-md-6 col-xs-6 m-2">
              <input type="text" onChange={onChange} value={query} className="form-control" placeholder="search for properties" />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="dashboard_property">
                  <div className="table-responsive">
                    {error ? (
                      <div
                        className="alert alert-danger text-center"
                        role="alert">
                        <i className="fa fa-warning fa-5x"></i>
                        <div className="mt-3">{error.data}</div>
                      </div>
                    ) : properties.length === 0 ? (
                      <div className="alert alert-info text-center">
                        <i className="fa-solid fa-house-fire fa-10x"></i>
                        <h3>
                          You haven't added any Listing, click{" "}
                          <a
                            className="btn btn-sm btn-primary"
                            href="/zm/accounts/realestate/add-property">
                            <i className="fa fa-plus-circle"></i> HERE
                          </a>{" "}
                          to add Your listings
                        </h3>
                      </div>
                    ) : (
                      <>
                      <table className="table table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Property</th>

                            <th scope="col" className="m2_hide">
                              Stats
                            </th>
                            <th scope="col" className="m2_hide">
                              Posted On
                            </th>
                            <th scope="col">Listing Status</th>
                            <th scope="col">Property Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <!-- tr block --> */}
                          {properties.results.map((item: PropertyModel, index: number) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="dash_prt_wrap">
                                    <div className="dash_prt_thumb">
                                      <img
                                        src={`${item.image}`}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="dash_prt_caption">
                                      <h5 className="text-capitalize">
                                        {item.propertyName.substring(0, 20)}...
                                      </h5>
                                      <div className="prt_dashb_lot capitalize">
                                        {item.propertyAddress.substring(0, 20)}...
                                      </div>
                                      <div className="prt_dash_rate">
                                        <span>
                                          {`${formatPrice({currency: item.user.company_currency.currency.currency_code, price: item.propertyPrice})}${item.propertyLabel === "rent" ? "/Month" : ""}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className="m2_hide">
                                  <div className="_leads_view">
                                    <h5 className="up">
                                      {Number(item.propertyReviews).toLocaleString()}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>Total Views</span>
                                  </div>
                                </td>
                                <td className="m2_hide">
                                  <div className="_leads_posted">
                                    <h5>
                                      {moment(item.created_at).format( "Do MMM YYYY - HH:MM a")}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>
                                      {moment(item.created_at).fromNow()}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="_leads_status text-capitalize">
                                    <span
                                      className={`text-capitalize ${item.status === "PENDING" ? "expire" : "active"}`}>
                                      {item.status}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-capitalize">
                                  {item.rentedOrSold && item.propertyLabel === "rent" ? (
                                    <span>
                                      <i className="fa fa-home"></i> Property
                                      Rented
                                    </span>
                                  ) : item.rentedOrSold &&
                                    item.propertyLabel === "sale" ? (
                                    <span>
                                      <i className="fa fa-home"></i> Property
                                      Sold
                                    </span>
                                  ) : (
                                    `for ${item.propertyLabel}`
                                  )}
                                </td>
                                <td>
                                  
                                    <div className="_leads_action">
                                      <a href={`/zm/accounts/realestate/edit-property/?property=${item.id}`}>
                                        <i className="fas fa-edit"></i>
                                      </a>
                                      <a
                                        onClick={() => details(item)}
                                        className="bg-danger text-white"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                        href="#">
                                        <i className="fas fa-trash"></i>
                                      </a>
                                      {item.status === "PENDING" && (
                                        <a
                                          onClick={() => details(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#paymentModal"
                                          href="#">
                                          <i className="fas fa-dollar-sign"></i>
                                        </a>
                                      )}
                                    </div>
                                  
                                </td>
                                <PaymentModal
                                  property={property === null ? item : property}
                                />
                                <DeletePayment
                                  property={property === null ? item : property}
                                />
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <Pagination
                        currentPage={currentPage}
                        lastPage={properties.total_pages}
                        maxLength={7}
                        setCurrentPage={setCurrentPage}
                      />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
}

export default Properties;
