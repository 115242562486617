import React from 'react';
import { ICarImages, ICarRental, ICarReview } from '../../../../redux/types/car_rental.type';
import { useGetAdminCarQuery } from '../../../../redux/slices/admin_hire_slice';
import { ICarBooking } from '../../../../redux/types/car_booking.type';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { ErrorWidget } from '../../../utils/error_widget';
import BreadCrumbs from '../../../widgets/bread_crumbs';
import moment from 'moment';
import { formatPrice } from '../../../utils/format_price';
import { renderStars } from '../../../utils/render_star';


const CarDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const car_id = queryParams.get("car_id");
    const {
      data: car,
      isLoading,
      error,
    }: ICarRental | any = useGetAdminCarQuery(Number(car_id));

    if (isLoading) {
      return (
        <div style={{ marginTop: "10%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }

    if (error) {
      return <ErrorWidget error={error} />
    }

  return (
    <div className='content'>
      <BreadCrumbs
        title={"Car Details"}
        subTitle={"View/Manage Car Details"}
      />
      <h1 className='text-capitalize'><b>{car.car_model} - {car.year}</b></h1>
      <h3 className='text-uppercase'><b>{car.car_brand.brand_name}</b></h3>
      
      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        {/* Car Images */}
        <div style={{ flex: 1 }}>
          <h2>Images</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {car.car_images.map((item: ICarImages) => (
              <img 
                key={item.id} 
                src={`${process.env.REACT_APP_API_URL}${item.image}`} 
                alt={car.car_model} 
                style={{ width: '150px', height: '100px', objectFit: 'cover', borderRadius: '5px' }} 
              />
            ))}
          </div>
        </div>

        {/* Car Details */}
        <div style={{ flex: 2 }}>
          <h2>Details</h2>
          <div className="row">
            <div className="col-md-3 mb-3">
            <p><strong>Gear Type:</strong> {car.gear_type}</p>
            </div>
            <div className="col-md-3 mb-3">
            <p><strong>Seats:</strong> {car.seats}</p>
            </div>
            <div className="col-md-3 mb-3">
            <p><strong>Fuel Litres:</strong> {car.fuel_litres}L</p>
            </div>
            <div className="col-md-3 mb-3">
            <p><strong>Speedometer:</strong> {car.speedometer} km</p>
            </div>
            <div className="col-md-4">
            <p><strong>Music Types:</strong> {car.music_types}</p>
            </div>
            <div className="col-md-4">
            <p><strong>Available:</strong> {car.is_available ? 'Yes' : 'No'}</p>
            </div>
            <div className="col-md-4">
            <p><strong>Active:</strong> {car.is_active ? 'Yes' : 'No'}</p>
            </div>
          </div>
          <br />
          <p><strong>Description:</strong> {car.description}</p>
          
        </div>
      </div>

      <div className="row">
        {/* Pricing and Fees */}
      <div className='col-md-4' style={{ marginTop: '20px' }}>
        <h2>Pricing</h2>
        <p><strong>Local Price:</strong> {formatPrice({ currency: car.user.company_currency.currency.currency_code, price: car.local_price })}</p>
        <p><strong>Outside Town:</strong> {formatPrice({ currency: car.user.company_currency.currency.currency_code, price: car.outside_town })}</p>
        <p><strong>Viewing Fee:</strong> {formatPrice({ currency: car.user.company_currency.currency.currency_code, price: car.car_viewing_fee })}</p>
        <p><strong>Deal Price:</strong> {formatPrice({ currency: car.user.company_currency.currency.currency_code, price: car.car_deal_price })}</p>
      </div>
      {/* Documents */}
      <div className='col-md-8' style={{ marginTop: '20px' }}>
        <h2>Documents</h2>
        <div className="row">
          <div className="col-md-4">
            <p><strong>White Book:</strong></p>
            <img 
                src={`${process.env.REACT_APP_API_URL}${car.white_book}`} 
                alt={car.car_model} 
                style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '5px' }} 
              />
          </div>
          <div className="col-md-4">
          <p><strong>Fitness:</strong></p>
          <img 
            src={`${process.env.REACT_APP_API_URL}${car.fitness}`} 
            alt={car.car_model} 
            style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '5px' }} 
          />
          </div>
          <div className="col-md-4">
          <p><strong>Insurance:</strong></p>
          <img 
                src={`${process.env.REACT_APP_API_URL}${car.insurance}`} 
                alt={car.car_model} 
                style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '5px' }} 
              />
          </div>
        </div>
      </div>
      </div>
      
      {/* Rental Information */}
      <div style={{ marginTop: '20px' }}>
        <h2><b>Rental History</b></h2>
        {car.car_user_rental.length === 0 ? <h1><b>No rental history available.</b></h1>:
        <div className="table-responsive dataview">
            <table className="table datatable">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Client</th>
                    <th>Amount</th>
                    <th>Days</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Picked Up</th>
                    <th>Returned</th>
                    <th>Status</th>
                    <th>Posted Date</th>
                    {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {car?.car_user_rental.map((item: ICarBooking, index: number)=>{
                    return <tr>
                    <td>{index + 1}</td>
                    <td className="productimgname">
                        <a href={`/zm/car_dashboard/rental_details?rental_id=${item.id}`}>{item.client.first_name}{item.client.last_name}</a>
                    </td>
                    <td>{formatPrice({currency: item.company.company_currency.currency.currency_code, price: item.rental_price})}</td>
                    <td>{item.days}</td>
                    <td>{moment(item.client_pickup_date_time).format("Do MMM YYYY HH:mm a")}</td>
                    <td>{moment(item.client_return_date_time).format("Do MMM YYYY HH:mm a")}</td>
                    <td>{item.client_car_picked_up ? "Yes": "No"}</td>
                    <td>{item.client_car_returned ? "Yes" : "No"}</td>
                    <td>{item.status}</td>
                    <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                    <td>
                      <a href={`/zm/car_dashboard/rental_details?rental_id=${item.id}`}><i className='fa fa-arrow-right'></i></a>
                    </td>
                    </tr>
                    })}
                </tbody>
            </table>
        </div>
        }
      </div>
      {/* Reviews Section */}
      <div style={{ marginTop: '20px' }}>
        <h2>Ratings & Reviews</h2>

        {/* Display Average Rating */}
        <div style={{ marginBottom: '20px' }}>
          <h3>Average Rating: {car.rating_avg}
          </h3>
          <div>
            {renderStars(car.rating_avg, 50)}
          </div>
        </div>

        {/* List of Reviews */}
        {car.car_product_review.length > 0 ? (
          car.car_product_review.map((review: ICarReview) => (
            <div key={review.id} style={{ marginBottom: '15px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
              <div style={{ marginBottom: '5px' }}>
                <strong className='text-capitalize'>{review.user.first_name} {review.user.last_name}</strong> 
                <span style={{ marginLeft: '10px', fontSize: '0.9rem', color: '#888' }}>
                  {moment(review.created_at).format('MMM Do YYYY')}
                </span>
              </div>
              <div>{renderStars(review.rating, 20)} {review.rating}</div>
              <p>{review.review}</p>
            </div>
          ))
        ) : (
          <p>No reviews available for this car.</p>
        )}
      </div>
    </div>
  );
};

export default CarDetails;
