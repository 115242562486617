import { useRef, useState } from "react";

interface IDrop {
  heading: string;
  onChange: any;
  files: string[];
  setFiles: (file: any) => void;
}
export const DragAndDrop: React.FC<IDrop> = ({
  heading,
  onChange,
  files,
  setFiles,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  // const [files, setFiles] = useState<any>([]);

  // function handleChange(e: any) {
  //   e.preventDefault();
  //   console.log("File has been added");
  //   if (e.target.files && e.target.files[0]) {
  //     console.log(e.target.files);
  //     for (let i = 0; i < e.target.files["length"]; i++) {
  //       setFiles((prevState: any) => [...prevState, e.target.files[i]]);
  //     }
  //   }
  // }

  function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      for (let i = 0; i < e.dataTransfer.files["length"]; i++) {
        setFiles((prevState: any) => [...prevState, e.dataTransfer.files[i]]);
      }
    }
  }

  function handleDragLeave(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }

  function handleDragOver(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function handleDragEnter(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function removeFile(idx: any) {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles([]);
    setFiles(newArr);
  }

  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  return (
    <div className="flex items-center justify-center mt-4">
      <h2 className="text-center">{heading}</h2>
      <div
        className={`${
          dragActive ? "dropActive" : "dropUnActive dropZone"
        }  p-4 w-full rounded-lg  min-h-[10rem] text-center flex flex-col items-center justify-center`}
        onDragEnter={handleDragEnter}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}>
        {/* this input element allows us to select files for upload. We make it hidden so we can activate it when the user clicks select files */}
        <input
          placeholder="fileInput"
          className="hidden d-none"
          ref={inputRef}
          name="images"
          type="file"
          multiple={true}
          onChange={onChange}
          accept="image/*"
        />

        <p>
          Drag & Drop files or{" "}
          <span
            className="font-bold text-blue cursor-pointer btn btn-primary"
            onClick={openFileExplorer}>
            <u>Select files</u>
          </span>{" "}
          to upload
        </p>

        <div className="row">
          {files.map((file: any, idx: any) => (
            <div key={idx} className="mt-3 mb-3 col-sm-4 col-xm-4 col-md-2 col-lg-2">
              <img
                src={URL.createObjectURL(file)}
                className="img-responsive rounded"
                style={{
                  width: "100%",
                  height: "140px",
                }}
                alt="image"
              />
              <span
                className="btn btn-warning cursor-pointer btn"
                onClick={() => removeFile(idx)}>
                <i className="fa fa-trash text-danger"></i>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
