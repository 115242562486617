import React from 'react';
import classNames from 'classnames';
import get from 'lodash.get';
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
  FieldValues,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input, InputProps } from '../atoms/input';
import { FormErrorMessage } from '../atoms/form_error_message';

// Define a custom type for the errors object to handle the `systems` field
type CustomFieldErrors<TFormValues> = Partial<
  DeepMap<TFormValues, FieldError> & {
    systems?: FieldError | FieldError[];
  }
>;

export type FormInputProps<TFormValues extends FieldValues> = {
  label: string;
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: CustomFieldErrors<TFormValues>; // Use the custom type here
} & Omit<InputProps, 'name'>;

export const FormInput = <TFormValues extends Record<string, unknown>>({
  label,
  name,
  register,
  rules,
  errors,
  className,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className={'form-group'} aria-live="polite">
      <label className="label" htmlFor={name}>
        <span className="label-text">{label}</span>
      </label>
      <Input
        label={label}
        name={name}
        aria-invalid={hasError}
        className={classNames({ 'form-control border-danger': hasError })}
        {...props}
        {...(register && register(name, rules))}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
        )}
      />
    </div>
  );
};