import React from "react";
import { toast } from "react-toastify";
import { ICarBrand, ICarRental } from "../../../../redux/types/car_rental.type";
import { useForm } from "react-hook-form";
import EditCarImages from "./edit_car_images";
import { useGetBrandsQuery } from "../../../../redux/slices/car_brands_apislice";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useAdminUpdateCarMutation, useGetAdminCarQuery } from "../../../../redux/slices/admin_hire_slice";
import SearchableDropdown from "../../../widgets/search_dropdown";
import { carTypes } from "../../../widgets/car_types";
import { carColors } from "../../../widgets/car_colors";
import BreadCrumbs from "../../../widgets/bread_crumbs";
type Option = {
  value: number;
  label: string;
};

const EditCar: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const car_id = queryParams.get("car_id");
  const [carBrandOptions, setCarBrandOption] = React.useState<Option[]>([]);
  const [carColorsOptions, setCarColors] = React.useState<Option[]>([]);
  const [selectedCarColor, setSelectCarColor] = React.useState<Option | null>(null);
  const [selectedBrand, setSelectedBrand] = React.useState<Option | null>(null);
  const [options, setOptions] = React.useState<Option[]>([]);
  const [carType, setCarType] = React.useState<Option | null>(null);
  
  React.useEffect(() => {
    if (carBrands?.results) {
      const mappedOptions = carBrands.results.map((item: ICarBrand) => ({
        value: item.id,
        label: `${item.brand_name}`,
      }));
      setCarBrandOption(mappedOptions);
    }
    const carTypeMappedOptions = carTypes.map((item: any) => ({
      value: item,
      label: `${item}`,
    }));
    setOptions(carTypeMappedOptions);

    const carColorsMappedOptions = carColors.map((item: any) => ({
      value: item,
      label: `${item}`,
    }));
    setCarColors(carColorsMappedOptions);
  }, [carColors, carTypes])
  const { data: carBrands, isLoading: fetchingBrands }: ICarBrand | any = useGetBrandsQuery({searchQuery: "", page: ""});
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [adminUpdateCar, { isLoading }] = useAdminUpdateCarMutation();

  const {
    data: car,
    isLoading: isFetching,
    error,
  }: ICarRental | any = useGetAdminCarQuery(Number(car_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger mt-5" role="alert">
        <h4>Error: {error.status} </h4>
      </div>
    );
  }

    const handleCarColorsChange = (selected: Option | null) => {
      setSelectCarColor(selected);
    };
    const handleBrandChange = (selected: Option | null) => {
      setSelectedBrand(selected);
    };
    const handleChange = (selected: Option | null) => {
      setCarType(selected);
    };
  

  const onSubmit = async (data: any) => {
    adminUpdateCar({ id: car_id, ...data })
      .unwrap()
      .then(() => {
        toast.success("car updated Successfully");
      })
      .catch((err) => {
        console.error(err.error);
        toast.error("Failed to add:" + err.error);
      });
  };
  return (
    <div className="content">
      <BreadCrumbs
        title={`${car?.car_model}`}
        subTitle={`View/Manage ${car?.car_model}`}
      />
      <h3 className="mt-3 mb-4 text-center text-capitalize">Edit <span className="text-primary">{car?.car_model}</span></h3>
      <form
        id="paypal_donate_form_onetime_recurring"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Seats <small className="text-danger">*</small>
                  </label>
                  <input
                    id="seats"
                    disabled={isLoading}
                    {...register("seats", { required: true })}
                    className={`form-control ${
                      errors.seats ? "border-danger" : ""
                    }`}
                    name="seats"
                    defaultValue={car?.seats}
                    type="number"
                    placeholder="Number of Seats"
                  />
                  {errors.seats && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Speedometer <small className="text-danger">*</small>
                  </label>
                  <input
                    id="speedometer"
                    disabled={isLoading}
                    {...register("speedometer", { required: true })}
                    className={`form-control ${
                      errors.speedometer ? "border-danger" : ""
                    }`}
                    name="speedometer"
                    defaultValue={car?.speedometer}
                    type="number"
                    placeholder="Speedometer"
                  />
                  {errors.speedometer && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Fuel Tank <small className="text-danger">*</small>
                  </label>
                  <input
                    id="fuel_litres"
                    disabled={isLoading}
                    {...register("fuel_litres", { required: true })}
                    defaultValue={car?.fuel_litres}
                    className={`form-control ${
                      errors.fuel_litres ? "border-danger" : ""
                    }`}
                    name="fuel_litres"
                    type="number"
                    step="0.01"
                    placeholder="eg 5.6"
                  />
                  {errors.fuel_litres && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Music <small className="text-danger">*</small>
                  </label>
                  <input
                    id="music_types"
                    disabled={isLoading}
                    {...register("music_types", { required: true })}
                    defaultValue={car?.music_types}
                    className={`form-control ${
                      errors.music_types ? "border-danger" : ""
                    }`}
                    name="music_types"
                    type="text"
                    placeholder="E.G Radiio/Bluetooth/USB"
                  />
                  {errors.music_types && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Number Plate
                    <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={isLoading}
                    {...register("number_plate", { required: true })}
                    defaultValue={car?.number_plate}
                    className={`form-control ${
                      errors.number_plate ? "border-danger" : ""
                    }`}
                    name="number_plate"
                    type="text"
                    placeholder="Car Number Plate"
                    
                  />
                  {errors.number_plate && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Year
                    <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={isLoading}
                    {...register("year", { required: true })}
                    defaultValue={car?.year}
                    className={`form-control ${
                      errors.year ? "border-danger" : ""
                    }`}
                    name="year"
                    type="text"
                    placeholder="Car Year"
                    
                  />
                  {errors.year && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Model <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={isLoading}
                    {...register("car_model", { required: true })}
                    className={`form-control ${
                      errors.car_model ? "border-danger" : ""
                    }`}
                    name="car_model"
                    defaultValue={car?.car_model}
                    type="text"
                    placeholder="Car Manufacturer e.g BMW, VW, TOYOTA"
                  />
                  {errors.car_model && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                  {car?.label == "RENTAL" ? "Local Car Rental Price" : "Car Deal Price"}  <small className="text-danger">*</small>
                  </label>
                  <input
                    id="form_email"
                    disabled={isLoading}
                    defaultValue={car?.label == "RENTAL" ? car?.local_price: car?.car_deal_price}
                    {...register(`${car?.label === "RENTAL" ? "local_price" : "car_deal_price"}`, { required: true })}
                    className={`form-control ${
                      errors.local_price || errors.car_deal_price ? "border-danger" : ""
                    }`}
                    name={`${car?.label === "RENTAL" ? "local_price" : "car_deal_price"}`}
                    type="number"
                    step="0.01"
                    placeholder={`${car?.label === "RENTAL" ? "Local Car Rental Price Per DAY":"Car Deal Price"}`}
                  />
                  {errors.local_price && (
                    <p className="text-danger">This field is required.</p>
                  )}
                  {errors.car_deal_price && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                  {car?.label == "RENTAL" ? "OutSide Local Car Rental Price" : "Viewing Fee"} <small className="text-danger">*</small>
                  </label>
                  <input
                    id="form_email"
                    disabled={isLoading}
                    defaultValue={car?.label == "RENTAL" ? car?.outside_town: car?.car_viewing_fee}
                    {...register(`${car?.label === "RENTAL" ? "outside_town" : "car_viewing_fee"}`, { required: true })}
                    className={`form-control ${
                      errors.outside_town || car?.car_viewing_fee ? "border-danger" : ""
                    }`}
                    name={`${car?.label === "RENTAL" ? "outside_town" : "car_viewing_fee"}`}
                    type="number"
                    step="0.01"
                    placeholder={`${car?.label === "RENTAL" ? "Outside Local Car Rental Price Per DAY":"Viewing Fee"}`}
                  />
                  {errors.outside_town && (
                    <p className="text-danger">This field is required.</p>
                  )}
                  {errors?.car_viewing_fee && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Transmission
                    <small className="text-danger">*</small>
                  </label>
                  <select
                    disabled={isLoading}
                    {...register("gear_type", { required: true })}
                    className={`form-control ${
                      errors.gear_type ? "border-danger" : ""
                    }`}
                    defaultValue={car?.gear_type}
                    name="gear_type">
                    <option value="" selected>
                      Choose Transmission Type
                    </option>
                    <option value="automatic">Automatic Transmission</option>
                    <option value="manual">Manual Transmission</option>
                  </select>
                  {errors.gear_type && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="">
                  <label htmlFor="car_brand" className="mb-2">
                    Car Brand <small className="text-danger">*</small>
                  </label>
                  {carBrandOptions.length > 0 &&
                  <SearchableDropdown 
                  key={carBrandOptions.length}
                    defaultValue={car?.car_brand} 
                    options={carBrandOptions} 
                    onChange={handleBrandChange} />
                  }
                  {errors.car_brand && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="">
                <label htmlFor="car_type" className="mb-2">
                    Car Type <small className="text-danger">*</small>
                  </label>
                  {options.length > 0 && 
                  <SearchableDropdown 
                    key={options.length}
                    defaultValue={car?.car_type} 
                    options={options} 
                    onChange={handleChange} />
                  }
                </div>
              </div>
              <div className="col-md-4">
                <div className="">
                <label htmlFor="car_colors" className="mb-2">
                    Car Color <small className="text-danger">*</small>
                  </label>
                  {carColorsOptions.length > 0 &&
                  <SearchableDropdown 
                    key={carColorsOptions.length}
                    defaultValue={car?.color} 
                    options={carColorsOptions} 
                    onChange={handleCarColorsChange} />
                  }
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Description <small className="text-danger">*</small>
                  </label>
                  <textarea
                    rows={5}
                    cols={8}
                    id="description"
                    defaultValue={car?.description}
                    disabled={isLoading}
                    {...register("description", { required: true })}
                    className={`form-control ${
                      errors.description ? "border-danger" : ""
                    }`}
                    minLength={200}
                    name="description"
                    placeholder="Car Description"></textarea>
                  {errors.description && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-2 col-lg-4 shadow-xl image-full">
              <h4><b>White Book</b></h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${car?.white_book}`}
                style={{
                  width: "100%",
                  height: "200px",
                }}
                className="img-responsive rounded"
                alt="car rental"
              />
            </div>
            <div className="col-md-2 col-lg-4 shadow-xl image-full">
              <h4><b>Car Fitness</b></h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${car?.fitness}`}
                style={{
                  width: "100%",
                  height: "200px",
                }}
                className="img-responsive rounded"
                alt="car rental"
              />
            </div>
            <div className="col-md-2 col-lg-4 shadow-xl image-full">
              <h4><b>Car Insurance</b></h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${car?.insurance}`}
                style={{
                  width: "100%",
                  height: "200px",
                }}
                className="img-responsive rounded"
                alt="car rental"
              />
            </div>
              <div className="pull-right mb-4">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isLoading}>
                  {isLoading && <i className="fa fa-refresh fa-spin" />} Update
                  Car Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <EditCarImages carid={car_id} images={car?.car_images} />
    </div>
  );
};

export default EditCar;
