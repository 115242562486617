import React, { useRef } from 'react'
import { ICarRental } from '../../../../redux/types/car_rental.type';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { useGetAdminCarQuery } from '../../../../redux/slices/admin_hire_slice';
import moment from 'moment';
import { ICarBooking } from '../../../../redux/types/car_booking.type';
import BreadCrumbs from '../../../widgets/bread_crumbs';
import Filters from '../../widgets/filters';
import { ErrorWidget } from '../../../utils/error_widget';

const AdminCarTransaction: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
    const queryParams = new URLSearchParams(window.location.search);
    const car_id = queryParams.get("car_id");
    const [query, setQuery] = React.useState("")
    const [currentPage, setCurrentPage] = React.useState(1);

    const [fromDate, setFromDate] = React.useState("");
      const [toDate, setToDate] = React.useState("");
    
      // Handler for date filter change
      const onDateFilter = (fromDate: string, toDate: string) => {
        setFromDate(fromDate);
        setToDate(toDate);
        // Call your API or perform filtering here
        console.log("Filtering from:", fromDate, "to:", toDate);
      };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setQuery(newValue);
    }
    const {
        data: car,
        isLoading: isFetching,
        error,
      }: ICarRental | any = useGetAdminCarQuery(Number(car_id));
    
      if (isFetching) {
        return (
          <div style={{ marginTop: "20%" }} className="text-center">
            <LoadingSpinner />
          </div>
        );
      }
      if (error) {
        return (
          <ErrorWidget error={error}/>
        );
      }
    
  return (
    <div className="content">
        <BreadCrumbs
        title={"Transactions"}
        subTitle={"View/Manage Transactions"}
      />
        <div className="card">
          <div className="card-body">
          <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
          <div className='mb-2'>
            <h3 className="mt-3 mb-2 text-center text-capitalize">
              <span className="text-primary">{car?.car_model}</span>
            </h3>
            <div className="text-center text-capitalize">
            <h4>{car?.user.company_name}</h4>
            </div>
            <h1>Transactions ({car?.car_user_rental.length}) </h1>
        </div>
        {car?.car_user_rental.length === 0 ? <div className="alert alert-info text-center">
            <h3>No Transactions Found</h3>
        </div>:
        <div className="table-responsive dataview">
            <table className="table datatable">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Client</th>
                    <th>Amount</th>
                    <th>Days</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Picked Up</th>
                    <th>Returned</th>
                    <th>Status</th>
                    <th>Posted Date</th>
                    {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {car?.car_user_rental.map((item: ICarBooking, index: number)=>{
                    return <tr>
                    <td>{index + 1}</td>
                    <td className="productimgname">
                        <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}>{item.client.first_name}{item.client.last_name}</a>
                    </td>
                    <td>{Number(item.rental_price).toLocaleString()}</td>
                    <td>{item.days}</td>
                    <td>{moment(item.client_pickup_date_time).format("Do MMM YYYY HH:mm a")}</td>
                    <td>{moment(item.client_return_date_time).format("Do MMM YYYY HH:mm a")}</td>
                    <td>{item.client_car_picked_up ? "Yes": "No"}</td>
                    <td>{item.client_car_returned ? "Yes" : "No"}</td>
                    <td>{item.status}</td>
                    <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                    {/* <td>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}><i className='fa fa-arrow-right'></i></a>
                    </td> */}
                    </tr>
                    })}
                </tbody>
            </table>
        </div>
        }
          </div>
        </div>
    </div>
  )
}

export default AdminCarTransaction