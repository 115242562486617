export const carColors = [
    'Black',
    'White',
    'Gray',
    'Silver',
    'Blue',
    'Red',
    'Green',
    'Yellow',
    'Orange',
    'Brown',
    'Beige',
    'Gold',
    'Copper',
    'Bronze',
    'Pink',
    'Purple',
    'Maroon',
    'Turquoise',
    'Teal',
    'Lavender',
    'Magenta',
    'Burgundy',
    'Ivory',
    'Cream',
    'Charcoal',
    'Navy Blue',
    'Sky Blue',
    'Lime Green',
    'Forest Green',
    'Midnight Blue',
    'Pearl',
    'Champagne',
    'Matte Black',
    'Matte Gray',
    'Electric Blue',
    'Neon Green',
    'Rose Gold',
    'Dark Red',
    'Pastel Pink',
    'Pastel Yellow',
    'Pastel Blue',
    'Pastel Green',
    'Cyan',
    'Olive',
    'Rust',
    'Slate Gray',
    'Seafoam Green',
    'Sand',
    'Gunmetal',
    'Anthracite',
    'Fuchsia',
    'Mint Green',
    'Coral',
    'Amber',
    'Titanium',
    'Steel Blue'
  ];
  