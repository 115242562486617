import moment from 'moment';
import React from 'react'
import { IMessage, IUser } from './data/user_message.interface';


interface MessageBoxProps {
  messages: IMessage[];
  selectedUser: IUser;
}

const MessageBox: React.FC<MessageBoxProps> = ({ messages, selectedUser }) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [messages]);

  return (
    <div className="col-lg-7 col-xl-8 chat-cont-right">
      <div className="card mb-0">
        <div className="card-header msg_head">
          <div className="d-flex bd-highlight">
            <a
              id="back_user_list"
              href="javascript:void(0)"
              className="back-user-list"
            >
              <i className="fas fa-chevron-left"></i>
            </a>
            <div className="img_cont">
              <img
                className="rounded-circle user_img"
                src={selectedUser.image}
                alt={selectedUser.name}
              />
            </div>
            <div className="user_info">
              <span>
                <strong id="receiver_name">{selectedUser.name}</strong>
              </span>
              <p className="mb-0">Messages</p>
            </div>
          </div>
        </div>
        <div className="card-body msg_card_body chat-scroll">
          <ul className="list-unstyled">
            {messages.map((message) => (
              <li
                key={message.id}
                className={`media ${message.senderId === 'currentUser' ? 'sent' : 'received'} d-flex`}
              >
                <div className="avatar flex-shrink-0">
                  <img
                    src={
                      message.senderId === 'currentUser'
                        ? require('../../../../assets/img/customer/profile2.jpg') // Current user's avatar
                        : selectedUser.image // Selected user's avatar
                    }
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="media-body flex-grow-1">
                  <div className="msg-box">
                    <div>
                      <p>{message.text}</p>
                      {message.image && (
                        <div className="chat-msg-attachments">
                          <div className="chat-attachment">
                            <img
                              src={message.image}
                              alt="Attachment"
                              className="img-fluid"
                            />
                            <a href="" className="chat-attach-download">
                              <i className="fas fa-download"></i>
                            </a>
                          </div>
                        </div>
                      )}
                      <ul className="chat-msg-info">
                        <li>
                          <div className="chat-time">
                            <span>{moment(message.timestamp).format('h:mm A')}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="card-footer">
          <div className="input-group">
            <input
              className="form-control type_msg mh-auto empty_check"
              placeholder="Type your message..."
            />
            <button className="btn btn-primary btn_send">
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
