export const carTypes = [
    "Sports",
    "Economy",
    "Luxury",
    "Sedan",
    "SUV",
    "Hatchback",
    "Coupe",
    "Convertible",
    "Wagon",
    "Minivan",
    "Van",
    "Truck",
    "Bus",
    "Other",
  ];