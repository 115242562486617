import React from "react";
import editIcon from "../../../../../assets/img/icons/edit.svg";
import pdf from "../../../../../assets/img/icons/pdf.svg";
import excel from "../../../../../assets/img/icons/excel.svg";
import printer from "../../../../../assets/img/icons/printer.svg";
import LoadingSpinner from "../../../../../components/widgets/spinner";
import { useNavigate } from "react-router-dom";
import { useGetInvestmentQuery } from "../../../../../redux/slices/investment_slice";
import { TInvestment } from "../../../../../redux/types/investment.type";
import { TInvestmentDonation } from "../../../../../redux/types/investment_donations.type";
import moment from "moment";
import InvestmentStats from "../../../../admin/pages/investment/investment_stats";
import EditProject from "../edit_project";
import { formatPrice } from "../../../../utils/format_price";

const ProjectDetails: React.FC = () => {
  const navigate = useNavigate();
  const [project, setProject] = React.useState<TInvestment | any>();
  const [show, setShow] = React.useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (project: TInvestment) => {
    setProject(project);
    setShow(true);
  };
  const queryParams = new URLSearchParams(window.location.search);
  const project_id = queryParams.get("project_id");
  const {
    data: investment,
    isLoading: isFetching,
    error,
  }: TInvestment | any = useGetInvestmentQuery(Number(project_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };

  return (
    <div className="content">
      <div className="page-header">
        <div>
          <button
            onClick={() => navigate(-1)}
            className="btn btn-sm btn-secondary text-white mb-3"
          >
            <i className="fa fa-arrow-left"></i>
          </button>
          <button
            onClick={() => handleShow(investment)}
            className="btn btn-added"
          >
            <img src={editIcon} alt="img" />
             Edit Project
          </button>
        </div>
        <div className="page-title">
          <h4>Project Details</h4>
          <h6>Manage Project</h6>
        </div>
      </div>
      {error ? (
        <div className="text-center alert alert-danger mt-5" role="alert">
          <h4>Error: {error.status} </h4>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-3">
              <img
                className="img-responsive rounded"
                style={{ width: "100%", height: "150px" }}
                src={`${process.env.REACT_APP_API_URL}/${investment.thumbnail}`}
                alt={investment.project_title}
              />
              <h5 className="text-capitalize">
                <b>{investment.project_title}</b>
              </h5>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
                  <h3>Organizer</h3>
                  <h5 className="text-capitalize">
                    <b>{investment.project_organizer}</b>
                  </h5>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
                  <h3>Beneficiary</h3>
                  <h5 className="text-capitalize">
                    <b>{investment.project_beneficiary}</b>
                  </h5>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
                  <h3>Category</h3>
                  <h5 className="text-uppercase">
                    <b>{investment.project_category}</b>
                  </h5>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
                  <h3>Raising Go</h3>
                  <h5 className="text-uppercase">
                    <b>
                      {formatPrice({currency: investment?.user.company_currency.currency.currency_code, price: investment.project_amount})}
                    </b>
                  </h5>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
                  <h3>Raised</h3>
                  <h5 className="text-uppercase">
                    <b>
                      {formatPrice({currency: investment?.user.company_currency.currency.currency_code, price: investment.total_donated_avg})}
                    </b>
                  </h5>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12 mb-3">
                  <h3>Country</h3>
                  <h5 className="text-capitalize">
                    <b>{investment.country}</b>
                  </h5>
                </div>
                <div className="text-center">
                  {moment().format("YYYY-MM-D") === investment.project_days ||
                  moment().format("YYYY-MM-D") > investment.project_days ? (
                    <h2 className="text-warning">
                      <b>
                        <i className="fa fa-heart"></i> Closed
                      </b>
                    </h2>
                  ) : (
                    <h2 className="text-capitalize text-dark">
                      <b>
                        {findDayDifference(
                          new Date(moment().format(investment.project_days)),
                          new Date(moment().format("YYYY-MM-D"))
                        )}{" "}
                        day(s) remaining
                      </b>
                    </h2>
                  )}
                  <div style={{ height: "180px" }}>
                    <InvestmentStats />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="project-details">
            <h5 className="mt-3">
              <b>Project Description</b>
            </h5>
            <blockquote>
              <p>{investment.project_description}</p>
            </blockquote>
          </div>
          <div className="table-responsive mt-4">
            {investment.donations.length === 0 ? (
              <center className="p-3">
                <i className="fa fa-calendar-day fa-5x"></i>
                <h4>
                  <b>No Data Found</b>
                </h4>
              </center>
            ) : (
              <>
                
                <div className="table-top">
        <div className="search-set">
          <div className="search-path">
          <h2>
                  <b>Project Donations</b>
                </h2>
          </div>
        </div>
        <div className="wordset">
          <ul>
            <li>
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf">
                <img src={pdf} alt="img" />
              </a>
            </li>
            <li>
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel">
                <img src={excel} alt="img" />
              </a>
            </li>
            <li>
              <a data-bs-toggle="tooltip" data-bs-placement="top" title="print">
                <img src={printer} alt="img" />
              </a>
            </li>
          </ul>
        </div>
      </div>
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sponsor</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {investment.donations.map((item: TInvestmentDonation, index: number) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td className="text-capitalize">
                              {item.first_name} {item.last_name}
                            </td>
                            <td>
                              {formatPrice({currency: item.project.user.company_currency.currency.currency_code, price: item.donated_amount})}
                            </td>
                            <td>
                              {moment(item.created_at).format("Do MMMM, YYYY")}
                            </td>
                            <td>
                              <div className="status-toggle d-flex justify-content-between align-items-center">
                                <input
                                  type="checkbox"
                                  id="user1"
                                  className="check"
                                  checked={true}
                                />
                                <label htmlFor="user1" className="checktoggle">
                                  checkbox
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <EditProject
            project={project}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
          />
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
