import React from "react";
import { User } from "../../../../../redux/types/user";
import { useGetUserQuery } from "../../../../../redux/slices/usersApiSlice";
import LoadingSpinner from "../../../../../components/widgets/spinner";
import DataService from "../../../../services/http.product.services";
import BreadCrumbs from "../../../../widgets/bread_crumbs";
import { dailyData, monthlyData } from "../../../../utils/data_charts";
import BranchCharts from "../../../reports/branch_charts";
import moment from "moment";
import { PropertyModel } from "../../../../../models/product_model";
import { formatPrice } from "../../../../utils/format_price";

const RealEstateCompanyDetails: React.FC = () => {
  const [properties, setProperties] = React.useState<PropertyModel[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorz, setError] = React.useState(null);
  const [selected, setSelected] = React.useState<string>("all");
  const queryParams = new URLSearchParams(window.location.search);
  const company_id = queryParams.get("company_id");

  const fetchProperties = async () => {
    setIsLoading(true);
    DataService.getProperties(company_id)
      .then((response) => {
        setProperties(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    fetchProperties();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const filter = (value: string) => {
    setSelected(value);
  };

  const {
    data: userDetails,
    isLoading: isFetching,
    error,
  }: User | any = useGetUserQuery(Number(company_id));

  if (isFetching || isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  const findDayDifference = (date1: any, date2: any) => {
    // always round down
    return Math.floor(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
  };
  return (
    <div className="content">
      {error ? (
        <center>
          <div className="alert alert-info text-white">
            Error Loading Application
          </div>
        </center>
      ) : (
        <>
          <BreadCrumbs
            title={"Company Details"}
            subTitle={`View ${userDetails?.company_name}`}
          />
          <div className="row">
            <div className="col-md-5">
              <h3>Daily Sales</h3>
              <BranchCharts width={1000} height={250} data={dailyData} />
            </div>
            <div className="col-md-7">
              <h3>Monthly Sales</h3>
              <BranchCharts width={1000} height={250} data={monthlyData} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-5 mb-md-0">
              <h4 className="m-3 pr-2 text-capitalize">
                <b>Projects For {userDetails?.company_name}</b>
              </h4>
              {properties.length === 0 ? (
                <center>
                  <div
                    className="text-center alert alert-info mt-3"
                    role="alert"
                    style={{ width: "fit-content" }}
                  >
                    <h1 className="mt-3">NO PROJECTS FOUND</h1>
                  </div>
                </center>
              ) : (
                <div className="card-body table-responsive">
                  <table className="table table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Property</th>

                        <th scope="col" className="m2_hide">
                          Stats
                        </th>
                        <th scope="col" className="m2_hide">
                          Posted On
                        </th>
                        <th scope="col">Listing Status</th>
                        <th scope="col">Property Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <!-- tr block --> */}
                      {properties.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="dash_prt_wrap">
                                <div className="dash_prt_thumb">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}${item.image}`}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="dash_prt_caption">
                                  <h5 className="capitalize">
                                    {item.propertyName}
                                  </h5>
                                  <div className="prt_dashb_lot capitalize">
                                    {item.propertyAddress}
                                  </div>
                                  <div className="prt_dash_rate">
                                    <span>
                                      {`${formatPrice({currency: item.user.company_currency.currency.currency_code, price: item.propertyPrice})}${item.propertyLabel === "rent" ? "/Month" : ""}`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="m2_hide">
                              <div className="_leads_view">
                                <h5 className="up">
                                  {Number(
                                    item.propertyReviews
                                  ).toLocaleString()}
                                </h5>
                              </div>
                              <div className="_leads_view_title">
                                <span>Total Views</span>
                              </div>
                            </td>
                            <td className="m2_hide">
                              <div className="_leads_posted">
                                <h5>
                                  {moment(item.created_at).format(
                                    "Do MMM YYYY - HH:MM a"
                                  )}
                                </h5>
                              </div>
                              <div className="_leads_view_title">
                                <span>{moment(item.created_at).fromNow()}</span>
                              </div>
                            </td>

                            <td>
                              <div className="_leads_status text-capitalize">
                                <span
                                  className={`text-capitalize ${
                                    item.status === "PENDING"
                                      ? "expire"
                                      : "active"
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </div>
                            </td>
                            <td className="text-capitalize">
                              {item.rentedOrSold &&
                              item.propertyLabel === "rent" ? (
                                <span>
                                  <i className="fa fa-home"></i> Property Rented
                                </span>
                              ) : item.rentedOrSold &&
                                item.propertyLabel === "sale" ? (
                                <span>
                                  <i className="fa fa-home"></i> Property Sold
                                </span>
                              ) : (
                                `for ${item.propertyLabel}`
                              )}
                            </td>
                            
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RealEstateCompanyDetails;
