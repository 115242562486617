import React from "react";
import bell from "../../../assets/img/icons/notification-bing.svg";
import moment from "moment";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useGetConstructionOrdersListQuery } from "../../../redux/slices/construction_orders_slice";
import { TConstructionMiningOrders } from "../../../redux/types/construction.type";

const ConstructionNotificationWidget: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const {
    data: ordersList,
    isLoading,
    error,
  }: TConstructionMiningOrders | any = useGetConstructionOrdersListQuery({
    supplier: user?.id, 
    status: "PENDING", 
    searchQuery: "",
    page: 1,
    fromDate: '',
    toDate:   '',
  });
  return (
    <li className="nav-item dropdown">
      <a
        href="javascript:void(0);"
        className="dropdown-toggle nav-link"
        data-bs-toggle="dropdown"
      >
        <img src={bell} alt="img" />
        <span className="badge rounded-pill bg-success">{isLoading ? "0" : error ? "0":  ordersList.results.length}</span>
      </a>
      <div className="dropdown-menu notifications">
        <div className="topnav-dropdown-header">
          <span className="notification-title">{user?.user_type} Notifications</span>
          <a href="javascript:void(0)" className="clear-noti">
            {" "}
            Clear All{" "}
          </a>
        </div>
        <div className="noti-content">
          <ul className="notification-list">
            
            {isLoading ? <center>
              <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
            </center>: error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : ordersList.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h5 className="text-center">No Notifications</h5>
              </div>
            ) : ordersList.results.map((item: TConstructionMiningOrders, index: number)=>{
              return <li key={index} className="notification-message">
              <a href={`/zm/dashboard/${user?.user_type}/order_details/?order_id=${item.id}`}>
                <div className="media d-flex">
                  <span className="avatar flex-shrink-0">
                    <img
                      src={`${item.product.thumbnail}`}
                      alt={item.product.productName}
                    />
                  </span>
                  <div className="media-body flex-grow-1">
                    <p className="noti-details">
                      New <code className="text-capitalize">{item.product.productName.substring(0, 10)}...</code> Order From: <span className="noti-title text-capitalize">{item.customer.first_name} {item.customer.last_name}</span>
                    </p>
                    <p className="noti-time">
                      <span className="notification-time">
                        {moment(item.created_at).fromNow()}
                      </span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            })}
            
          </ul>
        </div>
        <div className="topnav-dropdown-footer">
          <a href="#">View all Notifications</a>
        </div>
      </div>
    </li>
  );
};


export default ConstructionNotificationWidget;
