import React from "react";
import { RequestsModel } from "../../../models/requests_model";
import { useGetRequestQuery, useUpdateRequestMutation } from "../../../redux/slices/property/property_requests_slice";
import RequestActionModal from "./action_modal";
import moment from "moment";
import BreadCrumbs from "../../widgets/bread_crumbs";
import { formatPrice } from "../../utils/format_price";

const RequestDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const requestID = queryParams.get("request");
  const {
    data: request,
    isLoading,
    isError,
    error,
  }: RequestsModel | any = useGetRequestQuery(Number(requestID));

  const [updateRequest, { isLoading: updating }] = useUpdateRequestMutation();



  React.useEffect(() => {
    if(!isLoading && request?.request_seen === "unseen"){
        updateRequest({id: Number(requestID), request_seen: "seen", status: request.status})
        .unwrap()
        .then(() => {
        })
        .catch((error) => {
        });
    }
  },[])

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  if (isError) {
    console.log(error);
    return (
      <div style={{ marginTop: "20%" }} className="alert alert-danger" role="alert">
        {error.message}
      </div>
    );
  }
  return (
    <div className="content">
      <BreadCrumbs
        title={"Property Request Details"}
        subTitle={"Manage Property Request Details"}
      />
      <div className="container">
        <h1 className="text-center mt-5 mb-3">Property Request Details</h1>
        <div className="row">
          <div className="col-md-4">
            <img
              className="img-responsive rounded"
              src={`${process.env.REACT_APP_API_URL}/${request.property.image}`}
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="client">
              <h3>Customer Details</h3>
              <h6 className="capitalize">
                Name: {request.user.first_name} {request.user.last_name}{" "}
              </h6>
              <h6>E-mail: {request.user.email} </h6>
              <h6>Phone Number: {request.phoneNumber}</h6>
            </div>
          </div>
          <div className="col-md-6">
            <h4>{request.notificationTitle}</h4>
            <blockquote>
                {request.notification}
            </blockquote>
          </div>
        </div>
        <h3 className="mt-5 mb-4">Property Details</h3>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <th>Property</th>
                    <th>Amount</th>
                    <th>Label</th>
                    <th>Payment Day</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{request.property.propertyName}</td>
                        <td>{formatPrice({currency: request.user.company_currency.currency.currency_code, price: request.property.propertyPrice})}</td>
                        <td className="capitalize">For {request.property.propertyLabel}</td>
                        <td>{moment(request.montlyPaymentDate).format("YYYY MMM Do")}</td>
                        <td>{request.property.rentedOrSold === true && request.property.propertyLabel === "rent" ? "Property Rented" : request.property.rentedOrSold === true && request.property.propertyLabel === "sale" ? "Property Sold" : "Property Available"}</td>
                        <td><RequestActionModal request={request} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default RequestDetails;
