import React from "react";
import { Modal, Button, Form as BootstrapForm, Row, Col, InputGroup } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetAdminCarsQuery } from "../../../../redux/slices/admin_hire_slice";
import { ICarRental } from "../../../../redux/types/car_rental.type";
import { useAppSelector } from "../../../../hooks/redux-hooks";

interface OfflineRentalsFormProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  initialValues?: any;
  company_id: any;
  isLoading: boolean;
}

const OfflineRentalsForm: React.FC<OfflineRentalsFormProps> = ({ visible, onClose, onSubmit, initialValues, company_id, isLoading }) => {
  const user = useAppSelector((state) => state.auth.user);
  const [carPrices, setCarPrices] = React.useState<{[key: string]: { local_price: number; outside_town: number };}>({});
  const [rentalPrice, setRentalPrice] = React.useState<number>(0);
  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues || {
      client: "",
      company: company_id,
      car: "",
      days: 0,
      rental_price: 0,
      phone_number: "",
      driveType: "local",
      hiring_reason: "",
      rental_type: "OFFLINE",
      client_first_name: "",
      client_last_name: "",
      client_email: "",
      client_pickup_location: "",
      client_return_location: "",
      client_pickup_date_time: null,
      client_return_date_time: null,
      status: "PENDING",
      client_car_picked_up: false,
      client_car_returned: false,
    },
  });
  const {
    data: carList,
    isLoading: fetchingCars,
    error,
  }: ICarRental | any = useGetAdminCarsQuery({
    company: company_id,
    status: "",
    searchQuery: "", 
    page: 1,
    label: "RENTAL"
  });

  const watchCar = watch("car");
  const watchPickUpDate = watch("client_pickup_date_time");
  const watchReturnUpDate = watch("client_return_date_time");
  const watchDriveType = watch("driveType");

  React.useEffect(() => {
    if (carList?.results) {
      const prices = carList.results.reduce((acc: any, car: ICarRental) => {
        acc[car.id] = {
          local_price: car.local_price,
          outside_town: car.outside_town,
        };
        return acc;
      }, {});
      setCarPrices(prices);
    }
  }, [carList]);

  React.useEffect(() => {
    if (watchCar && carPrices[watchCar]) {
      const selectedPrice = watchDriveType === "local"
          ? carPrices[watchCar].local_price
          : carPrices[watchCar].outside_town;

      setRentalPrice(selectedPrice);
      setValue("rental_price", selectedPrice);
    }
  }, [watchCar, watchDriveType, carPrices, setValue]);

  React.useEffect(() => {
    if (watchPickUpDate && watchReturnUpDate && watchCar) {
      const pickUpDate = new Date(watchPickUpDate);
      const returnUpDate = new Date(watchReturnUpDate);
      const timeDiff = Math.abs(returnUpDate.getTime() - pickUpDate.getTime());
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (!isNaN(days)) {
        setValue("days", days);
        setValue("rental_price", days * rentalPrice);
      }
    }
  }, [watchPickUpDate, watchReturnUpDate, rentalPrice, watchCar, setValue]);

  const onSubmitForm = (data: any) => {
    console.log("offline data: ", data);
    onSubmit(data);
    // reset();
  };

  return (
    <Modal
      size="xl"
      scrollable
      show={visible}
      onHide={onClose}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{initialValues ? "Edit Offline Rental" : "Add Offline Rental"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BootstrapForm onSubmit={handleSubmit(onSubmitForm)}>
          <Row>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formCar">
                <BootstrapForm.Label>Car</BootstrapForm.Label>
                {fetchingCars ? "fetching cars" : error ? "Error fetching cars" : <BootstrapForm.Select
                  {...register("car", { required: "Car ID is required" })}
                  // defaultValue="local"
                  disabled={isLoading}
                >
                  <option value="">Select Car</option>
                  {carList.results.map((item: ICarRental, index: number) =>{
                    return <option key={index} value={item.id}>{item.car_model}</option>
                  })}
                </BootstrapForm.Select>}
                
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.car?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
                
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formDays">
                <BootstrapForm.Label>Days</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="number"
                  placeholder="Number of days"
                  readOnly={true}
                  {...register("days", { required: "Number of days is required" })}
                  isInvalid={!!errors.days}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.days?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>

            
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formRentalPrice">
                <BootstrapForm.Label>Rental Price</BootstrapForm.Label>
                <InputGroup>
                  <InputGroup.Text>{user?.company_currency.currency.currency_code}</InputGroup.Text> {/* Currency sign */}
                  <BootstrapForm.Control
                    type="number"
                    step="0.01"
                    placeholder="Rental Price"
                    {...register("rental_price", { required: "Rental price is required" })}
                    isInvalid={!!errors.rental_price}
                    disabled={isLoading}
                  />
                  <BootstrapForm.Control.Feedback type="invalid">
                    {errors.rental_price?.message && (
                      <p className="text-danger">This field is required.</p>
                    )}
                  </BootstrapForm.Control.Feedback>
                </InputGroup>
              </BootstrapForm.Group>
              </Col>

            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formFirstName">
                <BootstrapForm.Label>First Name</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="First Name"
                  {...register("client_first_name", { required: "Client first name is required" })}
                  isInvalid={!!errors.client_first_name}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.client_first_name?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formLastName">
                <BootstrapForm.Label>Last Name</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Last Name"
                  {...register("client_last_name", { required: "Client last name is required" })}
                  isInvalid={!!errors.client_last_name}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.client_last_name?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formEmailName">
                <BootstrapForm.Label>Email Name</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="email"
                  placeholder="Email Name"
                  {...register("client_email", { required: "Client first name is required" })}
                  isInvalid={!!errors.client_email}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.client_email?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formPhoneNumber">
                <BootstrapForm.Label>Phone Number</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Phone Number"
                  {...register("phone_number", { required: "Phone number is required" })}
                  isInvalid={!!errors.phone_number}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.phone_number?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formPickup">
                <BootstrapForm.Label>Car Pickup Location</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Car Pickup Location"
                  {...register("client_pickup_location", { required: "Car Pickup Location is required" })}
                  isInvalid={!!errors.client_pickup_location}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.client_pickup_location?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formReturn">
                <BootstrapForm.Label>Car Return Location</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Car Return Location"
                  {...register("client_return_location", { required: "Car Return Location is required" })}
                  isInvalid={!!errors.client_return_location}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.client_return_location?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formReturn">
                <BootstrapForm.Label>Client address</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Client address"
                  {...register("client_address", { required: "Client address is required" })}
                  isInvalid={!!errors.client_address}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.client_address?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formPickUpDate">
                <BootstrapForm.Label>Pick-Up Date</BootstrapForm.Label>
                <Controller
                  name="client_pickup_date_time"
                  control={control}
                  rules={{ required: "Pick-up date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={field.onChange}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Pick up date"
                      className={`form-control ${errors.client_pickup_date_time ? "is-invalid" : ""}`}
                      disabled={isLoading}
                    />
                  )}
                />
                {errors.client_pickup_date_time?.message && (
                  <p className="text-danger">This field is required.</p>
                )}
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group controlId="formReturnUpDate">
                <BootstrapForm.Label>Return-Up Date</BootstrapForm.Label>
                <Controller
                  name="client_return_date_time"
                  control={control}
                  rules={{ required: "Return-up date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={field.onChange}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Return up date"
                      className={`form-control ${errors.client_return_date_time ? "is-invalid" : ""}`}
                      disabled={isLoading}
                    />
                  )}
                />
                {errors.client_return_date_time?.message && (
                  <p className="text-danger">This field is required.</p>
                )}
              </BootstrapForm.Group>
            </Col>
            <Col md={6} className="mb-3">
              <BootstrapForm.Group controlId="formStatus">
                <BootstrapForm.Label>Status</BootstrapForm.Label>
                <BootstrapForm.Select
                  {...register("status")}
                  defaultValue="PENDING"
                  disabled={isLoading}
                >
                  <option value="PENDING">Pending</option>
                  <option value="CONFIRMED">Confirmed</option>
                  <option value="CANCELLED">Cancelled</option>
                </BootstrapForm.Select>
              </BootstrapForm.Group>
            </Col>
            <Col md={6} className="mb-3">
              <BootstrapForm.Group controlId="formDriveType">
                <BootstrapForm.Label>Drive Type</BootstrapForm.Label>
                <BootstrapForm.Select
                  {...register("driveType")}
                  defaultValue="local"
                  disabled={isLoading}
                >
                  <option value="local">Local</option>
                  <option value="outstation">Outstation</option>
                </BootstrapForm.Select>
              </BootstrapForm.Group>
            </Col>

            <Col md={12} className="mb-3">
              <BootstrapForm.Group controlId="formHiringReason">
                <BootstrapForm.Label>Hiring Reason</BootstrapForm.Label>
                <BootstrapForm.Control
                  as="textarea"
                  rows={3}
                  placeholder="Reason for hiring"
                  {...register("hiring_reason", { required: "Reason for hiring is required" })}
                  isInvalid={!!errors.hiring_reason}
                  disabled={isLoading}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.hiring_reason?.message && (
                    <p className="text-danger">This field is required.</p>
                )}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Col>
            

            <Col md={6} className="mb-3">
              <BootstrapForm.Group controlId="formClientCarPickedUp">
                <BootstrapForm.Check
                  type="checkbox"
                  label="Client Car Picked Up"
                  {...register("client_car_picked_up")}
                  disabled={isLoading}
                />
              </BootstrapForm.Group>
            </Col>
            <Col md={6} className="mb-3">
              <BootstrapForm.Group controlId="formClientCarReturned">
                <BootstrapForm.Check
                  type="checkbox"
                  label="Client Car Returned"
                  {...register("client_car_returned")}
                  disabled={isLoading}
                />
              </BootstrapForm.Group>
            </Col>
          </Row>

          <div className="text-end">
            <Button variant="secondary" onClick={onClose} className="me-2" disabled={isLoading}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </BootstrapForm>
      </Modal.Body>
    </Modal>
  );
};

export default OfflineRentalsForm;
