import { apiSlice } from "../services/apiSlice";
import { TFuelOrderModel } from "../types/fuel_mode.type";

export const fuelOrderSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFuelOrder: builder.mutation<TFuelOrderModel, Partial<TFuelOrderModel>>({
      query(data) {
        return {
          url: `/administrator/v1/everyday/admin_fuel_orders/tech`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["TFuelOrderModel"],
      transformResponse: (result: { agri: TFuelOrderModel }) =>
        result.agri,
    }),
    updateFuelOrder: builder.mutation<TFuelOrderModel, Partial<TFuelOrderModel>& Pick<TFuelOrderModel, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/administrator/v1/everyday/admin_fuel_orders/tech/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["TFuelOrderModel"],
      transformResponse: (response: { agri: TFuelOrderModel }) =>
        response.agri,
    }),
    getFuelOrder: builder.query<TFuelOrderModel, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_fuel_orders/tech/${id}/`,
      }),
      providesTags: ["TFuelOrderModel"],
    }),
    getFuelOrderList: builder.query<TFuelOrderModel[], any>({
      query: ({supplier, status, searchQuery, page, fromDate, toDate}) => `/administrator/v1/everyday/admin_fuel_orders/tech/?supplier__id=${supplier}&status=${status}&search=${searchQuery}&page=${page}&from_date=${fromDate}&to_date=${toDate}`,
      // transformResponse: (res: TFuelOrderModel[]) =>
      //   res.sort((a, b) => b.id - a.id),
      //   providesTags: (result) =>
      //   result ? result.map(({ id }) => ({ type: 'TFuelOrderModel', id })) : ['TFuelOrderModel'],
      // keepUnusedDataFor: 5,
    }),
    getFuelCustomerOrdersList: builder.query<TFuelOrderModel, any>({
      query: ({customer}) => ({
        url: `/administrator/v1/everyday/fuel_order_customer/${customer}/tech`,
      }),
      providesTags: ["TFuelOrderModel"],
    }),
    getFuelCustomersList: builder.query<TFuelOrderModel[], any>({
      query: ({supplier, page, searchQuery}) => `/administrator/v1/everyday/fuels_customer_order/tech/?supplier__id=${supplier}&page=${page}&search=${searchQuery}`,
      // transformResponse: (res: TFuelOrderModel[]) =>
      //   res.sort((a, b) => b.id - a.id),
      // providesTags: ["TFuelOrderModel"],
      // keepUnusedDataFor: 5,
    }),
    deleteFuelOrder: builder.mutation<TFuelOrderModel, number>({
      query: (id) => ({
        url: `/administrator/v1/everyday/admin_fuel_orders/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TFuelOrderModel"],
    }),
  }),
});
export const {
  useCreateFuelOrderMutation,
  useDeleteFuelOrderMutation,
  useGetFuelCustomerOrdersListQuery,
  useGetFuelCustomersListQuery,
  useGetFuelOrderListQuery,
  useGetFuelOrderQuery,
  useUpdateFuelOrderMutation
} = fuelOrderSlice;