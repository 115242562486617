import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import plusIcon from "../../../../assets/img/icons/plus.svg";

const TransferList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  
  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Transfer List</h4>
            <h6>Transfer your stocks to one store another store.</h6>
          </div>
          <div className="page-btn">
            <a href="addtransfer.html" className="btn btn-added">
              <img src={plusIcon} alt="img" className="me-2" />
              Add Transfer
            </a>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
          <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="datetimepicker cal-icon"
                        placeholder="Choose Date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Reference" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select className="select">
                        <option>Choose Status</option>
                        <option>Inprogress</option>
                        <option>Complete</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src="assets/img/icons/search-whites.svg"
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div ref={divRef} className="table-responsive">
              <table className="table datanew">
                <thead>
                  <tr>
                    <th>
                      <label className="checkboxs">
                        <input type="checkbox" id="select-all" />
                        <span className="checkmarks"></span>
                      </label>
                    </th>
                    <th>Date</th>
                    <th>Reference</th>
                    <th>From</th>
                    <th>Paid</th>
                    <th>items</th>
                    <th>Grand total</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks"></span>
                      </label>
                    </td>
                    <td>19 Nov 2022</td>
                    <td>TR0107</td>
                    <td>Store1</td>
                    <td>Store2</td>
                    <td>10.00</td>
                    <td>150.00</td>
                    <td>
                      <span className="badges bg-lightgreen">Completed</span>
                    </td>
                    <td>
                      <a className="me-3" href="edittransfer.html">
                        <img src="assets/img/icons/edit.svg" alt="img" />
                      </a>
                      <a
                        className="me-3 confirm-text"
                        href="javascript:void(0);">
                        <img src="assets/img/icons/delete.svg" alt="img" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks"></span>
                      </label>
                    </td>
                    <td>19 Nov 2022</td>
                    <td>TR0108</td>
                    <td>Store1</td>
                    <td>Store2</td>
                    <td>10.00</td>
                    <td>365.00</td>
                    <td>
                      <span className="badges bg-lightred">Pending</span>
                    </td>
                    <td>
                      <a className="me-3" href="edittransfer.html">
                        <img src="assets/img/icons/edit.svg" alt="img" />
                      </a>
                      <a
                        className="me-3 confirm-text"
                        href="javascript:void(0);">
                        <img src="assets/img/icons/delete.svg" alt="img" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferList;
