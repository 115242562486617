import { RefObject } from 'react';
import * as XLSX from 'xlsx';

export const handleExportExcel = (divRef: RefObject<HTMLDivElement>) => {
    if (divRef.current) {
      const table = divRef.current.querySelector('table');
      if (table) {
        const workbook = XLSX.utils.table_to_book(table);
        XLSX.writeFile(workbook, 'content.xlsx');
      } else {
        console.error('No table found for export.');
      }
    }
  };