import React, { useRef } from "react";
import deleteIcon from "../../../../assets/img/icons/delete.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import { TMineral } from "../../../../redux/types/mineral.type";
import moment from "moment";
import AddMineral from "./add_mineral";
import Filters from "../../../admin/widgets/filters";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import EditMineral from "./edit_mineral";
import { useGetMineralListQuery } from "../../../../redux/slices/mineral_slice";
import DeleteMineralModal from "./delete_mineral_modal";
import Pagination from "../../../pagination/pagination_items";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";

const MineralPage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [show, setShow] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [mineral, setMineral] = React.useState<TMineral | any>();
  const [deleteMineral, setDeleteMineral] = React.useState<TMineral | any>();
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
      const [toDate, setToDate] = React.useState("");
      
      // Handler for date filter change
      const onDateFilter = (fromDate: string, toDate: string) => {
        setFromDate(fromDate);
        setToDate(toDate);
        // Call your API or perform filtering here
        console.log("Filtering from:", fromDate, "to:", toDate);
      };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowDelete = (mineral: TMineral) => {
    setDeleteMineral(mineral);
    setShowDelete(true);
  };

  const {
    data: minerals,
    isLoading,
    refetch,
    error,
  }: TMineral | any = useGetMineralListQuery({
    supplier: user?.id, 
    status: "", 
    searchQuery: query, 
    page: currentPage,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Mineral List</h4>
            <h6>Manage your Minerals</h6>
          </div>
          <div className="page-btn">
            <AddMineral fetchData={refetch} />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter}/>

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Customer Code" />
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src="assets/img/icons/search-whites.svg"
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {error ? (
              <ErrorWidget error={error}/>
            ) : minerals.results.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Ratings</th>
                      <th>Type</th>
                      <th>Unit</th>
                      <th>Product Price</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {minerals.results.map((item: TMineral, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            <div className="row">
                              <div className="col-md-4 mr-0 pr-0">
                                <a href="">
                                  <img
                                    className="product-imgs rounded"
                                    src={`${item.image}`}
                                    alt={item.productName}
                                  />
                                </a>
                              </div>
                              <div className="col-md-8 ml-0 pl-0">
                                <a href="">
                                  {item.productName.substring(0, 10)}
                                </a>
                              </div>
                            </div>
                          </td>
                          <td><i className="fa fa-star text-warning"></i> {item.rating_avg}</td>
                          <td className="text-capitalize">
                            {item.category.categoryName}
                          </td>
                          <td className="text-capitalize">{item.unit}</td>
                          <td>{Number(item.productPrice).toLocaleString()}</td>
                          <td className="text-capitalize">{item.status}</td>
                          <td>
                            {moment(item.created_at).format("Do MMMM, YYYY")}
                          </td>
                          <td>
                            <a
                              className="me-3 confirm-text"
                              onClick={() => handleShowDelete(item)}
                            >
                              <img src={deleteIcon} alt="img" />
                            </a>
                            <a
                              className="me-3 confirm-text"
                              href={`/zm/mineral/product_details/?product_id=${item.id}`}
                            ><i className="fa fa-arrow-right"></i></a>
                          </td>
                          <DeleteMineralModal
                            mineral={
                              deleteMineral === null ? item : deleteMineral
                            }
                            show={showDelete}
                            handleClose={handleCloseDelete}
                            setShow={setShowDelete}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={minerals.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MineralPage;
