import React, { useRef } from "react";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { TDealSecureTransactionModel } from "../../../../redux/types/deal_secure.type";
import { useGetDealSecureQuery } from "../../../../redux/slices/deal_secure_slice";
import moment from "moment";
import Filters from "../../widgets/filters";
import { ErrorWidget } from "../../../utils/error_widget";
import { formatPrice } from "../../../utils/format_price";

const EscrowDeals: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const transaction_id = queryParams.get("transaction");
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: transaction,
    isLoading,
    error,
  }: TDealSecureTransactionModel | any = useGetDealSecureQuery(Number(transaction_id));

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="content">
       <BreadCrumbs
        title={"DealSecure"}
        subTitle={`Transaction Details`}
      />
      <div className="card">
        <div className="card-body">
        <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
          {error ? <ErrorWidget error={error}/> : 
          <>
          <div className="row">
            <div className="col-md-6">
            <h4 className="text-center mb-4"><b>Transaction ID: {transaction.transaction_id}</b></h4>
            </div>
            <div className="col-md-6">
            <h4 className={`text-center badges ${transaction.is_paid ? "bg-lightgreen": "bg-lightred"} mb-4`}><b>Payment Status: {transaction.is_paid ? "Paid": "Unpaid"}</b></h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p><b>Start Date</b>: {moment(transaction.start_date).format("Do MMMM YYYY")}</p>
              <p><b>Duration</b>: {transaction.duration}/days</p>
              <p><b>End Date</b>: {moment(transaction.start_date).add(transaction.duration, 'days').format("Do MMMM YYYY")}</p>
              <p><b>Amount</b>: {formatPrice({currency: transaction.currency, price: transaction.amount})}</p>
              <p><b>Transaction Status</b>: <span className={`badges ${transaction.status === "COMPLETED" ? "bg-lightgreen": "bg-lightred"}`}>
              {transaction.status}</span></p>
            </div>
            <div className="col-md-4">
              <h4>{transaction.user_role1} Details</h4>
              <p><b>Name</b>: {transaction.client.first_name} {transaction.client.last_name}</p>
              <p><b>Email</b>: {transaction.client.email}</p>
              <p><b>Phone</b>: {transaction.client.phoneNumber}</p>
              <p><b>Nationality</b>: {transaction.client.nationality}</p>
            </div>
            <div className="col-md-4">
              <h4 className="text-capitalize"><b>{transaction.user_role2} Details</b></h4>
              <p className="text-capitalize"><b>Name</b>: {transaction.user.first_name} {transaction.user.last_name}</p>
              <p><b>Email</b>: {transaction.user.email}</p>
              <p><b>Phone</b>: {transaction.user.phoneNumber}</p>
              <p className="text-capitalize"><b>Nationality</b>: {transaction.user.nationality}</p>
            </div>
          </div>
          <p>
            <blockquote>
              <p>{transaction.description}</p>
            </blockquote>
          </p>
          </>}
        </div>
      </div>
    </div>
  );
};

export default EscrowDeals;
