import React from "react";
import { toast } from "react-toastify";
import { ICarBrand, ICarRental } from "../../../../redux/types/car_rental.type";
import { useForm } from "react-hook-form";
import { useGetBrandsQuery } from "../../../../redux/slices/car_brands_apislice";
import LoadingSpinner from "../../../../components/widgets/spinner";
import {
  useAdminUpdateCarMutation,
  useGetAdminCarQuery,
} from "../../../../redux/slices/admin_hire_slice";
import EditCarImages from "../../../cars_dashboard/pages/cars/edit_car_images";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import { ErrorWidget } from "../../../utils/error_widget";

const AdminCarDetails: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { data: carBrands, isLoading: fetchingBrands }: ICarBrand | any =   useGetBrandsQuery({searchQuery: "", page: ""});
  const car_id = queryParams.get("car_id");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [adminUpdateCar, { isLoading }] = useAdminUpdateCarMutation();

  const {
    data: car,
    isLoading: isFetching,
    error,
  }: ICarRental | any = useGetAdminCarQuery(Number(car_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (error) {
    return (
      <ErrorWidget error={error}/>
    );
  }

  const onSubmit = async (data: any) => {
    adminUpdateCar({ id: car_id, ...data })
      .unwrap()
      .then(() => {
        toast.success("car updated Successfully");
      })
      .catch((err) => {
        console.error(err.error);
        toast.error("Failed to add:" + err.error);
      });
  };
  return (
    <div className="content">
      <BreadCrumbs
        title={"Car Details"}
        subTitle={"View/Manage Car Details"}
      />
      <div className="card">
        <div className="card-body">
        <h3 className="mt-3 mb-4 text-center text-capitalize">
        <span className="text-primary">{car?.car_model}</span>
      </h3>
      <form
        id="paypal_donate_form_onetime_recurring"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
              <div className="col-sm-9 col-md-9">
                <div className="form-group">
                  
                  <select
                    disabled={isLoading}
                    {...register("is_active", { required: true })}
                    className={`form-control ${
                      errors.is_active ? "border-danger" : ""
                    }`}
                    defaultValue={car?.is_active}
                    name="is_active"
                  >
                    <option value={car?.is_active} selected>
                      {car?.is_active === true ? "Car in Listing" : "Pending"}
                    </option>
                    <option value="True">Approve Car</option>
                    <option value="False">Reject Car</option>
                  </select>
                  {errors.is_active && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-3 pull-right">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isLoading}
                >
                  {isLoading && <i className="fa fa-refresh fa-spin" />} Update
                  Car Status
                </button>
              </div>
              </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Seats <small className="text-danger">*</small>
                  </label>
                  <input
                    id="seats"
                    disabled={true}
                    {...register("seats", { required: false })}
                    className={`form-control ${
                      errors.seats ? "border-danger" : ""
                    }`}
                    name="seats"
                    defaultValue={car?.seats}
                    type="number"
                    placeholder="Number of Seats"
                  />
                  {errors.seats && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Speedometer <small className="text-danger">*</small>
                  </label>
                  <input
                    id="speedometer"
                    disabled={true}
                    {...register("speedometer", { required: false })}
                    className={`form-control ${
                      errors.speedometer ? "border-danger" : ""
                    }`}
                    name="speedometer"
                    defaultValue={car?.speedometer}
                    type="number"
                    placeholder="Speedometer"
                  />
                  {errors.speedometer && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Fuel Tank <small className="text-danger">*</small>
                  </label>
                  <input
                    id="fuel_litres"
                    disabled={true}
                    {...register("fuel_litres", { required: false })}
                    defaultValue={car?.fuel_litres}
                    className={`form-control ${
                      errors.fuel_litres ? "border-danger" : ""
                    }`}
                    name="fuel_litres"
                    type="number"
                    step="0.01"
                    placeholder="eg 5.6"
                  />
                  {errors.fuel_litres && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Music <small className="text-danger">*</small>
                  </label>
                  <input
                    id="music_types"
                    disabled={true}
                    {...register("music_types", { required: false })}
                    defaultValue={car?.music_types}
                    className={`form-control ${
                      errors.music_types ? "border-danger" : ""
                    }`}
                    name="music_types"
                    type="text"
                    placeholder="E.G Radiio/Bluetooth/USB"
                  />
                  {errors.music_types && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Number Plate
                    <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={true}
                    {...register("number_plate", { required: false })}
                    defaultValue={car?.number_plate}
                    className={`form-control ${
                      errors.number_plate ? "border-danger" : ""
                    }`}
                    name="number_plate"
                    type="text"
                    placeholder="Car Number Plate"
                  />
                  {errors.number_plate && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Model <small className="text-danger">*</small>
                  </label>
                  <input
                    id="title"
                    disabled={true}
                    {...register("car_model", { required: false })}
                    className={`form-control ${
                      errors.car_model ? "border-danger" : ""
                    }`}
                    name="car_model"
                    defaultValue={car?.car_model}
                    type="text"
                    placeholder="Car Manufacturer e.g BMW, VW, TOYOTA"
                  />
                  {errors.car_model && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                    Car Brand <small className="text-danger">*</small>
                  </label>
                  <select
                    disabled
                    className="form-control mb-2 text-capitalize"
                    name="car_brand"
                  >
                    <option value={car?.car_brand.id} selected>
                      {car?.car_brand.brand_name}
                    </option>
                    {fetchingBrands ? (
                      <div></div>
                    ) : (
                      carBrands.results.map((item: ICarBrand, index: number) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.brand_name}
                          </option>
                        );
                      })
                    )}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                    Local Car Rental Price{" "}
                    <small className="text-danger">*</small>
                  </label>
                  <input
                    id="form_email"
                    disabled={true}
                    defaultValue={car?.local_price}
                    {...register("local_price", { required: false })}
                    className={`form-control ${
                      errors.local_price ? "border-danger" : ""
                    }`}
                    name="local_price"
                    type="number"
                    step="0.01"
                    placeholder="Local Car Rental Price Per DAY"
                  />
                  {errors.local_price && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_email">
                    OutSide Local Car Rental Price{" "}
                    <small className="text-danger">*</small>
                  </label>
                  <input
                    id="form_email"
                    disabled={true}
                    defaultValue={car?.outside_town}
                    {...register("outside_town", { required: false })}
                    className={`form-control ${
                      errors.outside_town ? "border-danger" : ""
                    }`}
                    name="outside_town"
                    type="number"
                    step="0.01"
                    placeholder="Outside Local Car Rental Price Per DAY"
                  />
                  {errors.outside_town && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Gear Box
                    <small className="text-danger">*</small>
                  </label>
                  <select
                    disabled={true}
                    {...register("gear_type", { required: false })}
                    className={`form-control ${
                      errors.gear_type ? "border-danger" : ""
                    }`}
                    defaultValue={car?.gear_type}
                    name="gear_type"
                  >
                    <option value={car?.gear_type} selected>
                      {car?.gear_type}
                    </option>
                    <option value="automatic">Automatic Transmission</option>
                    <option value="manual">Manual Transmission</option>
                  </select>
                  {errors.gear_type && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="form_name">
                    Car Description <small className="text-danger">*</small>
                  </label>
                  <textarea
                    rows={5}
                    cols={8}
                    id="description"
                    defaultValue={car?.description}
                    disabled={true}
                    {...register("description", { required: false })}
                    className={`form-control ${
                      errors.description ? "border-danger" : ""
                    }`}
                    minLength={200}
                    name="description"
                    placeholder="Car Description"
                  ></textarea>
                  {errors.description && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              
            <div className="col-md-2 col-lg-4 shadow-xl image-full">
              <h4><b>White Book</b></h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${car?.white_book}`}
                style={{
                  width: "100%",
                  height: "200px",
                }}
                className="img-responsive rounded"
                alt="car rental"
              />
            </div>
            <div className="col-md-2 col-lg-4 shadow-xl image-full">
              <h4><b>Car Fitness</b></h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${car?.fitness}`}
                style={{
                  width: "100%",
                  height: "200px",
                }}
                className="img-responsive rounded"
                alt="car rental"
              />
            </div>
            <div className="col-md-2 col-lg-4 shadow-xl image-full">
              <h4><b>Car Insurance</b></h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${car?.insurance}`}
                style={{
                  width: "100%",
                  height: "200px",
                }}
                className="img-responsive rounded"
                alt="car rental"
              />
            </div>
            <h4><b>Image Gallery</b></h4>
              <EditCarImages carid={car_id} images={car?.car_images} />
            </div>
          </div>
        </div>
      </form>
        </div>
      </div>
    </div>
  );
};

export default AdminCarDetails;
