import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import { useUpdateRealEstateMutation } from "../../../../redux/slices/property/propertySlice";
import { PropertyModel } from "../../../../models/product_model";

export type DataFormFields = {
  status: string,
};

const statuses = [
    "PENDING",
    "COMFIRMED",
    "REJECT",
    "CANCELLED",
]

type IEditProps = {
    property: PropertyModel;
    show: boolean;
    handleClose: () => void;
    setShow: (show: boolean) => void;
  };
const EditPropertyStatus: React.FC<IEditProps> = ({property, show, handleClose, setShow,}) => {
  const [updateProperty, { isLoading }] = useUpdateRealEstateMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  const onSubmit = handleSubmit(async (data) => {
    
    updateProperty({
        id: property.id,
        status: data.status
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Product Updated Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="name">Status</label>
                <Form.Select {...register("status", { required: true })} name="status" defaultValue={property?.status} size="lg">
                    {statuses.map((item, index)=>{
                        return <option key={index} value={item}>{item}</option>
                    })}
                </Form.Select>
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPropertyStatus;
