type ICurrencyProps = {
    currency?: string;
    price: number;
  };
  
  export const formatPrice = ({ currency, price }: ICurrencyProps): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(price);
  };
  