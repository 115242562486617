import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FormInput } from "../../../../components/molecules/form_input";
import { FormTextarea } from "../../../../components/molecules/form_textarea";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { useGeTMineralCagetoryListQuery } from "../../../../redux/slices/mineral_category_slice";
import { TMineralCagetory } from "../../../../redux/types/mineral_category.type";
import { TMineral } from "../../../../redux/types/mineral.type";
import { useUpdateMineralMutation } from "../../../../redux/slices/mineral_slice";

export type DataFormFields = {
  supplier: string;
  category: string;
  productName: string;
  unit: string;
  productPrice: number;
  description: string;
  image: File;
};

type TMineralProps = {
  mineral: TMineral;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const EditMineral: React.FC<TMineralProps> = ({
  mineral,
  show,
  handleClose,
  setShow,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const [file, setFile] = useState<File | any>();

  const [updateMineral, { isLoading }] = useUpdateMineralMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    data: categories,
    isLoading: fetchingData,
    isFetching,
    error,
  }: TMineralCagetory | any = useGeTMineralCagetoryListQuery({
    searchQuery: "",
  });

  if (fetchingData || isFetching) {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin fa-2x text-danger"></i>
      </div>
    );
  }

  const onSubmit = handleSubmit(async (data) => {
    updateMineral({
      id: mineral.id,
      ...data,
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Product Created Successfully");
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Mineral Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="name">Product Name</label>
                <FormInput
                  id="productName"
                  type="text"
                  name="productName"
                  disabled={isLoading}
                  defaultValue={mineral?.productName}
                  label="Product Name"
                  placeholder="Product Name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Price</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{user?.company_currency.currency.currency_code}</span> {/* Currency sign */}
                  </div>
                  <FormInput
                    id="productPrice"
                    type="number"
                    step="0.01"
                    name="productPrice"
                    disabled={isLoading}
                    defaultValue={mineral?.productPrice}
                    label="Product Price"
                    placeholder="Product Price"
                    className="mb-2 form-control"
                    register={register}
                    rules={{ required: "Product Price is required." }}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="price">Product Unit</label>
                <FormInput
                  id="productUnit"
                  type="text"
                  step="0.01"
                  name="unit"
                  disabled={isLoading}
                  defaultValue={mineral?.unit}
                  label="Product Unit"
                  placeholder="Product Unit E.G grams, kg etc"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Product Unit is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="image">Product Image</label>
                <FormInput
                  id="thumbnail"
                  type="file"
                  name="image"
                  label="Image"
                  disabled={isLoading}
                  // defaultValue={mineral?.image}
                  accept="image/*"
                  placeholder="Image"
                  className="mb-2"
                  register={register}
                  rules={{
                    required: false,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                      }
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="label">Product Category</label>
                <select
                  {...register("category", { required: true })}
                  name="category"
                  defaultValue={mineral?.category.id}
                  className={`select text-capitalize form-control ${
                    errors.category ? "border-danger" : ""
                  }`}
                  id="category"
                >
                  <option value="">Select Category</option>
                  {categories?.map((item: TMineralCagetory, index: number) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.categoryName}
                      </option>
                    );
                  })}
                </select>
                {errors.category && (
                  <p className="text-danger">Category is required.</p>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="description">Description</label>
                <FormTextarea
                  id="description"
                  name="description"
                  disabled={isLoading}
                  defaultValue={mineral?.description}
                  label="description"
                  placeholder="Enter Description"
                  rows={8}
                  cols={12}
                  className="form-control"
                  register={register}
                  rules={{ required: "Description is required." }}
                  errors={errors}
                />
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-plus"></i> Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditMineral;
