import React, { useRef } from "react";
import editIcon from "../../../../assets/img/icons/edit.svg";
import Filters from "../../../admin/widgets/filters";
import { useGetViewRequestListQuery } from "../../../../redux/slices/car_viewing_request_slice";
import { ICarViewRequest } from "../../../../redux/types/car_booking.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import moment from "moment";
import { formatPrice } from "../../../utils/format_price";
import BreadCrumbs from "../../../widgets/bread_crumbs";

const CarViewRequestsList: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  
  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  };

  const {
    data: requests,
    isLoading,
    error,
  }: ICarViewRequest | any = useGetViewRequestListQuery({
    supplier: user?.id,
    status: status,
    page: currentPage,
    searchQuery: query,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div className="content">
      <BreadCrumbs
        title={"Car Viewing Requests List"}
        subTitle={"Manage Your Car Viewing Requests List"}
      />
        <div className="card">
          <div className="card-body">
            <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />

            <div className="card" id="filter_inputs">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="datetimepicker cal-icon"
                        placeholder="Choose Date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Reference" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select className="select">
                        <option>Choose Status</option>
                        <option>Inprogress</option>
                        <option>Complete</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src="assets/img/icons/search-whites.svg"
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {error ? (
              <ErrorWidget error={error} />
            ) : requests.results.length === 0 ? (
              <center>
                <div className="alert alert-info custom-length" role="alert">
                  <i className="fa fa-car fa-5x"></i>
                  <h1>No Requests Found</h1>
                </div>
              </center>
            ) : (
              <>
                <div ref={divRef} className="table-responsive mb-4">
                  <table className="table datanew">
                    <thead>
                      <tr>
                        <th>
                         #
                        </th>
                        <th>Client</th>
                        <th>Car</th>
                        <th>Reference</th>
                        <th>Paid</th>
                        <th>View Date</th>
                        <th>Requested on</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requests.results.map(
                        (item: ICarViewRequest, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="text-capitalize">{item.client.first_name} {item.client.last_name}</td>
                              <td className="productimgname">
                              <a
                                href={`/zm/car_dashboard/car_view_details/?car_id=${item.id}`}
                                className="product-imgs text-uppercase rounded"
                              >
                                <img style={{ height: '40px'}} src={item.car.car_images[0].image} className="img-fluid img-responsive rounded" alt="" />
                              </a>
                              <a
                                className="text-capitalize"
                                href={`/zm/car_dashboard/car_view_details/?car_id=${item.id}`}
                              >
                                {`${item.car.car_model}`}
                              </a>
                            </td>
                              <td>TR0108</td>
                              <td>{formatPrice({currency: item.company.company_currency.currency.currency_code, price: item.view_price})}</td>
                              <td>{moment(item.view_date).format("Do MMM YYYY HH:mm a")}</td>
                              <td>{moment(item.created_at).format("Do MMM YYYY HH:mm a")}</td>
                              <td>
                                <span className={`badges ${item.status === "CONFIRMED" ? "bg-lightgreen" : "bg-lightred"}`}>
                                  {item.status}
                                </span>
                              </td>
                              <td>
                                <a className="me-3" href={`/zm/car_dashboard/car_view_details/?car_id=${item.id}`}>
                                  <img
                                    src={editIcon}
                                    alt="img"
                                  />
                                </a>
                               
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>

                <Pagination
                  currentPage={currentPage}
                  lastPage={requests.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarViewRequestsList;
