import React, { useRef } from "react";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { TDealSecureTransactionModel } from "../../../../redux/types/deal_secure.type";
import { useGetDealSecureListQuery } from "../../../../redux/slices/deal_secure_slice";
import moment from "moment";
import { ErrorWidget } from "../../../utils/error_widget";
import Filters from "../../widgets/filters";
import Pagination from "../../../pagination/pagination_items";

const EscrowList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: transactionsList,
    isLoading,
    error,
  }: TDealSecureTransactionModel | any = useGetDealSecureListQuery({ 
    status: "", 
    searchQuery: query, 
    page: currentPage,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="content">
      <BreadCrumbs title={"DealSecure"} subTitle={`View DealSecure List`} />

      <div className="card">
        <div className="card-body">
        <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
          {error ? (
           <ErrorWidget error={error}/>
          ): transactionsList.results.length === 0 ? (
            <center className="p-3">
              
              <h5>No Data Found</h5>
            </center>
          ) :(
            <>
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Duration</th>
                      <th>Payment Status</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionsList.results.map(
                      (item: TDealSecureTransactionModel, index: number) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td className="text-capitalize">{item.title}</td>
                            <td>{item.transaction_id}</td>
                            <td>{moment(item.start_date).format("YYYY MMM Do")}</td>
                            <td>{Number(item.amount).toLocaleString()}</td>
                            <td>{item.duration}/days</td>
                            <td>
                              <span className={`badges ${item.is_paid ? "bg-lightgreen": "bg-lightred"}`}>
                            {item.is_paid ? "Paid": "Unpaid"}
                            </span></td>
                            <td>
                              <span className={`badges ${item.status === "COMPLETED" ? "bg-lightgreen": "bg-lightred"}`}>
                                {item.status}
                              </span>
                            </td>
                            <td>
                              <a className="me-3" href={`/zm/dashboard/deal_secure_details/?transaction=${item.id}`}>
                                <i className="fa fa-arrow-right"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  lastPage={transactionsList.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EscrowList;
