import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import httpProductServices from "../../services/http.product.services";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { DragAndDrop } from "../../widgets/DragNdrop";
import { FormInput } from "../../../components/molecules/form_input";
import { SelectInput } from "../../../components/molecules/selectInput";
import { FormTextarea } from "../../../components/molecules/form_textarea";

const FormLandForm: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [files, setFiles] = React.useState<string[]>([]);
  const [submiting, setSubmiting] = useState(false);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);



  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const handleFormSubmit = async (data: any): Promise<void> => {    
    const uid: any = user?.id;
    let form_data = new FormData();
    files.forEach((image, index) => {
      form_data.append(`property_image`, image);
    });
    form_data.append("user", uid);
    form_data.append("propertyPrice", data.propertyPrice);
    form_data.append("propertyDescription", data.propertyDescription);
    form_data.append("contactName", data.contactName);
    form_data.append("contactEmail", data.contactEmail);
    form_data.append("phoneNumber", data.phoneNumber);
    form_data.append("propertyName", data.propertyName);
    form_data.append("propertyLabel", data.propertyLabel);
    form_data.append("propertyLocation", data.propertyAddress);
    form_data.append("propertyType", data.propertyType);
    form_data.append("propertyCity", data.propertyCity);
    form_data.append("propertyCountry", data.propertyCountry);
    form_data.append("zipCode", data.zipCode);
    form_data.append("squareFeet", data.squareFeet);
    form_data.append("propertyAddress", data.propertyAddress);
    form_data.append("yearBuilt", "2024-01-20");
    form_data.append("bedrooms", "0");
    form_data.append("bathrooms", "0");
    form_data.append("garages", "0");
    form_data.append("image", files[0]);

    if (files.length > 3) {
      setSubmiting(true);
      httpProductServices
      .createProperty(form_data)
      .then((response) => {
        if (response.status === 201) {
          setSubmiting(false);
          toast.success("Property Added Successfully");
        }
      })
      .catch((error: any) => {
        setSubmiting(false);
        toast.error(error.message);
      });
    } else {
      toast.error("Please select more than 5 images")
    }
    // reset();
  };

  return (
    <>
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
            <h3 className="mt-3 mb-3 text-center">Farm/Land Listing</h3>
              <div className="row">
                {/* <!-- Submit Form --> */}
                <div className="col-lg-12 col-md-12">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="submit-page">
        {/* Basic Information */}
        <div className="frm_submit_block">
          <h3>Basic Information</h3>
          <div className="frm_submit_wrap">
            <div className="row">
              <div className="col-md-4">
                <FormInput
                  id="propertyName"
                  type="text"
                  name="propertyName"
                  label="Property Title"
                  placeholder="Property Title"
                  register={register}
                  rules={{ required: "Property Name is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>

              <div className="col-md-4">
                <SelectInput
                  id="propertyLabel"
                  name="propertyLabel"
                  label="Property Label"
                  register={register}
                  rules={{ required: "Property Label is required!" }}
                  errors={errors}
                  disabled={submiting}
                >
                  <option value="">Select Property Label</option>
                  <option value="rent">For Rent</option>
                  <option value="sale">For Sale</option>
                </SelectInput>
              </div>

              <div className="col-md-4">
                <SelectInput
                  id="propertyType"
                  name="propertyType"
                  label="Property Type"
                  register={register}
                  rules={{ required: "Property Type is required!" }}
                  errors={errors}
                  disabled={submiting}
                >
                  <option value="">Select Property Type</option>
                  <option value="lands">Land</option>
                  <option value="farms">Farm</option>
                </SelectInput>
              </div>
              <div className="col-md-4">
                <FormInput
                  id="propertyPrice"
                  type="number"
                  name="propertyPrice"
                  label="Price"
                  placeholder="Property Price"
                  register={register}
                  rules={{ required: "Property Price is required!" }}
                  errors={errors}
                  disabled={submiting}
                  className="input-group"
                />
              </div>

              <div className="col-md-4">
                <FormInput
                  id="areaLocation"
                  type="text"
                  name="areaLocation"
                  label="Area"
                  placeholder="Area"
                  register={register}
                  rules={{ required: "Property Area is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>

              <div className="col-md-4">
                <FormInput
                  id="squareFeet"
                  type="number"
                  name="squareFeet"
                  label="Square Feet"
                  placeholder="Square Feet"
                  register={register}
                  rules={{ required: "Property Square Feet is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Gallery */}
        <div className="frm_submit_block rounded" style={{ backgroundColor: "lightgray" }}>
          <h3 className="m-2">Gallery</h3>
          <div className="frm_submit_wrap">
            <DragAndDrop
              heading={"Land/Farm Image Gallery"}
              onChange={(e: any) => {
                if (e.target.files && e.target.files[0]) {
                  for (let i = 0; i < e.target.files["length"]; i++) {
                    setFiles((prevState) => [...prevState, e.target.files[i]]);
                  }
                }
              }}
              setFiles={setFiles}
              files={files}
            />
          </div>
        </div>

        

        {/* Location */}
        <div className="frm_submit_block">
          <h3>Location</h3>
          <div className="frm_submit_wrap">
            <div className="row">
              <div className="col-md-3">
                <FormInput
                  id="propertyAddress"
                  type="text"
                  name="propertyAddress"
                  label="Address"
                  placeholder="Address"
                  register={register}
                  rules={{ required: "Property Address is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>

              <div className="col-md-3">
                <FormInput
                  id="propertyCity"
                  type="text"
                  name="propertyCity"
                  label="City"
                  placeholder="City"
                  register={register}
                  rules={{ required: "Property City is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>

              <div className="col-md-3">
                <FormInput
                  id="propertyCountry"
                  type="text"
                  name="propertyCountry"
                  label="State"
                  placeholder="State"
                  register={register}
                  rules={{ required: "Property State is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>

              <div className="col-md-3">
                <FormInput
                  id="zipCode"
                  type="text"
                  name="zipCode"
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  register={register}
                  rules={{ required: "Property Zip Code is required!" }}
                  errors={errors}
                  disabled={submiting}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Property Description */}
        <div className="form-group col-md-12">
          <FormTextarea
            id="propertyDescription"
            name="propertyDescription"
            label="Property Description"
            placeholder="Property Description"
            register={register}
            rules={{ required: "Property Description is required!" }}
            errors={errors}
            disabled={submiting}
            className="h-120"
          />
        </div>
        {/* Contact Information */}
        <div className="frm_submit_block">
          <h3>Contact Information</h3>
          <div className="frm_submit_wrap">
            <div className="row">
              <div className="col-md-4">
                <FormInput
                  id="contactName"
                  type="text"
                  name="contactName"
                  label="Name"
                  placeholder="Property Contact Name"
                  register={register}
                  rules={{ required: "Property Contact Name is required!" }}
                  errors={errors}
                  disabled={submiting}
                  defaultValue={`${user?.first_name} ${user?.last_name}`}
                />
              </div>

              <div className="col-md-4">
                <FormInput
                  id="contactEmail"
                  type="email"
                  name="contactEmail"
                  label="Email"
                  placeholder="Property Contact Email"
                  register={register}
                  rules={{ required: "Property Contact Email is required!" }}
                  errors={errors}
                  disabled={submiting}
                  defaultValue={user?.email}
                />
              </div>

              <div className="col-md-4">
                <FormInput
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  label="Phone"
                  placeholder="Property Contact Number"
                  register={register}
                  rules={{ required: "Property Phone Number is required!" }}
                  errors={errors}
                  disabled={submiting}
                  defaultValue={user?.phoneNumber}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Consent Checkbox */}
        <div className="form-group col-lg-12 col-md-12">
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="gdpr" />
            <label className="form-check-label" htmlFor="gdpr">
              I consent to having this website store my submitted information so they can respond to my inquiry.
            </label>
          </div>
        </div>

        {/* Submit Button */}
        <div className="form-group col-lg-12 col-md-12 text-center">
          <button
            disabled={submiting || files.length < 3}
            className="btn btn-primary"
            type="submit"
          >
            {files.length < 3 ? "Select at least 4 images" : "Submit"}
            {submiting && (
              <span>
                <i className="fa fa-refresh fa-spin"></i>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </>
  );
};

export default FormLandForm;
