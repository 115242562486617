import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import editIcon from "../../../../assets/img/icons/edit.svg";
import dollarIcon from "../../../../assets/img/icons/dollar-square.svg";
import downloadIcon from "../../../../assets/img/icons/download.svg";
import deleteIcon from "../../../../assets/img/icons/delete1.svg";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetConstructionQuery } from "../../../../redux/slices/construction_slice";
import { TConstruction, TConstructionMiningOrders } from "../../../../redux/types/construction.type";
import moment from "moment";
import MiningConstructionOrderChart from "../construction/stats";

const MiningConstructionOrdersPage: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const queryParams = new URLSearchParams(window.location.search);
  const transaction_id = queryParams.get("transaction_id");
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
  
    // Handler for date filter change
    const onDateFilter = (fromDate: string, toDate: string) => {
      setFromDate(fromDate);
      setToDate(toDate);
      // Call your API or perform filtering here
      console.log("Filtering from:", fromDate, "to:", toDate);
    };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
    data: transaction,
    isLoading: isFetching,
    error,
  }: TConstruction | any = useGetConstructionQuery(Number(transaction_id));

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <BreadCrumbs
          title={"Mining Orders List"}
          subTitle={"View/Manage Mining Orders List"}
        />

        <div className="card">
          <div className="card-body">
            {error ? (
              <div className="alert alert-danger" role="alert">
                {error.data?.detail}
              </div>
            ) : (
              <>
                <Filters divRef={divRef} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
                <div className="mb-2">
                  <h3 className="mt-3 mb-2 text-center text-capitalize">
                    <span className="text-primary">
                      {transaction?.productName}
                    </span>
                  </h3>
                  <div className="text-center text-capitalize">
                    <h4>{transaction?.supplier.company_name}</h4>
                  </div>
                  <MiningConstructionOrderChart transaction_id={transaction_id} />
                  <h1>
                    Transactions (
                    {transaction?.construction_product_order.length}){" "}
                  </h1>
                </div>
                {transaction?.construction_product_order.length === 0 ? (
                  <div className="alert alert-info text-center">
                    <h3>No Transactions Found</h3>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table datanew">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer</th>
                          <th>Date</th>
                          <th>Reference</th>
                          <th>Status</th>
                          <th>Payment</th>
                          <th>Qty</th>
                          <th>Total</th>
                          <th>Paid</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transaction?.construction_product_order.map((item: TConstructionMiningOrders, index: number) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.customer.first_name} {item.customer.last_name}</td>
                                <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                                <td>{item.order_unique_id}</td>
                                <td>
                                  <span className={`badges ${item.status !== "CONFIRMED" ? "bg-lightred" : "bg-lightgreen"}`}>
                                    {item.status}
                                  </span>
                                </td>
                                <td>
                                  <span className="badges bg-lightgreen">
                                    Paid
                                  </span>
                                </td>
                                <td>{item.quantity}</td>
                                <td className="text-green">
                                  {Number(item.productPrice).toLocaleString()}
                                </td>
                                <td>{Number(item.productPrice).toLocaleString()}</td>
                                <td className="text-center">
                                  {user?.user_type === "ADMIN" ? (
                                    <span className="badges bg-lightred">
                                      No Action
                                    </span>
                                  ) : (
                                    <a
                                      className="action-set"
                                      href="javascript:void(0);"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                    >
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  )}

                                  <ul className="dropdown-menu">
                                    <li>
                                      <a href="#" className="dropdown-item">
                                        <img
                                          src={editIcon}
                                          className="me-2"
                                          alt="img"
                                        />
                                        Edit Sale
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showpayment"
                                      >
                                        <img
                                          src={dollarIcon}
                                          className="me-2"
                                          alt="img"
                                        />
                                        Show Payments
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img
                                          src={downloadIcon}
                                          className="me-2"
                                          alt="img"
                                        />
                                        Download pdf
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item confirm-text"
                                      >
                                        <img
                                          src={deleteIcon}
                                          className="me-2"
                                          alt="img"
                                        />
                                        Delete Sale
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MiningConstructionOrdersPage;
