import React from 'react';
import { useLocation } from 'react-router-dom';
import dashboard from "../../../assets/img/logo.jpg";

const CommonLinks: React.FC = () => {
  const location = useLocation();

  return (
    <>
      {/* Dashboard Link */}
      <li className={`${location.pathname === "/zm/dashboard" ? "active" : ""}`}>
        <a href="/zm/dashboard">
          <img style={{ borderRadius: '50%' }} src={dashboard} alt="img" />
          <span>Dashboard</span>
        </a>
      </li>

      {/* My Profile Link */}
      <li className={`${location.pathname === "/zm/dashboard/profile/" ? "active" : ""}`}>
        <a href="/zm/dashboard/profile/">
          <i className="fa fa-user-tie text-muted"></i>
          <span>My Profile</span>
          <span className="menu-arrow"></span>
        </a>
      </li>

      {/* Change Password Link */}
      <li className={`${location.pathname === "/zm/dashboard/change-password" ? "active" : ""}`}>
        <a href="/zm/dashboard/change-password">
          <i className="fa fa-unlock-alt text-muted"></i>
          <span>Change Password</span>
          <span className="menu-arrow"></span>
        </a>
      </li>
    </>
  );
};

export default CommonLinks;