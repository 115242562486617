import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { RefObject } from 'react';

export const handleExportPDF = async (divRef: RefObject<HTMLDivElement>) => {
    if (divRef.current) {
      try {
        const canvas = await html2canvas(divRef.current);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
        pdf.save('content.pdf');
      } catch (error) {
        console.error('Error exporting to PDF:', error);
      }
    }
  };