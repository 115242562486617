import React from "react";
import editIcon from "../../../../../assets/img/icons/edit.svg";
import deleteIcon from "../../../../../assets/img/icons/delete.svg";
import AddMainCategory from "./add_main_category";
import { TMineralCagetory } from "../../../../../redux/types/mineral_category.type";
import { useGeTMineralCagetoryListQuery } from "../../../../../redux/slices/mineral_category_slice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../hooks/redux-hooks";
import LoadingSpinner from "../../../../../components/widgets/spinner";

const MineralCategories: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const navigate = useNavigate();
   const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setQuery(newValue);
    }
  const {
    data: categories,
    isLoading,
    refetch,
    error,
  }: TMineralCagetory | any = useGeTMineralCagetoryListQuery({
    searchQuery: query,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
          <button onClick={() => navigate(-1)} className="btn btn-sm btn-secondary text-white">
           <i className="fa fa-arrow-left"></i>
          </button>
            <h4>Mineral Category list</h4>
            <h6>View/Search Mineral Category</h6>
          </div>
          {user?.user_type !== "ADMIN" ? "" : <AddMainCategory fetchData={refetch} />}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input className="form-control" type="search" onChange={onChange} name="search_mineral" id="search_mineral" placeholder="Search..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {error ? (
              <div className="alert alert-danger text-center mt-5" role="alert">
                {error.status}
              </div>
            ) : categories.length === 0 ? (
              <div className="text-center">
                <i style={{ fontSize: "50px" }} className="ti-home"></i>
                <h2>No Data Found</h2>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Category name</th>
                      <th>Category Code</th>
                      <th>Created By</th>
                      <th>Created On</th>
                      <th>Status</th>
                      {user?.user_type !== "ADMIN" ? null : <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((item: TMineralCagetory, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {index + 1}
                          </td>
                          <td className="productimgname">
                            <a
                              href={`/zm/products?main_category=${item.categoryName.toLocaleLowerCase()}`}
                              className="product-img rounded"
                            >
                              <img
                                src={`${item.image}`}
                                alt="product"
                                className="rounded"
                              />
                            </a>
                            <a className="text-capitalize" href={`#`}>
                              {item.categoryName}
                            </a>
                          </td>
                          <td>{item.mineral_category_id}</td>
                          <td>Admin</td>
                          <td>{moment(item.created_at).format("MMMM Do, YYYY")}</td>
                          <td className="text-capitalize">{item.status}</td>
                          {user?.user_type !== "ADMIN" ? null : <td>
                            <a className="me-3" href="#">
                              <img src={editIcon} alt="img" />
                            </a>
                            <a
                              className="me-3 confirm-text"
                              href="javascript:void(0);"
                            >
                              <img src={deleteIcon} alt="img" />
                            </a>
                          </td>}
                          
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MineralCategories;
