import React, { useRef } from 'react'
import { ICarRental } from '../../../../redux/types/car_rental.type';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { useGetAdminPendingCarsQuery } from '../../../../redux/slices/admin_hire_slice';
import moment from 'moment';
import Pagination from '../../../pagination/pagination_items';
import Filters from '../../widgets/filters';
import { ErrorWidget } from '../../../utils/error_widget';

const RecentProducts: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const {
    data: carList,
    isLoading,
    error,
  }: ICarRental | any = useGetAdminPendingCarsQuery({
    searchQuery: query, 
    page: currentPage, 
    status: '', label: "",
    fromDate: fromDate,
    toDate:   toDate,
  })

  if (isLoading) {
    return (
      <div style={{ marginTop: "10%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  return (
    <>
      <div className="card mb-0">
          <div className="card-body">
            <h4 className="card-title">Car Rentals/Deals </h4>
            <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input className="form-control" value={query}
                      onChange={onChange} type="search" name="search_cars" id="search_cars" placeholder="Search..." />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                  <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />
                  </div>
                </div>
            {error ? <ErrorWidget error={error}/>: <>
              <div className="table-responsive dataview">
              <table className="table datatable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Car</th>
                    <th>Company</th>
                    <th>Brand Name</th>
                    <th>Sales</th>
                    <th>Status</th>
                    <th>Posted Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {carList.results.map((item: ICarRental, index: number)=>{
                    return <tr>
                    <td>{index + 1}</td>
                    
                    <td className="productimgname">
                      <a className="product-img" href={`/zm/dashboard/car_details/?car_id=${item.id}`}>
                        <img
                        className='rounded'
                          src={`${item.car_images[0].image}`}
                          alt="product"
                        />
                      </a>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}>{item.car_model.substring(0, 20)}{item.car_model.length > 20 ? "...": ""}</a>
                    </td>
                    <td>
                      <a className='text-capitalize' href="javascript:void(0);">{item.user.company_name}</a>
                    </td>
                    <td className='text-capitalize'>{item.car_brand.brand_name}</td>
                    <td>{item.car_user_rental.length}</td>
                    <td>{item.is_active ? "Active": "Pending"}</td>
                    <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                    <td>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}><i className='fa fa-arrow-right'></i></a>
                      <a className='m-3' href={`/zm/dashboard/car_transactions/?car_id=${item.id}`}><i className='fa fa-eye'></i></a>
                    </td>
                  </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <Pagination
                currentPage={currentPage}
                lastPage={carList.total_pages??0}
                maxLength={7}
                setCurrentPage={setCurrentPage}
              />
              </div>
            </>}
          </div>
        </div>
    </>
  )
}

export default RecentProducts
