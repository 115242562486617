import React from "react";
import MessageBox from "./message_box";
import moment from "moment";
import { IMessage } from "../../../../redux/types/user_message.interface";
import { useGetMineralMessagesListQuery } from "../../../../redux/slices/mineral_slice";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { ErrorWidget } from "../../../utils/error_widget";
import { useAppSelector } from "../../../../hooks/redux-hooks";

const Chat: React.FC = () => {
  const [selectedUser, setSelectedUser] = React.useState<IMessage | null>(null);
  const user = useAppSelector((state) => state.auth.user);

  // Handle user click
  const handleUserClick = (user: IMessage) => {
    setSelectedUser(user);
  };

    const {
      data: messageList,
      isLoading,
      error,
    }: IMessage | any = useGetMineralMessagesListQuery();

    if (isLoading) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
  
    if (error) {
      return <ErrorWidget error={error} />;
    }

  return (
    <div className="content">
      <div className="col-lg-12">
        <div className="row chat-window">
          <div className="col-lg-5 col-xl-4 chat-cont-left">
            <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
              <div className="card-body contacts_body chat-users-list chat-scroll">
                {messageList.length === 0 ? <div className="text-muted text-center">
                  <i className="fa fa-messages"></i>
                  <h4><b>No Messages</b></h4>
                </div> : messageList.map((message: IMessage, index: number) => (
                  <a
                    key={index}
                    href="javascript:void(0);"
                    className={`media d-flex ${selectedUser?.id === message.id ? 'active' : ''}`}
                    onClick={() => handleUserClick(message)}
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={message.product.image}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name text-capitalize">
                        {message.user.id === user?.id && message.product.supplier.id === user?.id
                          ? `${message.receiver.first_name} ${message.receiver.last_name}`
                          :message.user.id !== user?.id && message.product.supplier.id !== user?.id 
                          ?  message.product.productName : `${message.user.first_name} ${message.user.last_name}`}
                        </div>
                        <div className="user-last-chat">
                          {message?.text}
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">
                          {moment(message.created_at).fromNow()}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {selectedUser && (
            <MessageBox
              selectedUser={selectedUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;