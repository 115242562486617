import React from 'react';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import LoadingSpinner from '../../../../components/widgets/spinner';
import { useGetFuelQuery } from '../../../../redux/slices/fuel_slice';
import { ErrorWidget } from '../../../utils/error_widget';
import EditFuel from './edit_fuel';
import BreadCrumbs from '../../../widgets/bread_crumbs';
import editIcon from "../../../../assets/img/icons/edit-5.svg";
import moment from 'moment';
import { IFuelReview, TFuelModel, TFuelOrderModel } from '../../../../redux/types/fuel_mode.type';
import FuelOrderChart from '../../../admin/pages/fuels/stats';
import { formatPrice } from '../../../utils/format_price';
import { renderStars } from '../../../utils/render_star';

const ProductDetails: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [editProduct, setEditProduct] = React.useState<TFuelModel | any>(null);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const product_id = queryParams.get("product_id");

  const {
    data: product,
    isLoading: isFetching,
    error,
  } = useGetFuelQuery(Number(product_id));

  const handleEditClick = (product: TFuelModel) => {
    setEditProduct(product);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  if (isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (!product) {
    return <div>No product found.</div>;
  }

  return (
    <>
      <EditFuel
        product={editProduct}
        show={showEditModal}
        setShow={setShowEditModal}
        handleClose={handleCloseEditModal}
      />

      <div className="content">
        <BreadCrumbs
          title={"Fuel Product Details"}
          subTitle={"View/Manage Fuel Product Details"}
        />

        <FuelOrderChart transaction_id={product_id} company_id={user?.id} />
        {/* Display Average Rating */}
        <div style={{ marginBottom: '20px' }}>
          <h3>Average Rating: {product.rating_avg}
          </h3>
          <div>
            {renderStars(product.rating_avg, 40)}
          </div>
        </div>

        <div className="row g-0">
          {/* Product Image */}
          <div className="col-md-4">
            <img
              src={`${process.env.REACT_APP_API_URL}${product.thumbnail}`}
              alt={product.fuel_name}
              className="img-fluid rounded"
              style={{ height: '100%', objectFit: 'cover' }}
            />
          </div>

          {/* Product Details */}
          <div className="col-md-8">
            <div className="card-body">
              <h1 className="card-title display-10 text-capitalize">
                <b>{product.fuel_name}</b>
              </h1>
              <p className="card-text text-muted">{product.location}</p>

              <div className="row mt-4">
                <div className="col-md-6">
                  <p className="mb-2">
                    <strong>Price:</strong> {formatPrice({currency: user?.company_currency.currency.currency_code, price: product.fuel_price})}
                  </p>
                  <p className="mb-2">
                    <strong>Unit:</strong> {product.unit}
                  </p>
                  <p className="mb-2">
                    <strong>Type:</strong> {product.fuel_type}
                  </p>
                  <p className="mb-2">
                    <strong>Quantity:</strong> {product.quantity}l
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-2">
                        <strong>Status:</strong>{" "}
                        <span className={`badge ${product.quantity > 1 ? 'bg-success' : 'bg-warning'}`}>
                          {product.status}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <a
                        className="mt-5 ml-4 text-warning p-2 rounded"
                        onClick={() => handleEditClick(product)}
                      >
                        <img width={30} height={30} src={editIcon} alt="Edit" />
                      </a>
                    </div>
                  </div>
                  <p className="mb-2">
                    <strong>Total Sales:</strong> {product.fuel_order_product.length}
                  </p>
                  <p className="mb-2">
                    <strong>Created At:</strong> {moment(product.created_at).format("YYYY-MM-DD HH:mm a")}
                  </p>
                  <p className="mb-2">
                    <strong>Product ID:</strong> {product.product_unique_id}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Order Products Section */}
        <div className="mt-4">
          <h3 className="h5"><b>Orders</b></h3>
          {product.fuel_order_product.length > 0 ? (
            <table className="table datanew">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer Name</th>
                  <th>Reference</th>
                  <th>Date</th>
                  <th>Quantity</th>
                  <th>Paid</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {product.fuel_order_product.map((item: TFuelOrderModel, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-bolds text-capitalize">{item.customer.first_name} {item.customer.last_name}</td>
                    <td>{item.fuel_order_id}</td>
                    <td>{moment(item.created_at).format("Do MMM, YYYY")}</td>
                    <td>{item.quantity}</td>
                    <td>{formatPrice({currency: user?.company_currency.currency.currency_code, price: item.productPrice})}</td>
                    <td>
                      <span className="badges bg-lightgreen text-capitalize">{item.status}</span>
                    </td>
                    <td>
                      <a className="me-3" href={`/zm/dashboard/${user?.user_type}/order_details/?order_id=${item.id}`}>
                        <img src={editIcon} alt="Edit" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center">
              <h4 className="text-muted">No placed orders yet.</h4>
            </div>
          )}
        </div>

        {/* Product Reviews Section */}
        <div className="mt-4">
          <h3 className="h5"><b>Product Reviews</b></h3>
          {product.fuel_product_review.length > 0 ? (
            <div className="list-group">
              {product.fuel_product_review.map((review: IFuelReview, index: number) => (
                <div key={index} className="list-group-item">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{review.user.first_name} {review.user.last_name}</strong>
                      <span className="text-warning ms-2">
                        {renderStars(review.rating, 20)} {review.rating}
                      </span>
                    </div>
                    <small className="text-muted">
                      {moment(review.created_at).format("YYYY-MM-DD HH:mm a")}
                    </small>
                  </div>
                  <p className="mt-2 mb-0">{review.review}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h4 className="text-muted">No reviews yet.</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetails;