import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useDeleteBrandMutation } from "../../../../redux/slices/car_brands_apislice";
import { ICarBrand } from "../../../../redux/types/car_rental.type";

type TProps = {
  brand: ICarBrand;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
};

const DeleteBrandModal: React.FC<TProps> = ({
  brand,
  show,
  handleClose,
  setShow,
}) => {
  const [deleteBrand, { isLoading: deleting, error: errorDeleting, isSuccess }] =
    useDeleteBrandMutation();

  const onDelete = () => {
    deleteBrand(brand.id)
      .unwrap()
      .then((res) => {
        setShow(false);
        toast.success("Brand Deleted Successfully");
      })
      .catch((err) => {
        toast.error("An error occurred try again");
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <i className="fa fa-car fa-5x text-danger"></i>
          </center>
          <h3 className="text-center mt-3 mb-3">Are you sure you want to delete <br />
          <b className="text-capitalize">{brand?.brand_name}</b></h3>
          <p className="text-danger">
            This action can't be undone and all the information and
            products related to this brand will be delete and can't be retrieved
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={deleting} onClick={handleClose}>
            Close
          </Button>
          <Button disabled={deleting} onClick={onDelete} variant="danger">
            {deleting && <Spinner size="sm" />} Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteBrandModal;
