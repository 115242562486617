import React, { useRef } from "react";
import searchIcon from "../../../../assets/img/icons/search-whites.svg";
import Filters from "../../widgets/filters";
import { useGetConstructionListQuery } from "../../../../redux/slices/construction_slice";
import { TConstruction } from "../../../../redux/types/construction.type";
import moment from "moment";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import { useGetCompaniesListQuery } from "../../../../redux/slices/authApiSlice";
import { User } from "../../../../redux/types/user";
import { formatPrice } from "../../../utils/format_price";

const MiningList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [supplier, setSupplier] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  // Handler for date filter change
  const onDateFilter = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    // Call your API or perform filtering here
    console.log("Filtering from:", fromDate, "to:", toDate);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }

  const onChangeSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSupplier(newValue);
  }

  const {
    data: usersList,
    isLoading: loadingUsers,
    error: usersError,
  }: User | any = useGetCompaniesListQuery({ 
    user_type: "MINING",
    
   });
  const {
    data: constructionData,
    isLoading,
    error,
  }: TConstruction | any = useGetConstructionListQuery({
    supplier: supplier, 
    product_type: "MINING", 
    page: currentPage, 
    searchQuery: query,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading || loadingUsers) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Mining List</h4>
            <h6>Manage your Minings</h6>
          </div>
          <div className="page-btn">
            {user?.user_type === "ADMIN" ? <span></span> : ""}
          </div>
        </div>

        <div className="card">
          {error || usersError ? (
           <ErrorWidget error={error | usersError}/>
          ) : constructionData.results.length === 0 ? (
            <center className="p-3">
              <i className="fa fa-hammer fa-5x"></i>
              <h4>
                <b>No Data Found</b>
              </h4>
            </center>
          ) : (
            <div className="card-body">
              <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />

              <div className="card" id="">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input type="search" className="form-control" value={query}
                          onChange={onChange} placeholder="Search products" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                      <select onChange={onChangeSupplier} name="supplier__id" className="select form-control">
                          <option value="">Choose Supplier</option>
                          {usersList.map((item: User, index: number)=>{
                            return <option key={index} value={item.id}>{item.company_name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select form-control">
                          <option>Choose Status</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto">
                          <img
                            src={searchIcon}
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Supplier</th>
                      <th>Product</th>
                      <th>Reference</th>
                      <th>Product Price</th>
                      <th>Quantity</th>
                      <th>Sales</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {constructionData.results.map(
                      (item: TConstruction, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-bolds text-capitalize">
                              {item.supplier.company_name.substring(0, 20)}{item.supplier.company_name.length > 20 ? "..." : ""}
                            </td>
                            <td className="text-bolds text-capitalize">
                              {item.productName.substring(0, 20)}{item.productName.length > 20 ? "..." : ""}
                            </td>
                            <td>{item.product_unique_id}</td>
                            <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.productPrice})}</td>
                            <td>{item.quantity}</td>
                            <td>{item.construction_product_order.length}</td>
                            <td>
                              <span className="badges bg-lightgreen">
                                {item.status}
                              </span>
                            </td>
                            <td>
                              {moment(item.created_at).format("DD MMMM YYYY")}
                            </td>
                            <td>
                              {user?.user_type === "ADMIN" ? (
                                 <a className="me-3" href={`/zm/dashboard/construction_mining_transaction/?transaction_id=${item.id}`}>
                                    <i className="fa fa-arrow-right"></i>
                                  </a>
                              ) : (
                                null
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                  currentPage={currentPage}
                  lastPage={constructionData.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MiningList;
