import React from "react";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import moment from "moment";
import eyeIcon from "../../../../assets/img/icons/eye1.svg";
import editIcon from "../../../../assets/img/icons/edit.svg";
import TransactionModalDetails from "./transaction_modal_details";
import { formatPrice } from "../../../utils/format_price";

interface IReservationCard {
  item: ICarBooking;
  index: number;
}

const ReservationCard: React.FC<IReservationCard> = ({ item, index }) => {
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [details, setDetails] = React.useState<ICarBooking | null>(null);
  // DETAILS MODAL
  const handleShow = (item: ICarBooking) => {
    setDetails(item);
    setShowDetailsModal(true);
  };
  const handleCloseDetailsModel = () => {
    setShowDetailsModal(false);
  };
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>
          {item.car.car_model.substring(0, 12)}...
        </td>
        <td>{moment(item.client_pickup_date_time).format("Do MMM YYYY HH:mm a")}</td>
        <td className="text-capitalize">
          {item.client.first_name} {item.client.last_name}
        </td>
        <td>{item.car_rental_unique_id}</td>
        <td>
          <span className={`badges ${item.status !== "CONFIRMED" ? "bg-lightred": "bg-lightgreen"}`}>{item.status}</span>
        </td>
        <td>
          <span className="badges bg-lightgreen">Paid</span>
        </td>
        <td>{formatPrice({currency: item.company.company_currency.currency.currency_code, price: item.car.local_price})}</td>
        <td className="text-green">{formatPrice({currency: item.company.company_currency.currency.currency_code, price: item.rental_price})}</td>

        <td className="text-center">
          <a
            className="action-set"
            href="javascript:void(0);"
            data-bs-toggle="dropdown"
            aria-expanded="true">
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </a>
          <ul className="dropdown-menu">
            <li>
              <a onClick={()=> handleShow(item)} className="dropdown-item">
                <img src={eyeIcon} className="me-2" alt="img" />
                Rental Detail
              </a>
            </li>
            <li>
              <a href={`/zm/car_dashboard/rental_details?rental_id=${item.id}`} className="dropdown-item">
                <img src={editIcon} className="me-2" alt="img" />
                Edit Rental
              </a>
            </li>
          </ul>
        </td>
      </tr>
      <TransactionModalDetails
        show={showDetailsModal}
        item={details === null ? item : details}
        handleClose={handleCloseDetailsModel}
      />
    </>
  );
};

export default ReservationCard;
