import React, { useState } from "react";
import plusIcon from "../../../../../assets/img/icons/plus.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { FormInput } from "../../../../../components/molecules/form_input";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useUpdateCurrencyMutation } from "../../../../../redux/slices/country_currency_slice";
import { TCountyCurrency } from "../../../../../redux/types/country_currency.type";


export type DataFormFields = {
  country: string;
  currency_name: string;
  currency_code: string;
  currency_symbol: string;
};

type TEditProps={
  currency: TCountyCurrency;
  show: boolean;
  handleClose: () => void;
  setShow: (show: boolean) => void;
}

const EditCountryCurrency: React.FC<TEditProps> = ({currency, show, handleClose, setShow}) => {
  const [updateCurrency, { isLoading }] = useUpdateCurrencyMutation();
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);

    updateCurrency({
      id: currency.id,
      ...data
    })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Currency Updated Successfully");
      })
      .catch((error) => {
        toast.error("Error: " + error.data["detail"]);
      });
  });
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Currency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="name">Country</label>
                <FormInput<DataFormFields>
                  id="country"
                  type="text"
                  name="country"
                  defaultValue={currency?.country}
                  disabled={isLoading}
                  label="Country"
                  placeholder="Country"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Country is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="price">Currency name</label>
                <FormInput<DataFormFields>
                  id="currency_name"
                  type="text"
                  name="currency_name"
                  defaultValue={currency?.currency_name}
                  disabled={isLoading}
                  label="Currency name"
                  placeholder="Currency name"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Currency name is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="price">Currency Code</label>
                <FormInput<DataFormFields>
                  id="currency_code"
                  type="text"
                  name="currency_code"
                  defaultValue={currency?.currency_code}
                  disabled={isLoading}
                  label="Currency Code"
                  placeholder="Currency Code"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Currency Code is required." }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="price">Currency Symbol</label>
                <FormInput<DataFormFields>
                  id="currency_symbol"
                  type="text"
                  name="currency_symbol"
                  defaultValue={currency?.currency_symbol}
                  disabled={isLoading}
                  label="Currency Symbol"
                  placeholder="Currency Symbol"
                  className="mb-2"
                  register={register}
                  rules={{ required: "Currency Symbol is required." }}
                  errors={errors}
                />
              </div>
              
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} variant="primary" type="submit">
                {isLoading && <Spinner size="sm" />}{" "}
                <i className="fa fa-upload"></i> Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCountryCurrency;
