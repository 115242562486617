import React, {RefObject} from "react";
import pdf from "../../../assets/img/icons/pdf.svg";
import excel from "../../../assets/img/icons/excel.svg";
import printer from "../../../assets/img/icons/printer.svg";
import filter from "../../../assets/img/icons/filter.svg";
import close from "../../../assets/img/icons/closes.svg";
import search from "../../../assets/img/icons/search-white.svg";
import searchIcon from "../../../assets/img/icons/search-whites.svg";
import { handleExportPDF } from "./export_to_pdf";
import { handleExportExcel } from "./export_to_excel";
import { handlePrint } from "./print";

type IFilterProps = {
  showSearch?: boolean;
  onSearchChange: (query: any) => void;
  onDateFilter: (fromDate: string, toDate: string) => void;
  query: string;
  divRef: RefObject<HTMLDivElement>;
};

const Filters: React.FC<IFilterProps> = ({
  showSearch,
  onSearchChange,
  onDateFilter,
  query,
  divRef,
}) => {
  const [fromDate, setFromDate] = React.useState<string>("");
  const [toDate, setToDate] = React.useState<string>("");

  const handleDateFilter = () => {
    onDateFilter(fromDate, toDate);
  };

  return (
    <>
      <div className="table-top">
        {showSearch !== null && showSearch === false ? (
          <div></div>
        ) : (
          <div className="search-set">
            <div className="search-path">
              <a className="btn btn-filter" id="filter_search">
                <img src={filter} alt="img" />
                <span>
                  <img src={close} alt="img" />
                </span>
              </a>
            </div>
            <div className="search-input">
              <a className="btn btn-searchset">
                <img src={search} alt="img" />
              </a>
            </div>
          </div>
        )}

        <div className="wordset">
          <ul>
            <li>
              <a
                onClick={()=>handleExportPDF(divRef)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="pdf"
              >
                <img src={pdf} alt="img" />
              </a>
            </li>
            <li>
              <a
                onClick={()=>handleExportExcel(divRef)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="excel"
              >
                <img src={excel} alt="img" />
              </a>
            </li>
            <li>
              <a
                onClick={()=>handlePrint(divRef)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="print"
              >
                <img src={printer} alt="img" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="card" id="filter_inputs">
        <div className="card-body pb-0">
          <div className="row">
            <div className="col-lg-4 col-sm-4 col-4">
              <div className="form-group">
                <label htmlFor="search">Search</label>
                <input
                  type="text"
                  value={query}
                  onChange={onSearchChange}
                  className="form-control"
                  placeholder="Search Anything..."
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-4">
              <div className="form-group">
                <label htmlFor="from-date">From Date</label>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-4">
              <div className="form-group">
                <label htmlFor="to-date">To Date</label>
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-2 col-sm-3 col-2">
              <div className="form-group">
                <a className="btn btn-filters ms-auto" onClick={handleDateFilter}>
                  <img src={searchIcon} alt="img" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
