import React from 'react'
import dash1 from "../../../assets/img/icons/dash1.svg";
import dash2 from "../../../assets/img/icons/dash2.svg";
import dash4 from "../../../assets/img/icons/dash4.svg";
import { TAgroOrder } from '../../../redux/types/agro_order.type';
import { TAgriculture } from '../../../redux/types/agriculture.type';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { formatPrice } from '../../utils/format_price';

interface IWelcomeDisplay{
  totalAmount: number;
  totalConfirmed: number;
  reservations: TAgroOrder[];
  agroList: TAgriculture[];
  customers: TAgroOrder[];
  confirmedReservations: TAgroOrder[],
  cancelled: TAgroOrder[]
}

const WelcomeDisplay: React.FC<IWelcomeDisplay> = ({totalAmount, reservations, agroList, customers, confirmedReservations, totalConfirmed, cancelled}) => {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div className='container mt-4'>
      <div className="row">
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash1} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  <span className="counters" data-count={reservations.length}>
                    {reservations.length}
                  </span>
                </h5>
                <h6>Pending Sales</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget dash1">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash2} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5> 
                  <span className="counters" data-count={totalAmount}>
                   {formatPrice({currency: user?.company_currency.currency.currency_code, price: totalAmount})}
                  </span>
                </h5>
                <h6>Total Pending Sales</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget dash3">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash4} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  <span className="counters" data-count={totalConfirmed.toLocaleString()}>
                  {formatPrice({currency: user?.company_currency.currency.currency_code, price: totalConfirmed})} 
                  </span>
                </h5>
                <h6>Total Sale Amount</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count">
              <div className="dash-counts">
                <h4>{agroList.length}</h4>
                <h5>Agro Products</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-tractor"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das2">
              <div className="dash-counts">
                <h4>{customers.length}</h4>
                <h5>Customers</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-users"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>{confirmedReservations.length}</h4>
                <h5>Total Confirmed Sales</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-check"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das4">
              <div className="dash-counts">
                <h4>{cancelled.length}</h4>
                <h5>Total Cancelled Sales</h5>
              </div>
              <div className="dash-imgs">
                <i className="fa fa-times"></i>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default WelcomeDisplay
