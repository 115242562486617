import React from "react";
import MessageBox from "./message_box";
import moment from "moment";
import { IUserMessages } from "./data/user_message.interface";
import { mockUserMessages } from "./data/mockAPI";

const Chat: React.FC = () => {
  const [users, setUsers] = React.useState<IUserMessages[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<IUserMessages | null>(null);

  // Load users and their messages
  React.useEffect(() => {
    setUsers(mockUserMessages);
  }, []);

  // Handle user click
  const handleUserClick = (user: IUserMessages) => {
    setSelectedUser(user);
  };

  return (
    <div className="content">
      <div className="col-lg-12">
        <div className="row chat-window">
          <div className="col-lg-5 col-xl-4 chat-cont-left">
            <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
              <div className="card-body contacts_body chat-users-list chat-scroll">
                {users.map((user) => (
                  <a
                    key={user.userId}
                    href="javascript:void(0);"
                    className={`media d-flex ${selectedUser?.userId === user.userId ? 'active' : ''}`}
                    onClick={() => handleUserClick(user)}
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={user.user.image}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">{user.user.name}</div>
                        <div className="user-last-chat">
                          {user.lastMessage?.text}
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">
                          {moment(user.lastMessage?.timestamp).fromNow()}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {selectedUser && (
            <MessageBox
              messages={selectedUser.messages}
              selectedUser={selectedUser.user}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;