import React, { useRef } from "react";
import Filters from "../../widgets/filters";
import searchIcon from "../../../../assets/img/icons/search-whites.svg";
import { useGetAgricultureListQuery } from "../../../../redux/slices/agriculture_slice";
import { TAgriculture } from "../../../../redux/types/agriculture.type";
import AddAgriculture from "./add_agriculture";
import moment from "moment";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import LoadingSpinner from "../../../../components/widgets/spinner";
import Pagination from "../../../pagination/pagination_items";
import { ErrorWidget } from "../../../utils/error_widget";
import { useGetCompaniesListQuery } from "../../../../redux/slices/authApiSlice";
import { User } from "../../../../redux/types/user";

const AgricultureList: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const [supplier, setSupplier] = React.useState("")

  const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
  
    // Handler for date filter change
    const onDateFilter = (fromDate: string, toDate: string) => {
      setFromDate(fromDate);
      setToDate(toDate);
      // Call your API or perform filtering here
      console.log("Filtering from:", fromDate, "to:", toDate);
    };

  const onChangeSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSupplier(newValue);
  }

  const [dateRange, setDateRange] = React.useState<string>('today');
  const [status, setStatus] = React.useState<string | null>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDateRange(event.target.value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value === 'all' ? null : event.target.value);
  };
  const {
    data: usersList,
    isLoading: loadingUsers,
    error: usersError,
  }: User | any = useGetCompaniesListQuery({ user_type: "AGRO" });
  const {
    data: agricultureData,
    isLoading,
    refetch,
    error,
  }: TAgriculture | any = useGetAgricultureListQuery({
    supplier: supplier, 
    status: "ACTIVE", 
    searchQuery: query, 
    page: currentPage,
    fromDate: fromDate,
    toDate:   toDate,
  });

  if (isLoading || loadingUsers) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Agriculture List</h4>
            <h6>Manage your Agricultures</h6>
          </div>
          <div className="page-btn">
            {user?.user_type === "ADMIN" || user?.user_type !== "AGRO" ? (
             <span></span>
            ) : (
              <AddAgriculture fetchData={refetch} />
            )}
          </div>
        </div>

        <div className="card">
          {error || usersError ? (
           <ErrorWidget error={error | usersError}/>
          ) : 
            <div className="card-body">
              <Filters divRef={divRef} showSearch={false} query={query} onSearchChange={onChange} onDateFilter={onDateFilter} />

              <div className="card" id="">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <input type="text"
                         value={query}
                         name="search_agro_list"
                         id="search_agro_list"
                        onChange={onChange} placeholder="Search Products" />
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select onChange={onChangeSupplier} name="supplier__id" className="select form-control">
                          <option value="">Choose Supplier</option>
                          {usersList.map((item: User, index: number)=>{
                            return <option key={index} value={item.id}>{item.company_name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg col-sm-6 col-12">
                      <div className="form-group">
                        <select className="select form-control">
                          <option>Choose Status</option>
                          <option>Inprogress</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1 col-sm-6 col-12">
                      <div className="form-group">
                        <a className="btn btn-filters ms-auto">
                          <img
                            src={searchIcon}
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {agricultureData.results.length === 0 ? (
            <center className="p-3">
              
              <h5>No Data Found</h5>
            </center>
          ) : (
              <>
              <div ref={divRef} className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Supplier Name</th>
                      <th>Product</th>
                      <th>Sales</th>
                      <th>Reference</th>
                      <th>Product Price</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agricultureData.results.map(
                      (item: TAgriculture, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-bolds text-capitalize">
                              {item.supplier.first_name}{" "}
                              {item.supplier.last_name}
                            </td>
                            <td className="text-bolds text-capitalize">
                              {item.productName.substring(0, 12)}...
                            </td>
                            <td>{item.agri_order_product.length}</td>
                            <td>{item.product_unique_id}</td>
                            <td>{item.productPrice.toLocaleString()}</td>
                            <td>{item.quantity}</td>
                            <td>
                              <span className="badges bg-lightgreen">
                                {item.status}
                              </span>
                            </td>
                            <td>
                              {moment(item.created_at).format("DD MMMM YYYY")}
                            </td>
                            <td>
                              {user?.user_type === "ADMIN" ? (
                                <span className="">
                                  <a className="me-3" href={`/zm/dashboard/agro_transactions/?agro_id=${item.id}`}>
                                    <i className="fa fa-arrow-right"></i>
                                  </a>
                                </span>
                              ) : (
                                <span>

                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                
              </div>
                <Pagination
                  currentPage={currentPage}
                  lastPage={agricultureData.total_pages}
                  maxLength={7}
                  setCurrentPage={setCurrentPage}
                />
              </>
              )}
              
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default AgricultureList;
