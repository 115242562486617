import React from "react";
import moment from "moment";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetMineralCustomerQuery } from "../../../../redux/slices/mineral_order_slice";
import { TMineralOrder } from "../../../../redux/types/mineral_order.type";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import { formatPrice } from "../../../utils/format_price";

const CustomerProfile: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const customer_id = queryParams.get("customer_id");
  const {
    data: reservations,
    isLoading,
    error,
  }: TMineralOrder | any = useGetMineralCustomerQuery({
    customer: customer_id,
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger mt-5" role="alert">
        <h4>Error: {error.status}</h4>
      </div>
    );
  }

  return (
    <>
        <div className="content">
        <BreadCrumbs
        title={"Customer Details"}
        subTitle={"View/Manage Customer Details"}
      />
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="my-3 text-capitalize">
                    {reservations[0].customer.first_name}{" "}
                    {reservations[0].customer.last_name}
                  </h5>
                  {/* <p className="text-muted mb-1">Full Stack Developer</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0 text-capitalize">
                        {reservations[0].customer.first_name}{" "}
                        {reservations[0].customer.last_name}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {reservations[0].customer.email}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{reservations[0].customer.phoneNumber}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                      {reservations[0]?.customer_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-4 mb-md-0">
              <div className="card-body table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>Product</th>
                      <th>Supplier</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Quantity</th>
                      <th>Paid</th>
                      <th>Status</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    
                    {reservations.map((item: TMineralOrder, index: number)=>{
                      return <tr key={index}>
                      <td>
                        {index+1}
                      </td>
                      <td><span className="text-capitalize">{item.product.productName.substring(0, 10)}</span></td>
                      <td className="text-bolds text-capitalize">{item.supplier.first_name} {item.supplier.last_name}</td>
                      <td>{item.mineral_order_id}</td>
                      <td>{moment(item.created_at).format("Do MMM, YYYY")}</td>
                      <td>{item.quantity}/{item.unit}</td>
                      <td>{formatPrice({currency: item.supplier.company_currency.currency.currency_code, price: item.productPrice})}</td>
                      <td>
                        <span className="badges bg-lightgreen text-capitalize">{item.status}</span>
                      </td>
                      
                      
                    </tr>
                    })}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default CustomerProfile;
