import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import emailIcon from "../assets/img/icons/mail.svg";
import logo from "../assets/img/logo.jpg"
import { toast } from "react-toastify";

const ResetPassword = (props) => {
  const [requestSent, setRequestSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
          body,
          config
        )
        .then((res) => {
          setRequestSent(true);
          setLoading(false);
          toast.success("An E-mail is on its way, Check Your E-mail to reset the password");
          console.log(res);
          // setTimeout(() => {
          //   window.location.href = "/";
          // }, 5000);
        })
        .catch((e) => {
          toast.error("Error: " + e.message);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  // if (requestSent) {
  //     return <Redirect to='/' />
  // }

  return (
    <>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="login-wrapper">
              <div className="login-content">
                <div className="login-userset">
                  <center>
                    <div className="login-logo">
                      <img style={{borderRadius: '50%'}} src={logo} alt="img" />
                    </div>
                  </center>
                  <div className="login-userheading">
                    <h3>Forgot Password</h3>
                    <h4>Enter an Email associated with your account to reset password</h4>
                  </div>

                  <form onSubmit={onSubmit}>
                    <div className="form-login">
                      <label>Email</label>
                      <div className="form-addons">
                        <input
                          type="email"
                          onChange={(e) => onChange(e)}
                          name="email"
                          value={email}
                          required
                          disabled={loading}
                          placeholder="Enter your email address"
                        />
                        <img src={emailIcon} alt="img" />
                      </div>
                    </div>
                    <div className="form-login">
                      <button
                        type="submit"
                        className="btn btn-login"
                        disabled={loading}>
                        {loading && (
                          <div className="spinner-border spinner-border-sm text-danger"></div>
                        )}{" "}
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="login-img shadow">
                <img src={require("../assets/img/login.jpg")} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null)(ResetPassword);
