import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import httpProductServices from "../../services/http.product.services";
import { toast } from "react-toastify";
import { Utilities } from "../../../redux/types/utilities.type";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { DragAndDrop } from "../../widgets/DragNdrop";
import { FormInput } from "../../../components/molecules/form_input";
import { SelectInput } from "../../../components/molecules/selectInput";
import { FormTextarea } from "../../../components/molecules/form_textarea";

interface DataFormFields {
  propertyName: string;
  propertyLabel: string;
  propertyType: string;
  propertyPrice: string;
  areaLocation: string;
  bedrooms: string;
  bathrooms: string;
  yearBuilt: string;
  squareFeet: string;
  garages: string;
  propertyAddress: string;
  propertyCity: string;
  propertyCountry: string;
  zipCode: string;
  propertyDescription: string;
  contactName: string;
  contactEmail: string;
  phoneNumber: string;
}

const HouseForm: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [files, setFiles] = useState<string[]>([]);
  const [submiting, setSubmiting] = useState(false);
  const [utilities, setUtilities] = useState<Utilities[]>([]);
  const [amenities, setAmenities] = useState<Utilities[]>([]);
  const [outDoorDetails, setOutDoorDetails] = useState<Utilities[]>([]);
  const [interialDetails, setInterialDetails] = useState<Utilities[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data: any): Promise<void> => {
    const uid: any = user?.id;
    let form_data = new FormData();
    files.forEach((image, index) => {
      form_data.append(`property_image`, image);
    });
    form_data.append("user", uid);
    form_data.append("propertyPrice", data.propertyPrice);
    form_data.append("propertyDescription", data.propertyDescription);
    form_data.append("contactName", data.contactName);
    form_data.append("contactEmail", data.contactEmail);
    form_data.append("phoneNumber", data.phoneNumber);
    form_data.append("propertyName", data.propertyName);
    form_data.append("propertyLabel", data.propertyLabel);
    form_data.append("propertyLocation", data.propertyAddress);
    form_data.append("propertyType", data.propertyType);
    form_data.append("yearBuilt", data.yearBuilt);
    form_data.append("bedrooms", data.bedrooms);
    form_data.append("bathrooms", data.bathrooms);
    form_data.append("garages", data.garages);
    form_data.append("propertyCity", data.propertyCity);
    form_data.append("propertyCountry", data.propertyCountry);
    form_data.append("zipCode", data.zipCode);
    form_data.append("squareFeet", data.squareFeet);
    form_data.append("propertyAddress", data.propertyAddress);
    form_data.append("propertyAmenities", JSON.stringify([...amenities]));
    form_data.append("propertyUtilities", JSON.stringify([...utilities]));
    form_data.append("propertyOutdoorDetails", JSON.stringify([...outDoorDetails]));
    form_data.append("propertyInteriorDetails", JSON.stringify([...interialDetails]));
    form_data.append("image", files[0]);

    if (files.length > 4) {
      setSubmiting(true);
      httpProductServices
        .createProperty(form_data)
        .then((response) => {
          if (response.status === 201) {
            setSubmiting(false);
            toast.success("Property Added Successfully");
          }
        })
        .catch((error: any) => {
          setSubmiting(false);
          toast.error(error.message);
        });
    } else {
      toast.error("Please select more than 5 images");
    }
  };

  return (
    <section className="gray pt-0 pb-5">
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="dashboard-body">
          <div className="dashboard-wraper">
            <h3 className="mt-3 mb-3 text-center">House Listing</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="submit-page">
                    {/* Basic Information */}
                    <div className="frm_submit_block">
                      <h3>Basic Information</h3>
                      <div className="frm_submit_wrap">
                        <div className="row">
                          <div className="col-md-3">
                            <FormInput
                              id="propertyName"
                              type="text"
                              name="propertyName"
                              disabled={submiting}
                              label="Property Title"
                              placeholder="Property Title"
                              register={register}
                              rules={{ required: "Property Name is required!" }}
                              errors={errors}
                            />
                          </div>
                          <div className="col-md-3">
                            <SelectInput
                              id="propertyLabel"
                              name="propertyLabel"
                              disabled={submiting}
                              label="Property Label"
                              register={register}
                              rules={{ required: "Property Label is required!" }}
                              errors={errors}
                            >
                              <option value="">Select Property Label</option>
                              <option value="rent">For Rent</option>
                              <option value="sale">For Sale</option>
                            </SelectInput>
                          </div>

                        <div className="col-md-3">
                        <SelectInput
                          id="propertyType"
                          name="propertyType"
                          disabled={submiting}
                          label="Property Type"
                          register={register}
                          rules={{ required: "Property Type is required!" }}
                          errors={errors}
                        >
                          <option value="">Select Property Type</option>
                          <option value="houses">Houses</option>
                          <option value="apartments">Apartment</option>
                          <option value="villas">Villas</option>
                          <option value="commercials">Commercial</option>
                          <option value="offices">Offices</option>
                        </SelectInput>
                        </div>
                          <div className="col-md-3">
                          <FormInput
                            id="propertyPrice"
                            type="number"
                            name="propertyPrice"
                            disabled={submiting}
                            label="Price"
                            placeholder="Property Price"
                            register={register}
                            rules={{ required: "Property Price is required!" }}
                            errors={errors}
                          />
                          </div>
                          {/* Select Input for Bedrooms */}
                          <div className="col-md-3">
                          <SelectInput
                            id="bedrooms"
                            name="bedrooms"
                            disabled={submiting}
                            label="Bedrooms"
                            register={register}
                            rules={{ required: "Bedrooms is required!" }}
                            errors={errors}
                          >
                            <option value="">Select Number of Bedrooms</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </SelectInput>
                          </div>

                          {/* Select Input for Bathrooms */}
                          <div className="col-md-3">
                          <SelectInput
                            id="bathrooms"
                            name="bathrooms"
                            disabled={submiting}
                            label="Bathrooms"
                            register={register}
                            rules={{ required: "Bathrooms is required!" }}
                            errors={errors}
                          >
                            <option value="">Select Number of Bathrooms</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </SelectInput>
                          </div>
                          <div className="col-md-3">
                          <FormInput
                            id="yearBuilt"
                            type="number"
                            name="yearBuilt"
                            disabled={submiting}
                            label="Year Built"
                            placeholder="Year Built"
                            register={register}
                            rules={{ required: "Year Built is required!" }}
                            errors={errors}
                          />
                          </div>
                          <div className="col-md-3">
                          <FormInput
                            id="squareFeet"
                            type="number"
                            name="squareFeet"
                            disabled={submiting}
                            label="Square Feet"
                            placeholder="Square Feet"
                            register={register}
                            rules={{ required: "Square Feet is required!" }}
                            errors={errors}
                          />
                          </div>
                          <div className="col-md-3">
                          <FormInput
                            id="garages"
                            type="number"
                            name="garages"
                            disabled={submiting}
                            label="Garages"
                            placeholder="Number of Garages"
                            register={register}
                            rules={{ required: "Garages is required!" }}
                            errors={errors}
                          />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Gallery */}
                    <div className="frm_submit_block rounded" style={{ backgroundColor: "lightgray" }}>
                      <h3 className="m-2">Gallery</h3>
                      <div className="frm_submit_wrap">
                        <DragAndDrop
                          heading={"Image Gallery"}
                          onChange={(e: any) => {
                            if (e.target.files && e.target.files[0]) {
                              for (let i = 0; i < e.target.files["length"]; i++) {
                                setFiles((prevState: any) => [
                                  ...prevState,
                                  e.target.files[i],
                                ]);
                              }
                            }
                          }}
                          setFiles={setFiles}
                          files={files}
                        />
                      </div>
                    </div>

                    {/* Location */}
                    <div className="frm_submit_block">
                      <h3>Location</h3>
                      <div className="frm_submit_wrap">
                        <div className="row">
                          <div className="col-md-3">
                          <FormInput
                            id="propertyAddress"
                            type="text"
                            name="propertyAddress"
                            disabled={submiting}
                            label="Address"
                            placeholder="Address"
                            register={register}
                            rules={{ required: "Address is required!" }}
                            errors={errors}
                          />
                          </div>
                          <div className="col-md-3">
                          <FormInput
                            id="propertyCity"
                            type="text"
                            name="propertyCity"
                            disabled={submiting}
                            label="City"
                            placeholder="City"
                            register={register}
                            rules={{ required: "City is required!" }}
                            errors={errors}
                          />
                          </div>
                          <div className="col-md-3">
                          <FormInput
                            id="propertyCountry"
                            type="text"
                            name="propertyCountry"
                            disabled={submiting}
                            label="State"
                            placeholder="State"
                            register={register}
                            rules={{ required: "State is required!" }}
                            errors={errors}
                          />
                          </div>
                          <div className="col-md-3">
                          <FormInput
                            id="zipCode"
                            type="text"
                            name="zipCode"
                            disabled={submiting}
                            label="Zip Code"
                            placeholder="Zip Code"
                            register={register}
                            rules={{ required: "Zip Code is required!" }}
                            errors={errors}
                          />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Detailed Information */}
                    <div className="frm_submit_block">
                      <h3>Detailed Information</h3>
                      <div className="frm_submit_wrap">
                        {/* Utilities */}
                        <div className="detailed-information">
                          <h3>Utilities</h3>
                          <div className="float-right pull-right">
                            <button
                              type="button"
                              onClick={() => setUtilities([...utilities, { name: "" }])}
                              className="btn btn-sm btn-primary"
                            >
                              Add More <i className="fa fa-circle-plus"></i>
                            </button>
                          </div>
                          <div className="row">
                            {utilities.map((value, index) => (
                              <div key={index} className="form-group col-md-4">
                                <label>Item {index + 1}</label>
                                <input
                                  type="text"
                                  disabled={submiting}
                                  value={value.name}
                                  onChange={(e) => {
                                    const newUtilities = [...utilities];
                                    newUtilities[index].name = e.target.value;
                                    setUtilities(newUtilities);
                                  }}
                                  className="form-control"
                                />
                                <div className="float-right">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const newUtilities = [...utilities];
                                      newUtilities.splice(index, 1);
                                      setUtilities(newUtilities);
                                    }}
                                    className="btn btn-sm btn-danger"
                                  >
                                    Remove <i className="fa fa-minus-plus"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Interior Details */}
                        <div className="detailed-information">
                          <h3>Interior Details</h3>
                          <div className="float-right pull-right">
                            <button
                              type="button"
                              onClick={() => setInterialDetails([...interialDetails, { name: "" }])}
                              className="btn btn-sm btn-primary"
                            >
                              Add More <i className="fa fa-circle-plus"></i>
                            </button>
                          </div>
                          <div className="row">
                            {interialDetails.map((value, index) => (
                              <div key={index} className="form-group col-md-4">
                                <label>Item {index + 1}</label>
                                <input
                                  type="text"
                                  disabled={submiting}
                                  value={value.name}
                                  onChange={(e) => {
                                    const newInteriorDetails = [...interialDetails];
                                    newInteriorDetails[index].name = e.target.value;
                                    setInterialDetails(newInteriorDetails);
                                  }}
                                  className="form-control"
                                />
                                <div className="float-right">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const newInteriorDetails = [...interialDetails];
                                      newInteriorDetails.splice(index, 1);
                                      setInterialDetails(newInteriorDetails);
                                    }}
                                    className="btn btn-sm btn-danger"
                                  >
                                    Remove <i className="fa fa-minus-plus"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Outdoor Details */}
                        <div className="detailed-information">
                          <h3>Outdoor Details</h3>
                          <div className="float-right pull-right">
                            <button
                              type="button"
                              onClick={() => setOutDoorDetails([...outDoorDetails, { name: "" }])}
                              className="btn btn-sm btn-primary"
                            >
                              Add More <i className="fa fa-circle-plus"></i>
                            </button>
                          </div>
                          <div className="row">
                            {outDoorDetails.map((value, index) => (
                              <div key={index} className="form-group col-md-4">
                                <label>Item {index + 1}</label>
                                <input
                                  type="text"
                                  placeholder="Out Door Details eg. swimming pool, parking, etc"
                                  disabled={submiting}
                                  value={value.name}
                                  onChange={(e) => {
                                    const newOutdoorDetails = [...outDoorDetails];
                                    newOutdoorDetails[index].name = e.target.value;
                                    setOutDoorDetails(newOutdoorDetails);
                                  }}
                                  className="form-control"
                                />
                                <div className="float-right">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const newOutdoorDetails = [...outDoorDetails];
                                      newOutdoorDetails.splice(index, 1);
                                      setOutDoorDetails(newOutdoorDetails);
                                    }}
                                    className="btn btn-sm btn-danger"
                                  >
                                    Remove <i className="fa fa-minus-plus"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Amenities */}
                        <div className="detailed-information">
                          <h3>Amenities</h3>
                          <div className="float-right pull-right">
                            <button
                              type="button"
                              onClick={() => setAmenities([...amenities, { name: "" }])}
                              className="btn btn-sm btn-primary"
                            >
                              Add More <i className="fa fa-circle-plus"></i>
                            </button>
                          </div>
                          <div className="row">
                            {amenities.map((value, index) => (
                              <div key={index} className="form-group col-md-4">
                                <label>Item {index + 1}</label>
                                <input
                                  type="text"
                                  placeholder="Property Amenities eg. bike racks, lobby reception, fitness facility, etc"
                                  disabled={submiting}
                                  value={value.name}
                                  onChange={(e) => {
                                    const newAmenities = [...amenities];
                                    newAmenities[index].name = e.target.value;
                                    setAmenities(newAmenities);
                                  }}
                                  className="form-control"
                                />
                                <div className="float-right">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      const newAmenities = [...amenities];
                                      newAmenities.splice(index, 1);
                                      setAmenities(newAmenities);
                                    }}
                                    className="btn btn-sm btn-danger"
                                  >
                                    Remove <i className="fa fa-minus-plus"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Property Description */}
                        <div className="row">
                          <FormTextarea
                            id="propertyDescription"
                            name="propertyDescription"
                            disabled={submiting}
                            label="Property Description"
                            placeholder="Property Description"
                            register={register}
                            rules={{ required: "Property Description is required!" }}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <div className="frm_submit_block">
                      <h3>Contact Information</h3>
                      <div className="frm_submit_wrap">
                        <div className="row">
                          <div className="col-md-4">
                          <FormInput
                            id="contactName"
                            type="text"
                            name="contactName"
                            disabled={submiting}
                            label="Name"
                            placeholder="Contact Name"
                            register={register}
                            rules={{ required: "Contact Name is required!" }}
                            errors={errors}
                            defaultValue={`${user?.first_name} ${user?.last_name}`}
                          />
                          </div>
                          <div className="col-md-4">
                          <FormInput
                            id="contactEmail"
                            type="email"
                            name="contactEmail"
                            disabled={submiting}
                            label="Email"
                            placeholder="Contact Email"
                            register={register}
                            rules={{ required: "Contact Email is required!" }}
                            errors={errors}
                            defaultValue={user?.email}
                          />
                          </div>
                          <div className="col-md-4">
                          <FormInput
                            id="phoneNumber"
                            type="text"
                            name="phoneNumber"
                            disabled={submiting}
                            label="Phone"
                            placeholder="Contact Number"
                            register={register}
                            rules={{ required: "Phone Number is required!" }}
                            errors={errors}
                            defaultValue={user?.phoneNumber}
                          />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Consent Checkbox */}
                    <div className="form-group col-lg-12 col-md-12">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gdpr"
                        />
                        <label className="form-check-label" htmlFor="gdpr">
                          I consent to having this website store my submitted
                          information so they can respond to my inquiry.
                        </label>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div className="form-group col-lg-12 col-md-12 text-center">
                      <button
                        disabled={submiting || files.length < 3}
                        className="btn btn-primary"
                        type="submit"
                      >
                        {submiting ? (
                          <span>
                            <i className="fa fa-refresh fa-spin"></i> Please Wait ...
                          </span>
                        ) : (
                          <span>{files.length < 3 ? "Select at least 3 images": "Submit"}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
        </section>
  );
};

export default HouseForm;