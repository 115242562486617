import { toast } from "react-toastify";
import { useRegisterMutation } from "../redux/slices/authApiSlice";
import { useForm } from "react-hook-form";


export type DataFormFields = {
  first_name: string;
  last_name: string;
  email: string;
  company_email: string;
  nationality: string;
  company_name: string;
  phoneNumber: string;
  user_type: string;
  password: string;
  
};
type TProps = {
  setShow: (show: boolean) => void;
  fetchData: () => void;
  accountType: string;
  systems?: string[];
  currency: any;
};
const useRegister =({setShow, accountType, systems, currency, fetchData}: TProps)=> {
  const [registerAccount, { isLoading }] = useRegisterMutation();
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);

    var selectedCurrency = currency.value;

    registerAccount({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      company_email: data.company_email,
      nationality: data.nationality,
      company_name: data.company_name,
      phoneNumber: data.phoneNumber,
      user_type: accountType,
      currency: selectedCurrency,
      password: 'everyday_password',
      re_password: 'everyday_password',
      systems: systems,
    })
      .unwrap()
      .then((res) => {
        reset();
        fetchData();
        setShow(false);
        toast.success("User Accounted Created Successfully");
      })
      .catch((error) => {
        if (error.data["email"]) {
          toast.error(`${error.data["email"]}`);
        } else if (error.data["password"]) {
          toast.error(`${error.data["password"]}`);
        } else {
          toast.error("Error: " + error.data["detail"]);
        }
      });
  });


  return {
    isLoading,
    register,
    errors,
    onSubmit,
  };
}
export default useRegister;