import React from 'react';
import { useLocation } from 'react-router-dom';
import sales1 from "../../../assets/img/icons/sales1.svg";
import user1 from "../../../assets/img/icons/users1.svg";

interface SupportAgentLinksProps {
  assignedSystems: string[];
}

const SupportAgentLinks: React.FC<SupportAgentLinksProps> = ({ assignedSystems })=> {
  const location = useLocation();

  return (
    <>
    {/* Requests */}
    {assignedSystems.includes("DEALSECURE") && (
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={sales1} alt="img" />
            <span>Requests</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/requests" ? "active" : ""}`} href="/zm/dashboard/requests">View Requests</a>
            </li>
          </ul>
        </li>
      )}

      {/* Complaints */}
      {assignedSystems.includes("SUPPORTAGENT") && (
        <li className="submenu">
          <a href="javascript:void(0);">
            <img src={user1} alt="img" />
            <span>Complaints</span>
            <span className="menu-arrow"></span>
          </a>
          <ul>
            <li>
              <a className={`${location.pathname === "/zm/dashboard/complaints" ? "active" : ""}`} href="/zm/dashboard/complaints">Complaint List</a>
            </li>
          </ul>
        </li>
      )}
      {/* Requests */}
      <li className="submenu">
        <a href="javascript:void(0);">
          <img src={sales1} alt="img" />
          <span>Requests</span>
          <span className="menu-arrow"></span>
        </a>
        <ul>
          <li>
            <a className={`${location.pathname === "/zm/dashboard/requests" ? "active" : ""}`} href="/zm/dashboard/requests">View Requests</a>
          </li>
        </ul>
      </li>

      {/* People */}
      <li className="submenu">
        <a href="javascript:void(0);">
          <img src={user1} alt="img" />
          <span>Complaints</span>
          <span className="menu-arrow"></span>
        </a>
        <ul>
          <li>
            <a className={`${location.pathname === "/zm/dashboard/complaints" ? "active" : ""}`} href="/zm/dashboard/complaints">Complaint List</a>
          </li>
        </ul>
      </li>
    </>
  );
};

export default SupportAgentLinks;