import React from "react";
import { useGetApprovedRequestsQuery } from "../../../redux/slices/property/property_requests_slice";
import { RequestsModel } from "../../../models/requests_model";
import BreadCrumbs from "../../widgets/bread_crumbs";
import moment from "moment";
import { formatPrice } from "../../utils/format_price";

const BookMarks: React.FC = () => {
  const {
    data: requests,
    isLoading,
    isError,
    error,
  }: RequestsModel | any = useGetApprovedRequestsQuery();
  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  if (isError) {
    console.log(error);
    return (
      <div
        style={{ marginTop: "20%" }}
        className="alert alert-danger"
        role="alert"
      >
        {error.message}
      </div>
    );
  }
  return (
    <div className="content">
      <BreadCrumbs
        title={"Rented Properties"}
        subTitle={"Manage Rented properties"}
      />
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
              {requests.length === 0 ? (
                <center>
                  <div
                    style={{ width: "fit-content" }}
                    className="alert alert-info mt-5 text-center"
                    role="alert"
                  >
                    <h1>No Properties Found</h1>
                  </div>
                </center>
              ) : (
                <>
                <table className="table table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Property</th>

                            <th scope="col" className="m2_hide">
                              Tenant
                            </th>
                            <th scope="col" className="m2_hide">
                              Posted On
                            </th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <!-- tr block --> */}
                          {requests.map((item: RequestsModel, index: number) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="dash_prt_wrap">
                                    <div className="dash_prt_thumb">
                                      <img
                                        src={`${process.env.REACT_APP_API_URL}${item.property.image}`}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="dash_prt_caption">
                                      <h5 className="capitalize">
                                        {item.property.propertyName}
                                      </h5>
                                      <div className="prt_dashb_lot capitalize">
                                        {item.property.propertyAddress}
                                      </div>
                                      <div className="prt_dash_rate">
                                        <span>
                                          {`${formatPrice({currency: item.propertyOwner.company_currency.currency.currency_code, price: item.property.propertyPrice})}${item.property.propertyLabel === "rent" ? "/Month" : ""}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className="m2_hide">
                                  <div className="_leads_view">
                                    <h5 className="up">
                                    {item.user.first_name}{" "} {item.user.last_name}
                                    </h5>
                                  </div>
                                  
                                </td>
                                <td className="m2_hide">
                                  <div className="_leads_posted">
                                    <h5>
                                      {moment(item.created_at).format( "Do MMM YYYY - HH:MM a")}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>
                                      {moment(item.created_at).fromNow()}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="_leads_status text-capitalize">
                                    <span
                                      className={`text-capitalize ${item.status === "PENDING" ? "expire" : "active"}`}>
                                      {item.status}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  
                                    <div className="_leads_action">
                                      <a href={`/zm/accounts/realestate/rented/property_details?property=${item.id}`}>
                                        <i className="fas fa-edit"></i>
                                      </a>
                                    </div>
                                </td>
                                
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookMarks;
