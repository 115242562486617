import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./components/page404";
import * as dashboard from "./accounts/admin/index";
import * as auth from "./auth/index";
import * as carDashboard from "./accounts/cars_dashboard";
import * as mineral from "./accounts/mineral_dashboard";
import * as fuelDash from "./accounts/fuel_dashboard";
import * as agric from "./accounts/agric_dashboard"
import * as construction from "./accounts/construction_dashboard"
import * as investment from "./accounts/investment_dashboard"
import * as realestate from "./accounts/realestate_dashboard"
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UserRedirect from "./auth/redirectUser";
import DefaultLayout from "./auth/defaultLayout";
import ProtectedLayout from "./auth/protectedLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";
import CarMainLayout from "./accounts/cars_dashboard/layout/car_main_layout";
import MineralMainLayout from "./accounts/mineral_dashboard/layout/mineral_main_layout";
import AgricultureMainLayout from "./accounts/agric_dashboard/layout/agric_main_layout";
import InvestmentMainLayout from "./accounts/investment_dashboard/layout/investment_main_layout";
import LockScreen from "./accounts/realestate_dashboard/lockscreen";
import ChangeUserPassword from "./accounts/change-password/page";
import ConstructionMainLayout from "./accounts/construction_dashboard/layout/construction_main_layout";
import { useAppSelector } from "./hooks/redux-hooks";
import PrivacyPolicy from "./accounts/privacy_policy";
import TermsConditions from "./accounts/terms_conditions";
import FuelMainLayout from "./accounts/fuel_dashboard/layout/fuel_main_layout";
import CustomerPage from "./accounts/customer/customer";

const App = () => {
  const user = useAppSelector((state) => state.auth.user);
  React.useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
      offset: 80,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/zm/lock-screen" element={<LockScreen />} />
          <Route path="/zm/welcome" element={<CustomerPage />} />
          {/* AUTH URSL */}
          <Route path="/zm/authenticating" element={<UserRedirect />} />
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<auth.LoginPage />} />
            <Route path="/reset-password/" element={<auth.ResetPassword />}/>
            <Route path="/password/reset/confirm/:uid/:token" element={<auth.ResetPasswordConfirm />} />
          </Route>
          {/* DASHBOARD ROUTES STARTS */}
          <Route element={<ProtectedLayout />}>
            <Route path="/zm/dashboard" element={<dashboard.AdminLayout />}>
              <Route index element={<dashboard.Dashboard />} />
              <Route path="/zm/dashboard/change-password" element={<ChangeUserPassword />} />
              <Route path="/zm/dashboard/customer_orders" element={<dashboard.CustomerOrders />} />
              <Route path="/zm/dashboard/order_details" element={<dashboard.OrderDetails />} />
              <Route path="/zm/dashboard/system_users" element={<dashboard.SystemUsers />} />
              <Route path="/zm/dashboard/expenselist" element={<dashboard.ExpensesList />} />
              <Route path="/zm/dashboard/createexpense" element={<dashboard.CreateExpense />} />
              <Route path="/zm/dashboard/transferlist" element={<dashboard.TransferList />} />
              <Route path="/zm/dashboard/addtransfer" element={<dashboard.CreateTransfer />} />
              <Route path="/zm/dashboard/customerlist" element={<dashboard.CustomerList />} />
              <Route path="/zm/dashboard/complaints" element={<dashboard.Chat />} />
              <Route path="/zm/dashboard/payment_settings" element={<dashboard.PaymentSettings />} />
              <Route path="/zm/dashboard/profile/" element={<dashboard.Profile />} />
              <Route path="/zm/dashboard/construction_list" element={<dashboard.ConstructionList />} />
              <Route path="/zm/dashboard/construction_deals" element={<dashboard.ConstructionDeals />} />
              <Route path="/zm/dashboard/agriculture_list/" element={<dashboard.AgricultureList />} />
              <Route path="/zm/dashboard/agro_transactions" element={<dashboard.AgroTransactions />} />
              <Route path="/zm/dashboard/agriculture_orders" element={<dashboard.AgricultureOrdersList />} />
              <Route path="/zm/dashboard/construction_orders" element={<dashboard.ConstructionCustomersList />} />
              <Route path="/zm/dashboard/mineral_trading" element={<dashboard.MineralPage />} />
              <Route path="/zm/dashboard/pending_mineral_requests" element={<dashboard.PendingMineralRequestPage />} />
              <Route path="/zm/dashboard/mineral_transactions" element={<dashboard.MineralTransactions />} />
              <Route path="/zm/dashboard/mineral_categories" element={<dashboard.MineralCategories />} />
              <Route path="/zm/dashboard/mineral_sales" element={<dashboard.MineralOrdersList />} />
              <Route path="/zm/dashboard/car_organizations" element={<dashboard.CarRentalOrganization />} />
              <Route path="/zm/dashboard/mineral_companies" element={<dashboard.MineralCOmpaines />} />
              <Route path="/zm/dashboard/mining_companies" element={<dashboard.MiningCompanies />} />
              <Route path="/zm/dashboard/construction_companies" element={<dashboard.ConstructionCompanies />} />
              <Route path="/zm/dashboard/agro_companies" element={<dashboard.AgroCompanies />} />
              <Route path="/zm/dashboard/investment_companies" element={<dashboard.InvestmentCompanies />} />
              <Route path="/zm/dashboard/real_estate_companies" element={<dashboard.RealEstateCompanies />} />
              <Route path="/zm/dashboard/car_brands" element={<dashboard.CarBrandList />} />
              <Route path="/zm/dashboard/car_list" element={<dashboard.CarList />} />
              <Route path="/zm/dashboard/car_details" element={<dashboard.AdminCarDetails />} />
              <Route path="/zm/dashboard/deal_secure_details" element={<dashboard.EscrowDeals />} />
              <Route path="/zm/dashboard/escrow_list" element={<dashboard.EscrowList />} />
              <Route path="/zm/dashboard/construction_mining_transaction" element={<dashboard.MiningConstructionOrdersPage />} />
              <Route path="/zm/dashboard/mining_list" element={<dashboard.MiningList />} />
              <Route path="/zm/dashboard/currency_settings" element={<dashboard.CurrencySettings />} />
              <Route path="/zm/dashboard/investment_list" element={<dashboard.InvestmentList />} />
              <Route path="/zm/dashboard/project_details" element={<dashboard.InvestmentDetails />} />
              <Route path="/zm/dashboard/agro_company_details" element={<dashboard.AgroCompanyDetails />} />
              <Route path="/zm/dashboard/car_company_details" element={<dashboard.CarCompanyDetails />} />
              <Route path="/zm/dashboard/mineral_company_details" element={<dashboard.MineralCompanyDetails />} />
              <Route path="/zm/dashboard/investment_company_details" element={<dashboard.InvestmentCompanyDetails />} />
              <Route path="/zm/dashboard/real_estate_company_details" element={<dashboard.RealEstateCompanyDetails />} />
              <Route path="/zm/dashboard/real_estate_list" element={<dashboard.RealEstateList />} />
              <Route path="/zm/dashboard/real_estate_deals" element={<dashboard.RealDeals />} />
              <Route path="/zm/dashboard/fuel_companies" element={<dashboard.FuelCompanies />} />
              <Route path="/zm/dashboard/car_transactions" element={<dashboard.AdminCarTransaction />} />
              <Route path="/zm/dashboard/fuel_list" element={<dashboard.FuelList />} />
              <Route path="/zm/dashboard/fuel_details" element={<dashboard.FuelDeals />} />
              <Route path="/zm/dashboard/pending_fuel_list" element={<dashboard.PendingFuelList />} />
            </Route>
          </Route>
          {/* DASHBOARD ROUTES ENDS */}
          {/* CAR HIRE DASHBOARD ROUTES */}
          <Route path="/zm/car_dashboard" element={<CarMainLayout />}>
            <Route index element={<carDashboard.CarHomePage />} />
            <Route path="/zm/car_dashboard/change-password" element={<ChangeUserPassword />} />
            <Route path="/zm/car_dashboard/car_rentals_list" element={<carDashboard.CarRentalsList />}/>
            <Route path="/zm/car_dashboard/car_deals_list" element={<carDashboard.CarDealsList />}/>
            <Route path="/zm/car_dashboard/offline_records" element={<carDashboard.CarOfflineRentals />}/>
            <Route path="/zm/car_dashboard/edit_car/" element={<carDashboard.EditCar />}/>
            <Route path="/zm/car_dashboard/car_details/" element={<carDashboard.CarDetails />}/>
            <Route path="/zm/car_dashboard/customer_bookings" element={<carDashboard.CarReservation />}/>
            <Route path="/zm/car_dashboard/customers" element={<carDashboard.Customers />}/>
            <Route path="/zm/car_dashboard/car_viewing_requests" element={<carDashboard.CarViewRequestsList />}/>
            <Route path="/zm/car_dashboard/rental_details" element={<carDashboard.RentalDetails />}/>
            <Route path="/zm/car_dashboard/car_view_details" element={<carDashboard.CarViewRequestDetails />}/>
            <Route path="/zm/car_dashboard/customer_profile/:token" element={<carDashboard.CustomerProfile />}/>
            <Route path="/zm/car_dashboard/profile/" element={<carDashboard.CompanyProfile />}/>
            <Route path="/zm/car_dashboard/privacy_policy/:token/:uid" element={<PrivacyPolicy />}/>
            <Route path="/zm/car_dashboard/terms_of_service/:token/:uid" element={<TermsConditions />}/>
          </Route>
          {/* MINERAL ROUTES */}
          <Route path="/zm/mineral" element={<MineralMainLayout />}>
            <Route index element={<mineral.MineralHomePage />} />
            <Route path="/zm/mineral/change-password" element={<ChangeUserPassword />} />
            <Route path="/zm/mineral/customers" element={<mineral.Customers />}/>
            <Route path="/zm/mineral/minerals_list" element={<mineral.MineralPage />}/>
            <Route path="/zm/mineral/customer_profile/" element={<mineral.CustomerProfile />}/>
            <Route path="/zm/mineral/profile/" element={<mineral.CompanyProfile />}/>
            <Route path="/zm/mineral/privacy_policy/:token/:uid" element={<PrivacyPolicy />}/>
            <Route path="/zm/mineral/terms_of_service/:token/:uid" element={<TermsConditions />}/>
            <Route path="/zm/mineral/mineral_categories" element={<dashboard.MineralCategories />} />
            <Route path="/zm/mineral/pending_orders_list" element={<mineral.PendingMineralOrdersList />} />
            <Route path="/zm/mineral/confirmed_orders_list" element={<mineral.ConfirmedMineralOrdersList />} />
            <Route path="/zm/mineral/cancelled_orders_list" element={<mineral.CancelledMineralOrdersList />} />
            <Route path="/zm/mineral/order_details" element={<mineral.MineralDetails />} />
            <Route path="/zm/mineral/product_details" element={<mineral.ProductDetails />} />
            <Route path="/zm/mineral/chat" element={<mineral.Chat />} />
          </Route>
          {/* AGRICULTURE DASHBOARD ROUTES */}
          <Route path="/zm/agriculture" element={<AgricultureMainLayout />}>
            <Route index element={<agric.AgroDashboardHomePage />} />
            <Route path="/zm/agriculture/change-password" element={<ChangeUserPassword />} />
            <Route path="/zm/agriculture/customers" element={<agric.Customers />}/>
            <Route path="/zm/agriculture/agriculture_list" element={<agric.AgroPage />}/>
            <Route path="/zm/agriculture/customer_profile/" element={<agric.CustomerProfile />}/>
            <Route path="/zm/agriculture/profile/" element={<agric.CompanyProfile />}/>
            <Route path="/zm/agriculture/privacy_policy/:token/:uid" element={<PrivacyPolicy />}/>
            <Route path="/zm/agriculture/terms_of_service/:token/:uid" element={<TermsConditions />}/>
            <Route path="/zm/agriculture/pending_orders_list" element={<agric.PendingAgroOrdersList />} />
            <Route path="/zm/agriculture/confirmed_orders_list" element={<agric.ConfirmedAgroOrdersList />} />
            <Route path="/zm/agriculture/cancelled_orders_list" element={<agric.CancelledAgroOrdersList />} />
            <Route path="/zm/agriculture/order_details" element={<agric.AgroDetails />} />
            <Route path="/zm/agriculture/product_details" element={<agric.AgroProductDetails />} />
          </Route>

          {/* INVESTMENT DASHBOARD ROUTES */}
          <Route path="/zm/investment" element={<InvestmentMainLayout />}>
            <Route index element={<investment.InvestmentDashboardHomePage />} />
            <Route path="/zm/investment/change-password" element={<ChangeUserPassword />} />
            <Route path="/zm/investment/customers" element={<investment.Customers />}/>
            <Route path="/zm/investment/investment_list" element={<investment.InvestmetHomePage />}/>
            <Route path="/zm/investment/customer_profile/" element={<investment.CustomerProfile />}/>
            <Route path="/zm/investment/profile/" element={<investment.CompanyProfile />}/>
            <Route path="/zm/investment/privacy_policy/:token/:uid" element={<PrivacyPolicy />}/>
            <Route path="/zm/investment/terms_of_service/:token/:uid" element={<TermsConditions />}/>
            <Route path="/zm/investment/pending_projects_list" element={<investment.PendingProjectList />} />
            <Route path="/zm/investment/cancelled_projects_list" element={<investment.CancelledProjectsList />} />
            <Route path="/zm/investment/project_details" element={<investment.ProjectDetails />} />
          </Route>

          {/* REAL ESTATE DASHBOARD ROUTES */}
          <Route element={<ProtectedLayout />}>
            <Route
              path="/zm/accounts/realestate" element={<realestate.DashboardLayout />}>
              <Route index element={<realestate.Dashboard />} />
              <Route path="/zm/accounts/realestate/profile" element={<realestate.CompanyProfile />} />
              <Route path="/zm/accounts/realestate/properties" element={<realestate.Properties />} />
              <Route path="/zm/accounts/realestate/customers" element={<realestate.Customers />} />
              <Route path="/zm/accounts/realestate/messages" element={<realestate.Messages />} />
              <Route path="/zm/accounts/realestate/add-property" element={<realestate.AddProperty />} />
              <Route path="/zm/accounts/realestate/edit-property/" element={<realestate.EditProperty />} />
              <Route path="/zm/accounts/realestate/change-password" element={<ChangeUserPassword />} />
              <Route path="/zm/accounts/realestate/rented_properties" element={<realestate.BookMarks />}/>
              <Route path="/zm/accounts/realestate/rented/property_details" element={<realestate.RentDetails />}/>
              <Route path="/zm/accounts/realestate/requests/request_details" element={<realestate.RequestDetails />}/>
            </Route>
          </Route>
          {/* MINING & CONSTRUCTION DASHBOARD ROUTES */}
          <Route path={`/zm/dashboard/${user?.user_type}`} element={<ConstructionMainLayout />}>
            <Route index element={<construction.ConstructionDashboardHomePage />} />
            <Route path={`/zm/dashboard/${user?.user_type}/change-password`} element={<ChangeUserPassword />} />
            <Route path={`/zm/dashboard/${user?.user_type}/customers`} element={<construction.Customers />}/>
            <Route path={`/zm/dashboard/${user?.user_type}/construction_list`} element={<construction.ConstructionPage />}/>
            <Route path={`/zm/dashboard/${user?.user_type}/customer_profile/`} element={<construction.CustomerProfile />}/>
            <Route path={`/zm/dashboard/${user?.user_type}/profile/`} element={<construction.CompanyProfile />}/>
            <Route path={`/zm/dashboard/${user?.user_type}/privacy_policy/:token/:uid`} element={<PrivacyPolicy />}/>
            <Route path={`/zm/dashboard/${user?.user_type}/terms_of_service/:token/:uid`} element={<TermsConditions />}/>
            <Route path={`/zm/dashboard/${user?.user_type}/pending_orders_list`} element={<construction.PendingConstructionOrdersList />} />
            <Route path={`/zm/dashboard/${user?.user_type}/confirmed_orders_list`} element={<construction.ConfirmedConstructionOrdersList />} />
            <Route path={`/zm/dashboard/${user?.user_type}/cancelled_orders_list`} element={<construction.CancelledConstructionOrdersList />} />
            <Route path={`/zm/dashboard/${user?.user_type}/order_details`} element={<construction.ConstructionDetailsPage />} />
            <Route path={`/zm/dashboard/${user?.user_type}/product_details`} element={<construction.ProductDetails />} />
          </Route>
          {/* FUEL & LUBES DASHBOARD ROUTES */}
          <Route path={`/zm/account/${user?.user_type}/dashboard`} element={<FuelMainLayout />}>
            <Route index element={<fuelDash.FuelHomePage />} />
            <Route path={`/zm/account/${user?.user_type}/dashboard/change-password`} element={<ChangeUserPassword />} />
            <Route path={`/zm/account/${user?.user_type}/dashboard/customers`} element={<fuelDash.Customers />}/>
            <Route path={`/zm/account/${user?.user_type}/dashboard/product_list`} element={<fuelDash.FuelPage />}/>
            <Route path={`/zm/account/${user?.user_type}/dashboard/customer_profile/`} element={<fuelDash.CustomerProfile />}/>
            <Route path={`/zm/account/${user?.user_type}/dashboard/profile/`} element={<fuelDash.CompanyProfile />}/>
            <Route path={`/zm/account/${user?.user_type}/dashboard/privacy_policy/:token/:uid`} element={<PrivacyPolicy />}/>
            <Route path={`/zm/account/${user?.user_type}/dashboard/terms_of_service/:token/:uid`} element={<TermsConditions />}/>
            <Route path={`/zm/account/${user?.user_type}/dashboard/pending_orders_list`} element={<fuelDash.PendingFuelOrdersList />} />
            <Route path={`/zm/account/${user?.user_type}/dashboard/confirmed_orders_list`} element={<fuelDash.ConfirmedFuelOrdersList />} />
            <Route path={`/zm/account/${user?.user_type}/dashboard/cancelled_orders_list`} element={<fuelDash.CancelledFuelOrdersList />} />
            <Route path={`/zm/account/${user?.user_type}/dashboard/order_details`} element={<fuelDash.FuelDetails />} />
            <Route path={`/zm/account/${user?.user_type}/dashboard/product_details`} element={<fuelDash.ProductDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;